<template>
  <div>
    <div ref="mySvg">
      .
    </div>
    <v-divider class="my-5" />

    <div class="d-flex align-center justify-end mt-4">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="voltar"
      >
        <v-icon>$mdiArrowLeft</v-icon>
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import { IncertezasService, EnumeradorService } from '@common/services';
import * as d3 from '@/assets/js/d3.min.js';
import d3Fishbone from '@/assets/js/d3.fishbone';
export default {
  props: ['form'],
  mounted() {
    this.buscarComponentes();
    d3Fishbone(d3); //registra o fishbone na biblioteca
  },
  methods: {
    voltar() {
      if(this.revisao){
       return this.$router.back();
      }
      this.$router.push({ name: 'incertezas' });
    },
    buscarComponentes: function () {
      Promise.all([
        IncertezasService.listarComponentes(this.form.revisaoId),
        EnumeradorService.buscar('EnumFonteIncerteza'),
      ]).then((res) => {
        let componentes = res[0].data.componentes;
        let fontes = res[1];

        var fishbone = d3.fishbone();
        let data = {
          name: this.form.nome,
          children: fontes.map((el) => {
            return {
              name: el.text,
              children: componentes
                .filter((c) => c.fonteIncerteza == el.value)
                .map((c) => {
                  return { name: c.descricao };
                }),
            };
          }),
        };

        var size = function () {
          return { width: this.clientWidth, height: 500 };
        }.bind(this.$refs.mySvg);

        d3.select(this.$refs.mySvg).html(null);
        var svg = d3
          .select(this.$refs.mySvg)
          .append('svg')
          .attr('width', size().width)
          .attr('height', size().height)
          .datum(data)
          .call(fishbone.defaultArrow)
          .call(fishbone);

        fishbone.force().size([size().width, size().height]).start();

        d3.select(window).on('resize', function () {
          fishbone.force().size([size().width, size().height]).start();
          svg.attr(size());
        });

        setTimeout(function () {
          const resizeEvent = new UIEvent('resize');
          window.dispatchEvent(resizeEvent);
        }, 5000);
      });
    },
  },
};
</script>
<style>
.label-0 {
  font-size: 1.5em;
}
.label-1 {
  font-size: 0.9em;
  fill: #111;
}
.label-2 {
  font-size: 0.8em;
  fill: #444;
}
.label-3 {
  font-size: 0.8em;
  fill: #888;
}
.label-4 {
  font-size: 0.8em;
  fill: #aaa;
}

.link-0 {
  stroke: #000;
  stroke-width: 2px;
}
.link-1 {
  stroke: #333;
  stroke-width: 1px;
}
.link-2,
.link-3,
.link-4 {
  stroke: #666;
  stroke-width: 0.5px;
}
</style>
