import cloneDeep from 'lodash/cloneDeep';
import { DropdownModel } from '../geral/DropdownModel';
import helpers from '../../../common/utils/helpers';



export class IncertezaModel {
  constructor({
    id,
    nome,
    codigo,
    revisao,
    revisaoId,
    estruturaUtilizada,
    amplitudeMinima,
    amplitudeMaxima,
    unidadeMedida,
    formula,
    descricaoPadrao,
    descricaoMelhorArtefato,
    justificativa,
    imagem,
    nomeImagem,
    revisaoPublicada = false,
    possuiComponentes = false,
  } = {}) {
    this.id = id;
    this.nome = nome || '';
    this.codigo = codigo;
    this.revisao = revisao;
    this.revisaoId = revisaoId;
    this.revisaoPublicada = revisaoPublicada;
    this.estruturaUtilizada = estruturaUtilizada;
    this.amplitudeMinima = amplitudeMinima;
    this.amplitudeMaxima = amplitudeMaxima;

    if (unidadeMedida) {
      this.unidadeMedidaId = new DropdownModel(unidadeMedida);
    } else this.unidadeMedidaId = null

    if (formula) this.formulaId = new DropdownModel(formula);

    this.descricaoPadrao = descricaoPadrao || '';
    this.descricaoMelhorArtefato = descricaoMelhorArtefato || '';
    this.justificativa = justificativa;

    if (imagem && nomeImagem) {
      let ctx = this;
      helpers
        .converterBase64ParaFile(
          imagem,
          nomeImagem,
          'image/png'
        )
        .then(function (file) {
          ctx.fileImagem = file;
        });
    }

    this.possuiComponentes = possuiComponentes;
  }

  get request() {
    const retorno = cloneDeep(this);

    if (this.unidadeMedidaId) {
      retorno.unidadeMedidaId = this.unidadeMedidaId.value;
    }

    if (this.formulaId) {
      retorno.formulaId = this.formulaId.value;
    }

    return retorno;
  }
}
