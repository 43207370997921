var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"form"},[_c('div',{staticClass:"row mt-2"},[_c('input-text',{staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t('modulos.incertezas.formulario.dados_incerteza.nome'),"placeholder":_vm.$t('modulos.incertezas.formulario.dados_incerteza.nome'),"disabled":_vm.revisao,"obrigatorio":""},model:{value:(_vm.form.dados.nome),callback:function ($$v) {_vm.$set(_vm.form.dados, "nome", $$v)},expression:"form.dados.nome"}}),_c('input-formula',{staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t(
            'modulos.incertezas.formulario.dados_incerteza.formula_mensurando'
          ),"multiplos":false,"disabled":_vm.revisao,"somente-mensurando":"","obrigatorio":""},model:{value:(_vm.form.dados.formulaId),callback:function ($$v) {_vm.$set(_vm.form.dados, "formulaId", $$v)},expression:"form.dados.formulaId"}})],1),_c('div',{staticClass:"row"},[_c('input-unidade-medida',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t('modulos.incertezas.formulario.dados_incerteza.unidade_medida'),"multiplos":false,"disabled":_vm.revisao,"obrigatorio":""},model:{value:(_vm.form.dados.unidadeMedidaId),callback:function ($$v) {_vm.$set(_vm.form.dados, "unidadeMedidaId", $$v)},expression:"form.dados.unidadeMedidaId"}}),_c('v-form',{ref:"amplitudeForm",staticClass:"col-12 col-md-3 d-flex align-top"},[_c('input-text',{staticClass:"flex-fill",attrs:{"label":_vm.$t('modulos.incertezas.formulario.dados_incerteza.amplitude'),"placeholder":_vm.$t(
              'modulos.procedimentos_eletronicos.tabela_registro.formulario.amplitude_min'
            ),"obrigatorio":"","regras-personalizadas":_vm.regraAmplitude(),"type":"number","disabled":_vm.revisao},on:{"input":_vm.validarAmplitude},model:{value:(_vm.form.dados.amplitudeMinima),callback:function ($$v) {_vm.$set(_vm.form.dados, "amplitudeMinima", $$v)},expression:"form.dados.amplitudeMinima"}}),_c('span',{staticClass:"mx-2 mt-11"},[_vm._v(_vm._s(_vm.$t('geral.titulos.a')))]),_c('input-text',{staticClass:"flex-fill mt-5",attrs:{"placeholder":_vm.$t(
              'modulos.procedimentos_eletronicos.tabela_registro.formulario.amplitude_max'
            ),"obrigatorio":"","disabled":_vm.revisao,"regras-personalizadas":_vm.regraAmplitude(),"type":"number"},on:{"input":_vm.validarAmplitude},model:{value:(_vm.form.dados.amplitudeMaxima),callback:function ($$v) {_vm.$set(_vm.form.dados, "amplitudeMaxima", $$v)},expression:"form.dados.amplitudeMaxima"}})],1),_c('input-select',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t(
            'modulos.incertezas.formulario.dados_incerteza.estrutura_utilizada'
          ),"options":_vm.opcoes.estruturaUtilizada,"disabled":_vm.revisao || _vm.desabilitarEstruturaUtilizada,"obrigatorio":""},model:{value:(_vm.form.dados.estruturaUtilizada),callback:function ($$v) {_vm.$set(_vm.form.dados, "estruturaUtilizada", $$v)},expression:"form.dados.estruturaUtilizada"}}),_c('input-file',{staticClass:"col-12 col-md-3",attrs:{"value":_vm.form.dados.fileImagem,"returns":"base64","placeholder":_vm.$t('modulos.incertezas.formulario.dados_incerteza.adicionar_imagem'),"visualizador":"","regras-personalizadas":[_vm.regraArquivo],"image":_vm.form.dados.imagem,"disabled":_vm.revisao},on:{"handle-upload":_vm.handleUpload,"clear":_vm.desvincularImagem,"nome-imagem":_vm.nomeImagem}})],1),_c('div',{staticClass:"row"},[_c('input-textarea',{staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t('modulos.incertezas.formulario.dados_incerteza.descricao_padrao'),"disabled":_vm.revisao,"placeholder":_vm.$t('modulos.incertezas.formulario.dados_incerteza.descricao_padrao'),"min":0,"max":1000,"rows":4},model:{value:(_vm.form.dados.descricaoPadrao),callback:function ($$v) {_vm.$set(_vm.form.dados, "descricaoPadrao", $$v)},expression:"form.dados.descricaoPadrao"}}),_c('input-textarea',{staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t(
            'modulos.incertezas.formulario.dados_incerteza.descricao_melhor_artefato'
          ),"disabled":_vm.revisao,"placeholder":_vm.$t(
            'modulos.incertezas.formulario.dados_incerteza.descricao_melhor_artefato'
          ),"min":0,"max":1000,"rows":4},model:{value:(_vm.form.dados.descricaoMelhorArtefato),callback:function ($$v) {_vm.$set(_vm.form.dados, "descricaoMelhorArtefato", $$v)},expression:"form.dados.descricaoMelhorArtefato"}})],1),_c('div',{staticClass:"row"},[(_vm.form.dados.revisao > 0)?_c('input-textarea',{key:_vm.justificativaKey,staticClass:"col-12",attrs:{"label":_vm.$t('modulos.incertezas.formulario.dados_incerteza.justificativa'),"min":0,"max":1000,"rows":6,"disabled":_vm.revisao,"obrigatorio":_vm.justificativaObrigatoria},model:{value:(_vm.form.dados.justificativa),callback:function ($$v) {_vm.$set(_vm.form.dados, "justificativa", $$v)},expression:"form.dados.justificativa"}}):_vm._e()],1)]),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"d-flex align-center justify-end mt-4"},[(_vm.revisao)?_c('botao-padrao',{staticClass:"mr-2",attrs:{"outlined":"","color":"secondary"},on:{"click":_vm.voltar}},[_c('v-icon',[_vm._v("$mdiArrowLeft")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.voltar'))+" ")],1):[_c('botao-padrao',{attrs:{"outlined":"","color":"secondary"},on:{"click":_vm.cancelar}},[_c('v-icon',[_vm._v("$mdiCancel")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.cancelar'))+" ")],1),_c('botao-padrao',{staticClass:"mx-2",attrs:{"sem-capitalize":""},on:{"click":_vm.confirmacaoSalvarContinuar}},[_c('v-icon',[_vm._v("$mdiContentSaveMoveOutline")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.salvar_continuar'))+" ")],1),_c('botao-padrao',{staticClass:"mr-2",attrs:{"sem-capitalize":""},on:{"click":_vm.confirmacaoSalvarSair}},[_c('v-icon',[_vm._v("$mdiContentSaveOutline")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.salvar_sair'))+" ")],1),(_vm.form.dados.id)?_c('botao-padrao',{attrs:{"sem-capitalize":""},on:{"click":_vm.confirmacaoPublicar}},[_c('v-icon',[_vm._v("$mdiPublish")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.publicar'))+" ")],1):_vm._e()]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }