var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal-padrao',{ref:"modal-componente",attrs:{"max-width":"90%","persistente":"","titulo-ok":_vm.$t('geral.botoes.salvar'),"titulo-cancelar":_vm.$t('geral.botoes.voltar'),"mostraBotaoSalvar":!_vm.revisao},on:{"ok":_vm.confirmacaoSalvar,"modal-fechado":_vm.modalFechado},scopedSlots:_vm._u([{key:"titulo",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(_vm._s(_vm.titulo))]),_c('v-chip',{staticClass:"ml-2",attrs:{"small":"","color":"white"}},[_vm._v(" "+_vm._s(_vm.$t('modulos.incertezas.formulario.componentes.codigo_incerteza', { codigo: _vm.incerteza.codigo, }))+" ")])],1)]},proxy:true}])},[_c('div',[_c('v-form',{ref:"form"},[_c('div',{staticClass:"row mt-2"},[_c('input-text',{staticClass:"col-12 col-md-5",attrs:{"label":_vm.$t('modulos.incertezas.formulario.componentes.descricao'),"disabled":_vm.revisao,"obrigatorio":""},model:{value:(_vm.form.dados.descricao),callback:function ($$v) {_vm.$set(_vm.form.dados, "descricao", $$v)},expression:"form.dados.descricao"}}),_c('input-select',{staticClass:"col-12 col-md-3",attrs:{"disabled":_vm.revisao,"label":_vm.$t('modulos.incertezas.formulario.componentes.fonte_incerteza'),"options":_vm.opcoes.fontesIncerteza,"obrigatorio":""},model:{value:(_vm.form.dados.fonteIncerteza),callback:function ($$v) {_vm.$set(_vm.form.dados, "fonteIncerteza", $$v)},expression:"form.dados.fonteIncerteza"}}),_c('input-radio',{staticClass:"col",class:{
            'col-md-4': !_vm.form.dados.id,
            'col-md-auto': _vm.form.dados.id,
          },attrs:{"disabled":_vm.revisao,"divider":false,"label":_vm.$t('modulos.incertezas.formulario.componentes.tipoA'),"options":_vm.opcoes.tipoA},model:{value:(_vm.form.dados.tipoA),callback:function ($$v) {_vm.$set(_vm.form.dados, "tipoA", $$v)},expression:"form.dados.tipoA"}}),(_vm.form.dados.id)?_c('input-text',{staticClass:"col",attrs:{"label":_vm.$t('modulos.incertezas.formulario.componentes.xi'),"disabled":""},model:{value:(_vm.form.dados.xi),callback:function ($$v) {_vm.$set(_vm.form.dados, "xi", $$v)},expression:"form.dados.xi"}}):_vm._e()],1),_c('div',{staticClass:"row"},[_c('input-unidade-medida',{staticClass:"col-12 col-md-4",attrs:{"disabled":_vm.revisao,"label":_vm.$t(
              'modulos.incertezas.formulario.componentes.unidade_da_contribuicao'
            ),"multiplos":false,"obrigatorio":""},model:{value:(_vm.form.dados.unidadeMedidaContribuicaoId),callback:function ($$v) {_vm.$set(_vm.form.dados, "unidadeMedidaContribuicaoId", $$v)},expression:"form.dados.unidadeMedidaContribuicaoId"}}),_c('input-select',{staticClass:"col-12 col-md-4",attrs:{"disabled":_vm.revisao,"label":_vm.$t(
              'modulos.incertezas.formulario.componentes.origem_da_contribuicao'
            ),"options":_vm.opcoes.origemContribuicao,"obrigatorio":""},model:{value:(_vm.form.dados.origemContribuicao),callback:function ($$v) {_vm.$set(_vm.form.dados, "origemContribuicao", $$v)},expression:"form.dados.origemContribuicao"}}),(false)?_c('input-incerteza',{staticClass:"col-12 col-md-4",attrs:{"label":_vm.$t('modulos.incertezas.formulario.componentes.incerteza_auxiliar'),"apenas-publicados":"","sem-revisao":"","nivel-unico-incerteza-auxiliar":"","formula":_vm.incerteza.formulaId,"ignore-incerteza-id":_vm.incerteza.id,"disabled":!_vm.origemContribuicaoEhIncerteza || _vm.revisao,"multiplos":false},model:{value:(_vm.form.dados.incertezaAuxiliarId),callback:function ($$v) {_vm.$set(_vm.form.dados, "incertezaAuxiliarId", $$v)},expression:"form.dados.incertezaAuxiliarId"}}):_vm._e(),_c('input-select',{staticClass:"col-12 col-md-4",attrs:{"label":_vm.$t('modulos.incertezas.formulario.componentes.variavel'),"placeholder":_vm.$t('geral.inputs.selecione'),"options":_vm.variaveis,"disabled":_vm.revisao || _vm.variavelDesabilitado,"obrigatorio":_vm.variavelObrigatoria,"retornar-objeto":"","clearable":""},model:{value:(_vm.form.dados.variavel),callback:function ($$v) {_vm.$set(_vm.form.dados, "variavel", $$v)},expression:"form.dados.variavel"}}),_c('input-ide',{ref:"formulaContribuicao",staticClass:"col-12 col-md-12",attrs:{"obrigatorio":"","disabled":_vm.revisao,"label":_vm.$t(
              'modulos.incertezas.formulario.componentes.formula_da_contribuicao'
            ),"formula-mensurando-id":_vm.incerteza.formulaId && _vm.incerteza.formulaId.value},model:{value:(_vm.form.dados.formulaContribuicao),callback:function ($$v) {_vm.$set(_vm.form.dados, "formulaContribuicao", $$v)},expression:"form.dados.formulaContribuicao"}})],1),_c('div',{staticClass:"row"},[_c('input-select',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t(
              'modulos.incertezas.formulario.componentes.distribuicao_estatistica'
            ),"options":_vm.opcoes.distribuicaoEstatistica,"obrigatorio":"","disabled":_vm.revisao || _vm.origemContribuicaoIncertezaTipoANao},model:{value:(_vm.form.dados.distribuicaoEstatistica),callback:function ($$v) {_vm.$set(_vm.form.dados, "distribuicaoEstatistica", $$v)},expression:"form.dados.distribuicaoEstatistica"}}),_c('input-ide',{ref:"formulaDE",staticClass:"col-12 col-md-9",attrs:{"label":_vm.$t('modulos.incertezas.formulario.componentes.formula_de'),"disabled":_vm.form.dados.distribuicaoEstatistica !== 'Normal' || _vm.revisao || _vm.origemContribuicaoIncertezaTipoANao,"obrigatorio":_vm.form.dados.distribuicaoEstatistica === 'Normal' &&
              _vm.form.dados.tipoA,"formula-mensurando-id":_vm.incerteza.formulaId && _vm.incerteza.formulaId.value},model:{value:(_vm.form.dados.formulaDE),callback:function ($$v) {_vm.$set(_vm.form.dados, "formulaDE", $$v)},expression:"form.dados.formulaDE"}}),_c('input-unidade-medida',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t('modulos.incertezas.formulario.componentes.unidade_ci'),"multiplos":false,"obrigatorio":"","disabled":_vm.revisao},model:{value:(_vm.form.dados.unidadeMedidaCiId),callback:function ($$v) {_vm.$set(_vm.form.dados, "unidadeMedidaCiId", $$v)},expression:"form.dados.unidadeMedidaCiId"}}),_c('input-ide',{ref:"formulaCI",staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t('modulos.incertezas.formulario.componentes.formula_ci'),"disabled":_vm.estruturaUtilizadaEhMetodosNumericos || _vm.revisao,"obrigatorio":_vm.estruturaUtilizadaEhDerivadaParcial,"formula-mensurando-id":_vm.incerteza.formulaId && _vm.incerteza.formulaId.value},model:{value:(_vm.form.dados.formulaCi),callback:function ($$v) {_vm.$set(_vm.form.dados, "formulaCi", $$v)},expression:"form.dados.formulaCi"}}),_c('input-select',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t('modulos.incertezas.formulario.componentes.metodo_numerico_ci'),"obrigatorio":_vm.estruturaUtilizadaEhMetodosNumericos,"disabled":_vm.estruturaUtilizadaEhDerivadaParcial || _vm.revisao,"options":_vm.opcoes.metodoCI},model:{value:(_vm.form.dados.metodoNumericoCi),callback:function ($$v) {_vm.$set(_vm.form.dados, "metodoNumericoCi", $$v)},expression:"form.dados.metodoNumericoCi"}}),_c('input-unidade-medida',{staticClass:"col-12 col-md-4",attrs:{"disabled":_vm.revisao,"label":_vm.$t(
              'modulos.incertezas.formulario.componentes.unidade_da_incerteza_padrao'
            ),"multiplos":false,"obrigatorio":""},model:{value:(_vm.form.dados.unidadeMedidaIncertezaPadraoId),callback:function ($$v) {_vm.$set(_vm.form.dados, "unidadeMedidaIncertezaPadraoId", $$v)},expression:"form.dados.unidadeMedidaIncertezaPadraoId"}}),_c('input-text',{staticClass:"col-12 col-md-5",attrs:{"label":_vm.$t('modulos.incertezas.formulario.componentes.grau_liberdade'),"disabled":""},model:{value:(_vm.form.dados.grausLiberdadeFormulaTexto),callback:function ($$v) {_vm.$set(_vm.form.dados, "grausLiberdadeFormulaTexto", $$v)},expression:"form.dados.grausLiberdadeFormulaTexto"}})],1),_c('accordion-padrao',{staticClass:"mb-4",attrs:{"titulo":_vm.$t(
            'modulos.incertezas.formulario.componentes.valores_de_teste.titulo'
          )}},[_c('div',{staticClass:"row"},[_c('input-text',{staticClass:"col-12 col-md-3",attrs:{"type":"number","label":_vm.$t(
                'modulos.incertezas.formulario.componentes.valores_de_teste.contribuicao'
              ),"disabled":_vm.revisao,"placeholder":_vm.$t(
                'modulos.incertezas.formulario.componentes.valores_de_teste.contribuicao'
              ),"obrigatorio":""},model:{value:(_vm.form.dados.teste.valorTesteContribuicao),callback:function ($$v) {_vm.$set(_vm.form.dados.teste, "valorTesteContribuicao", $$v)},expression:"form.dados.teste.valorTesteContribuicao"}}),_c('input-text',{staticClass:"col-12 col-md-2",attrs:{"type":"number","label":_vm.$t(
                'modulos.incertezas.formulario.componentes.valores_de_teste.de'
              ),"disabled":_vm.revisao,"regras-personalizadas":_vm.regraDE(),"error":_vm.error.DE.status,"error-messages":_vm.error.DE.message,"min":1,"placeholder":_vm.$t(
                'modulos.incertezas.formulario.componentes.valores_de_teste.de'
              ),"obrigatorio":""},model:{value:(_vm.form.dados.teste.valorTesteDe),callback:function ($$v) {_vm.$set(_vm.form.dados.teste, "valorTesteDe", $$v)},expression:"form.dados.teste.valorTesteDe"}}),_c('input-text',{staticClass:"col-12 col-md-2",attrs:{"type":"number","label":_vm.$t(
                'modulos.incertezas.formulario.componentes.valores_de_teste.ci'
              ),"disabled":_vm.revisao,"placeholder":_vm.$t(
                'modulos.incertezas.formulario.componentes.valores_de_teste.ci'
              ),"obrigatorio":""},model:{value:(_vm.form.dados.teste.valorTesteCi),callback:function ($$v) {_vm.$set(_vm.form.dados.teste, "valorTesteCi", $$v)},expression:"form.dados.teste.valorTesteCi"}}),_c('input-text',{staticClass:"col-12 col-md-3",attrs:{"type":"number","label":_vm.$t(
                'modulos.incertezas.formulario.componentes.valores_de_teste.graus_liberdade'
              ),"disabled":_vm.revisao,"obrigatorio":"","placeholder":_vm.$t(
                'modulos.incertezas.formulario.componentes.valores_de_teste.graus_liberdade'
              ),"regras-personalizadas":_vm.regraNumeroDeCiclos()},model:{value:(_vm.form.dados.teste.grausLiberdade),callback:function ($$v) {_vm.$set(_vm.form.dados.teste, "grausLiberdade", $$v)},expression:"form.dados.teste.grausLiberdade"}}),_c('input-text',{staticClass:"col-12 col-md-2",attrs:{"type":"number","disabled":"","label":_vm.$t(
                'modulos.incertezas.formulario.componentes.valores_de_teste.incerteza_padrao'
              ),"placeholder":_vm.$t(
                'modulos.incertezas.formulario.componentes.valores_de_teste.incerteza_padrao'
              )},model:{value:(_vm.form.dados.teste.incertezaPadrao),callback:function ($$v) {_vm.$set(_vm.form.dados.teste, "incertezaPadrao", $$v)},expression:"form.dados.teste.incertezaPadrao"}})],1)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }