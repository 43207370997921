<template>
  <div>
    <!-- indicadores -->
    <div class="row mt-3 mx-0 flex-wrap">
      <!-- Indicadores -->
      <div class="row justify-space-between mt-6 mb-3 mx-0 flex-wrap">
        <div
          v-for="(indicador, index) in indicadores"
          :key="`indicador-${index}`"
          class="col-12 col-md-auto dados-cabecalho-item"
        >
          <span>
            {{ `${indicador.title} : ${indicador.value}` }}
          </span>
        </div>
      </div>
    </div>

    <!-- tabela -->
    <tabela-padrao
      :dados="tabela.dados"
      :colunas="tabela.colunas"
      class="mt-4"
      sem-selecionar-todos
      :mostrar-checkbox="false"
      sem-paginacao
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:item.percentual="{ item }">
        <div
          class="d-flex justify-center gap-2 align-center"
          style="width:200px"
        >
          <div style="width:75px">
            <span>
              {{
                item.item.percentual | formatarNumeroComPrecisao
              }}%
            </span>
          </div>
          <v-progress-linear
            height="26"
            background-color="transparent"
            :value="item.item.percentual"
            :color="corAleatoria(item.item.xi)"
          />
        </div>
      </template>
    </tabela-padrao>

    <v-divider class="my-5" />

    <div class="d-flex align-center justify-end mt-4">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="voltar"
      >
        <v-icon>$mdiArrowLeft</v-icon>
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
    </div>
  </div>
</template>

<script>
import IncertezasService from '@common/services/cadastros/IncertezasService';
import { orderBy } from 'lodash';
import helpers from '@common/utils/helpers';

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
  },

  data: function () {
    return {
      tabela: {
        indicadores: {
          incertezaCombinada: null,
          veff: null,
          fatorK: null,
          incertzaExpandida: null,
          incertezaExpandidaComArredondamento: null,
        },
        quantidadeItens: 0,
        dados: [],
        colunas: [
          {
            text: this.$t(
              'modulos.incertezas.grafico_de_contribuicoes.tabela.descricao'
            ),
            sortable: false,
            value: 'descricao',
          },
          {
            text: this.$t(
              'modulos.incertezas.grafico_de_contribuicoes.tabela.incerteza_padrao'
            ),
            sortable: false,
            value: 'incertezaPadrao',
            formatter: (value) => {
               if (value || value == 0) {
                 return helpers.formatarNumeroComPrecisao(value)
               }
             },
          },
          {
            text: this.$t(
              'modulos.incertezas.grafico_de_contribuicoes.tabela.percentual'
            ),
            sortable: false,
            value: 'percentual',
          },
        ],
      },
    };
  },

  computed: {
    indicadores() {
      return [
        {
          title: this.$t(
            'modulos.incertezas.grafico_de_contribuicoes.indicadores.incerteza_combinada'
          ),
          value: this.tabela.indicadores.incertezaCombinada
            ? helpers.formatarNumeroComPrecisao(
                this.tabela.indicadores.incertezaCombinada
              )
            : 0,
        },
        {
          title: this.$t(
            'modulos.incertezas.grafico_de_contribuicoes.indicadores.veff'
          ),
          value: this.tabela.indicadores.veff
            ? helpers.formatarNumeroComPrecisao(this.tabela.indicadores.veff)
            : 0,
        },
        {
          title: this.$t(
            'modulos.incertezas.grafico_de_contribuicoes.indicadores.fator_k'
          ),
          value: this.tabela.indicadores.fatorK
            ? helpers.formatarNumeroComPrecisao(this.tabela.indicadores.fatorK)
            : 0,
        },
        {
          title: this.$t(
            'modulos.incertezas.grafico_de_contribuicoes.indicadores.incerteza_expandida'
          ),
          value: this.tabela.indicadores.incertezaExpandida
            ? helpers.formatarNumeroComPrecisao(
                this.tabela.indicadores.incertezaExpandida
              )
            : 0,
        },
        {
          title: this.$t(
            'modulos.incertezas.grafico_de_contribuicoes.indicadores.incerteza_expandida_com_arredondamento'
          ),
          value: this.tabela.indicadores.incertezaExpandidaArrendondamento
            ? helpers.formatarNumeroComPrecisao(
                this.tabela.indicadores.incertezaExpandidaArrendondamento
              )
            : 0,
        },
      ];
    },
  },

  mounted() {
    //this.listarRegistros();
  },

  methods: {
    corAleatoria(xi) {
      return xi % 2 === 0 ? 'yellow' : 'blue';
    },

    voltar() {
      if (this.revisao) {
        return this.$router.back();
      }
      this.$router.push({ name: 'incertezas' });
    },

    proporcaoDaIncertezaCombinada(incertezaPadrao) {
      let valor = this.tabela.dados.reduce((total, incerteza) => {
        return total + incerteza.incertezaPadrao;
      }, 0);
      return parseFloat(((incertezaPadrao * 100) / valor).toFixed(4));
    },

    listarRegistros() {
      this.$store.dispatch('Layout/iniciarCarregamento');

      IncertezasService.listarComponentes(this.id)
        .then((res) => {
          const componentes = res.data.componentes.map((dado) => ({
            id: dado.xi,
            ...dado,
          }));

          const valorTotal = componentes.reduce((total, incerteza) => {
            return total + incerteza.incertezaPadrao;
          }, 0);

          const componenteComPercentual = componentes.map((componente) => ({
            ...componente,
            percentual: 
            
            Math.abs(parseFloat(
              ((componente.incertezaPadrao * 100) / valorTotal).toFixed(2))
            ),
          }));

          this.tabela.dados = orderBy(
            componenteComPercentual,
            'percentual',
            'desc'
          );
          this.tabela.quantidadeItens = componentes.length;

          this.tabela.indicadores.fatorK = res.data.fatorK;
          this.tabela.indicadores.incertezaCombinada =
            res.data.incertezaCombinada;
          this.tabela.indicadores.incertezaExpandida =
            res.data.incertezaExpandida;
          this.tabela.indicadores.incertezaExpandidaArrendondamento =
            res.data.incertezaExpandidaArrendondamento;
          this.tabela.indicadores.veff = res.data.veff;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>

<style scoped>
.dados-cabecalho-item {
  background-color: #f9fafb;

  border: 1px solid #e4e7ec;
  border-radius: 8px;

  padding: 8px 16px;

  display: flex;
  justify-content: center;

  max-width: fit-content;
}

.dados-cabecalho-item > span {
  color: #98a2b3;
  font-size: 14px;
  font-weight: 500;
}
</style>
