<template>
  <div>
    <!-- Cabeçalho -->
    <v-row class="mb-2">
      <v-col
        cols="12"
        class="d-flex align-center justify-space-between"
      >
        <div>
          <span class="titulo-pagina">
            {{ titulo }}
          </span>
          <div v-if="badges.codigo">
            <v-chip
              class="mr-1"
              small
            >
              Código: {{ badges.codigo }}
            </v-chip>
          </div>
        </div>

        <botao-padrao
          v-if="form.incerteza.dados.revisao"
          outline
          disabled
        >
          {{ revisao }}
        </botao-padrao>
      </v-col>
    </v-row>
    <!-- Tabs -->
    <div>
      <v-tabs
        v-model="tab"
        class="abas-padrao"
        background-color="primary"
        color="primary"
        @change="verificarSeTemDadoNaoSalvo"
      >
        <v-tab>
          {{ $t('modulos.incertezas.formulario.abas.dados_incerteza') }}
        </v-tab>

        <!-- Componentes -->

        <v-tab>
          {{ $t('modulos.incertezas.formulario.abas.componentes') }}
        </v-tab>

        <!-- Gráfico de Contribuições -->
        <template>
          <v-tab v-if="mostrarComponentesEGrafico">
            {{ $t('modulos.incertezas.formulario.abas.grafico') }}
          </v-tab>

          <v-tooltip
            v-else
            top
          >
            <template v-slot:activator="{ on }">
              <div
                class="v-tab"
                v-on="!mostrarComponentesEGrafico ? on : null"
              >
                {{ $t('modulos.incertezas.formulario.abas.grafico') }}
              </div>
            </template>
            <span>
              {{ $t('modulos.incertezas.formulario.abas.grafico_bloqueado') }}
            </span>
          </v-tooltip>
        </template>

        <template>
          <v-tab v-if="mostrarComponentesEGrafico">
            {{ $t('modulos.incertezas.formulario.abas.diagrama') }}
          </v-tab>

          <v-tooltip
            v-else
            top
          >
            <template v-slot:activator="{ on }">
              <div
                class="v-tab"
                v-on="!mostrarComponentesEGrafico ? on : null"
              >
                {{ $t('modulos.incertezas.formulario.abas.diagrama') }}
              </div>
            </template>
            <span>
              {{ $t('modulos.incertezas.formulario.abas.diagrama_bloqueado') }}
            </span>
          </v-tooltip>
        </template>
      </v-tabs>
      <v-tabs-items v-model="tabComponentes">
        <v-tab-item>
          <form-incerteza
            ref="form-incerteza"
            :form="form.incerteza"
            :revisao="modo === 'visualizacao'"
            :desabilitar-estrutura-utilizada="
              form.incerteza.dados.possuiComponentes
            "
            @salvar-continuar="salvarIncerteza('continuar')"
            @salvar-sair="salvarIncerteza('sair')"
            @publicar="verificarPublicacaoIncerteza"
            @cancelar="cancelar"
          />
        </v-tab-item>
        <v-tab-item>
          <listagem-componentes
            :id="form.incerteza.dados.revisaoId"
            ref="listagem-componentes"
            :revisao="modo === 'visualizacao'"
            :form="form"
            @cancelar="cancelar"
            @ir-para-aba-grafico-contribuicoes="irParaAbaGraficoContribuicoes"
            @criar-componente="abrirModalFormularioComponente('criacao')"
            @editar-componente="mostrarEditarComponente"
            @atualizar-diagrama="atualizarDiagrama"
            @modificar-formulario="modificarFormulario"
            @tem-componentes-criados="temComponentesCriados"
            @publicar="verificarPublicacaoIncerteza"
          />
        </v-tab-item>
        <v-tab-item>
          <grafico-contribuicoes
            :id="form.incerteza.dados.revisaoId"
            ref="grafico-contribuicoes"
          />
        </v-tab-item>
        <v-tab-item>
          <diagrama-fishbone
            ref="diagrama"
            :form="form.incerteza.dados"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <modal-formulario-componente
      ref="modal-formulario-componente"
      :form="form.componente"
      :revisao="modo === 'visualizacao'"
      :incerteza="form.incerteza.dados"
      :unidade-medida-formula-mensurando="unidadeMedidaFormulaMensurando"
      :opcoes-metodo-ci="opcoesMetodoCi"
      @salvar="salvarComponente"
    />
  </div>
</template>

<script>
import IncertezasService from '@/common/services/cadastros/IncertezasService';

import FormIncerteza from './formulario/incerteza.vue';
import GraficoContribuicoes from './formulario/GraficoContribuicoes.vue';
import ListagemComponentes from './formulario/ListagemComponentes.vue';
import DiagramaFishbone from './formulario/DiagramaFishbone.vue';
import ModalFormularioComponente from './formulario/ModalFormularioComponente.vue';
import FormulaService from '@/common/services/cadastros/FormulaService';
import { DropdownModel } from '../../../../common/models/geral/DropdownModel';
import { ComponentesIncertezaModel } from '@/common/models/cadastros/ComponentesIncertezaModel';
import { IncertezaModel } from '@/common/models/cadastros/IncertezaModel';
import helpers from '@common/utils/helpers';
export default {
  components: {
    FormIncerteza,
    ListagemComponentes,
    GraficoContribuicoes,
    DiagramaFishbone,
    ModalFormularioComponente,
  },
  props: {
    id: { type: String, default: '' },
    idRevisao: { type: String, default: '' },
  },
  data() {
    return {
      modo: '', // (edicao || criacao || visualizacao)
      badges: { codigo: '' },
      dadosNaoSalvos: false,
      primeiraVez: true,
      idWatcher: null,
      formularioWatcher: null,
      formulaMensurandoWatcher: null,
      form: {
        incerteza: {
          dados: new IncertezaModel(),
          valido: false,
        },
        componente: {
          dados: new ComponentesIncertezaModel(),
          valido: false,
        },
      },
      tab: 0,
      tabComponentes: 0,
      tabFlag: true,
      unidadeMedidaFormulaMensurando: {},
      opcoesMetodoCi: [],
    };
  },
  computed: {
    titulo() {
      const { codigo } = this.form.incerteza.dados;

      if (this.modo === 'criacao')
        return this.$t('modulos.incertezas.titulos.novo');

      if (this.modo === 'edicao')
        return `${this.$t('modulos.incertezas.titulos.editar')}`;

      if (this.modo === 'visualizacao')
        return `${this.$t(
          'modulos.incertezas.titulos.visualizar_revisao_incertezas'
        )} ${codigo}`;

      return '';
    },
    revisao() {
      const { revisao } = this.form.incerteza.dados;

      return `${this.$t('modulos.incertezas.titulos.revisao')}: ${revisao}`;
    },
    mostrarComponentesEGrafico() {
      return this.form.incerteza.dados.revisaoId;
    },
  },
  watch: {
    'form.incerteza.dados.formulaId': function (formula) {
      if (!formula.value) return;
      this.$refs['modal-formulario-componente'].buscarVariaveisMensurando(
        formula.value
      );
    },
    tab: {
      handler(tab) {
        if (tab == 2) {
          setTimeout(() => {
            this.$refs['grafico-contribuicoes'].listarRegistros();
          }, 500);
        }
      },
    },
  },
  async mounted() {
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'Incerteza', 'Editar');
    }

    helpers.redirecionarSemPermissao(this, 'Incerteza', 'Inserir');
    await this.definirModo();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.incertezas.titulos.listagem')
    );
    this.idWatcher = this.$watch('id', async function () {
      await this.buscarIncerteza();
      this.definirModo();
      setTimeout(() => {
        this.irParaAbaComponentes();
      }, 500);
    });

    this.formulaMensurandoWatcher = this.$watch(
      'form.incerteza.dados.formulaId',
      function (formula) {
        if (!formula?.value) return;
        this.buscarVariaveisUnidadeMedidaMensurando(formula.value);
      }
    );
    this.formularioWatcher = this.$watch(
      'form.incerteza',
      () => {
        this.dadosNaoSalvos = true;
      },
      { deep: true }
    );
  },
  methods: {
    async definirModo() {
      const { name } = this.$route;

      if (name === 'incertezas-novo') {
        this.modoCriacao();
        return;
      }

      if (name === 'incertezas-editar') {
        await this.buscarIncerteza();
        this.modoEdicao();
        return;
      }

      if (name === 'incertezas-revisoes-visualizar') {
        this.buscarRevisaoDeIncerteza(this.idRevisao);
        this.modoVisualizacao();
      }

      if (name === 'incertezas-revisoes-editar') {
        this.buscarRevisaoDeIncerteza(this.idRevisao);
        this.modoEdicao();
        return;
      }
    },
    verificarPublicacaoIncerteza() {
      if(this.form.incerteza.dados.revisao > 0 && !this.form.incerteza.dados.justificativa)
      {
        this.tab = 0;
        this.tabComponentes = 0;
        return this.$refs['form-incerteza']?.obrigarPreencherJustificativa();
      }
      console.log(this.form)
      this.confirmar(
        this.$t('modulos.incertezas.publicar.titulo'),
        this.$t('modulos.incertezas.publicar.mensagem')
      ).then(() => {
        this.publicarIncerteza(this.form.incerteza.dados.id);
      });
    },
    publicarIncerteza(idIncerteza) {
      if (
     !this.$refs['form-incerteza'].$refs.form.validate() ||
     !this.$refs['form-incerteza'].$refs['amplitudeForm'].validate()
   )
     return;
      this.$store.dispatch('Layout/iniciarCarregamento');

      IncertezasService.salvar(this.form.incerteza.dados.request)
        .then(() => {
          IncertezasService.publicar(idIncerteza)
            .then(() => {
              this.toastSucesso(this.$t(`modulos.incertezas.publicar_sucesso`));
              this.$router.push({ name: 'incertezas' });
            })
            .finally(() => {
              this.$store.dispatch('Layout/terminarCarregamento');
            });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    async salvarIncerteza(acao) {
      this.$refs['form-incerteza']?.podeJustificativaNula();
      await this.$nextTick();
      if (
        !this.$refs['form-incerteza'].$refs.form.validate() ||
        !this.$refs['form-incerteza'].$refs['amplitudeForm'].validate()
      )
        return;

      this.$store.dispatch('Layout/iniciarCarregamento');

      await IncertezasService.salvar(this.form.incerteza.dados.request)
        .then(async (res) => {
          this.dadosNaoSalvos = false;
          this.primeiraVez = true;
          if (acao === 'sair') {
            this.toastSucesso(
              this.$t('modulos.incertezas.formulario.salvar_incerteza')
            );
            this.$router.push({ name: 'incertezas' });
          } else if (acao === 'continuar') {
            this.toastSucesso(
              this.$t('modulos.incertezas.formulario.salvar_incerteza')
            );
            if (!this.form.incerteza.dados.id) {
              this.$router.push({
                name: 'incertezas-editar',
                params: { id: res.data.incertezaId },
              });
              this.definirModo();
              return;
            }
            this.buscarIncerteza();
            this.$nextTick(() => this.irParaAbaComponentes());
          } else if (acao === 'publicar') {
            this.publicarIncerteza(res.data.incertezaId);
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    irParaAbaComponentes() {
      this.tab = 1;
      this.tabComponentes = 1;
    },
    modoEdicao() {
      this.modo = 'edicao';
    },
    modoVisualizacao() {
      this.modo = 'visualizacao';
    },
    modoCriacao() {
      this.modo = 'criacao';
    },
    async buscarIncerteza() {
      this.$store.dispatch('Layout/iniciarCarregamento');

      await IncertezasService.buscar(this.id)
        .then(async (res) => {
          this.form.incerteza.dados = new IncertezaModel(res.data);
          this.dadosNaoSalvos = false;
          this.primeiraVez = true;
          this.badges.codigo = res.data.codigo;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.incertezas.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    fecharModalFormularioComponente() {
      this.$refs['modal-formulario-componente'].fecharModal();
      this.form.componente.valido = false;
    },
    abrirModalFormularioComponente(modo) {
      if (modo === 'criacao' && this.form.componente) {
        this.form.componente.dados = new ComponentesIncertezaModel({});
        (this.form.componente.dados.unidadeMedidaContribuicaoId =
          this.form.incerteza.dados.unidadeMedidaId),
          (this.form.componente.dados.unidadeMedidaCiId =
            this.form.incerteza.dados.unidadeMedidaId),
          (this.form.componente.dados.unidadeMedidaIncertezaPadraoId =
            this.form.incerteza.dados.unidadeMedidaId);
      }
      this.$refs['modal-formulario-componente'].abrirModal(modo);
    },
    mostrarEditarComponente(item) {
      this.$store.dispatch('Layout/iniciarCarregamento');

      IncertezasService.buscarComponente(item.id)
        .then((res) => {
          this.form.componente.dados = new ComponentesIncertezaModel(res.data);
          this.abrirModalFormularioComponente('edicao');
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarRevisaoDeIncerteza() {
      this.$store.dispatch('Layout/iniciarCarregamento');

      IncertezasService.buscarRevisao(this.idRevisao)
        .then((res) => {
          this.form.incerteza.dados = new IncertezaModel(res.data);
          this.form.incerteza.dados.revisaoId = this.idRevisao;
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    irParaAbaGraficoContribuicoes() {
      this.$nextTick(() => {
        this.tab = 2;
        this.tabComponentes = 2;
      });
    },
    async salvarComponente() {
      if (!this.$refs['modal-formulario-componente'].$refs.form.validate())
        return;
      this.$store.dispatch('Layout/iniciarCarregamento');

      await IncertezasService.salvarComponente(
        this.form.componente.dados.request,
        this.form.incerteza.dados.request.id
      )
        .then(async () => {
          this.dadosNaoSalvos = false;
          this.primeiraVez = true;
          this.toastSucesso(
            this.$t(`modulos.incertezas.formulario.salvar_componente`)
          );
          this.fecharModalFormularioComponente();
          await this.$refs['listagem-componentes'].listarRegistros();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar() {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'incertezas' });
      });
    },
    voltar() {
      this.$router.push({ name: 'incertezas' });
    },
    atualizarDiagrama() {
      if (this.$refs.diagrama != null) this.$refs.diagrama.buscarComponentes();
    },
    async verificarSeTemDadoNaoSalvo() {
      this.$refs['form-incerteza']?.podeJustificativaNula();
      await this.$nextTick();
      if (this.modo == 'visualizacao') {
        this.dadosNaoSalvos = false;
        this.tabComponentes = this.tab;
        return;
      }
      const dadosValido = this.$refs['form-incerteza'].$refs.form.validate();
      const amplitudeValida =
        this.$refs['form-incerteza'].$refs['amplitudeForm'].validate();
      if (!dadosValido || !amplitudeValida) {
        this.$nextTick(() => {
          this.tab = this.tabComponentes;
        });
        return;
      }

      if (!this.form.incerteza.dados.id) {
        this.tab = this.tabComponentes;
        this.salvarIncerteza('continuar');
      }

      if (this.dadosNaoSalvos) {
        try {
          if (this.tab != 0 && this.tabComponentes == 0) {
            await this.salvarIncerteza().then(() => {
              this.toastSucesso(
                this.$t('modulos.incertezas.formulario.salvar_incerteza')
              );
            });
          } else if (this.tab != 1 && this.tabComponentes == 1) {
            await this.$refs['listagem-componentes'].salvar();
          }
          this.dadosNaoSalvos = false;
          this.tabComponentes = this.tab;
        } catch (error) {
          this.dadosNaoSalvos = false;
          this.tab = this.tabComponentes;
        }
      } else {
        this.dadosNaoSalvos = false;
        this.tabComponentes = this.tab;
      }
    },
    modificarFormulario(booleano = true) {
      this.dadosNaoSalvos = booleano;
    },
    temComponentesCriados(temComponente) {
      this.form.incerteza.dados.possuiComponentes = temComponente;
    },
    buscarVariaveisUnidadeMedidaMensurando(id) {
      FormulaService.buscar(id)
        .then((res) => {
          this.unidadeMedidaFormulaMensurando = new DropdownModel(
            res.data.unidadeMedida
          );
          this.opcoesMetodoCi = res.data.metodosNumericos;
        })
        .then(() => {
          this.form.incerteza.dados.unidadeMedidaId =
            this.unidadeMedidaFormulaMensurando;
        });
    },
  },
};
</script>
