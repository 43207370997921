<template>
  <div>
    <div class="row mt-3 mx-0 flex-wrap">
      <!-- Indicadores -->
      <div class="row justify-space-between mt-6 mb-3 mx-0 flex-wrap">
        <div
          v-for="(indicador, index) in indicadores"
          :key="`indicador-${index}`"
          class="col-12 col-md-auto dados-cabecalho-item"
        >
          <span>
            {{ `${indicador.title} : ${indicador.value}` }}
          </span>
        </div>
      </div>
    </div>
    <div class="d-flex align-center justify-space-between mt-6">
      <div class="d-flex">
        <botao-padrao
          v-if="!naoPodeMoverParaCima"
          color="secondary"
          outlined
          @click="movimentarItem('cima')"
        >
          <v-icon> $arrowUp </v-icon>
        </botao-padrao>
        <botao-padrao
          v-if="!naoPodeMoverParaBaixo"
          color="secondary"
          outlined
          :class="{ 'ml-2': !naoPodeMoverParaCima }"
          @click="movimentarItem('baixo')"
        >
          <v-icon> $arrowDown </v-icon>
        </botao-padrao>
        <botao-padrao
          v-if="tabela.selecionados.length && !revisao"
          color="danger"
          outlined
          :class="{ 'ml-2': !naoPodeMoverParaBaixo || !naoPodeMoverParaCima }"
          @click="excluirItem"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>
      <div>
        <botao-padrao
          :disabled="revisao"
          @click="abrirModalFormularioCriarComponente"
        >
          {{ $t('geral.botoes.criar_componente') }}
        </botao-padrao>
      </div>
    </div>

    <tabela-padrao
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-4"
      :colunas="tabela.colunas"
      sem-selecionar-todos
      :por-pagina="-1"
      selecionar-apenas-um
      sem-paginacao
      :mostrar-checkbox="!revisao"
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:item.acoes="{ item }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item
            v-if="revisao"
            @click="abrirModalFormularioEditarComponente(item.item)"
          >
            {{ $t('geral.botoes.visualizar') }}
          </dropdown-padrao-item>
          <template v-else>
            <dropdown-padrao-item
              @click="abrirModalFormularioEditarComponente(item.item)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item @click="duplicarComponente(item.item)">
              {{ $t('geral.botoes.duplicar') }}
            </dropdown-padrao-item>
          </template>
        </dropdown-padrao>
      </template>
    </tabela-padrao>

    <v-divider class="my-5" />

    <div class="d-flex align-center justify-end mt-4">
      <botao-padrao
        v-if="revisao"
        outlined
        color="secondary"
        class="mr-2"
        @click="voltar"
      >
        <v-icon>$mdiArrowLeft</v-icon>
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
      <template v-else>
        <botao-padrao
          outlined
          color="secondary"
          @click="cancelar"
        >
          <v-icon>$mdiCancel</v-icon>
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>
        <botao-padrao
          sem-capitalize
          class="mx-2"
          @click="confirmacaoSalvarContinuar"
        >
          <v-icon>$mdiContentSaveMoveOutline</v-icon>
          {{ $t('geral.botoes.salvar_continuar') }}
        </botao-padrao>

        <botao-padrao
          sem-capitalize
          class="mr-2"
          @click="confirmacaoSalvarSair"
        >
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.salvar_sair') }}
        </botao-padrao>

        <botao-padrao
          v-if="form.incerteza.dados.id"
          sem-capitalize
          @click="confirmacaoPublicar"
        >
          <v-icon>$mdiPublish</v-icon>
          {{ $t('geral.botoes.publicar') }}
        </botao-padrao>
      </template>
    </div>
  </div>
</template>

<script>
import IncertezasService from '@/common/services/cadastros/IncertezasService';
import helpers from '@common/utils/helpers';
export default {
  props: {
    id: { type: String, required: true },
    revisao: { type: Boolean, required: false },
    form: { type: Object, required: true },
  },
  data() {
    return {
      primeiraVez: true,
      tabela: {
        selecionados: [],
        indicadores: {
          incertezaCombinada: null,
          veff: null,
          fatorK: null,
          incertezaExpandida: null,
          incertezaExpandidaComArredondamento: null,
        },
        dados: [],
        colunas: [
          {
            sortable: false,
            value: 'acoes',
            text: '',
          },
          {
            text: this.$t('modulos.incertezas.formulario.componentes.xi'),
            sortable: true,
            value: 'xi',
          },
          {
            text: this.$t(
              'modulos.incertezas.formulario.componentes.descricao'
            ),
            sortable: true,
            value: 'descricao',
          },
          {
            text: this.$t('modulos.incertezas.formulario.componentes.tipoA'),
            sortable: true,
            value: 'tipoA',
            formatter: (value) => {
              return value ? 'Sim' : 'Não';
            },
          },
          {
            text: this.$t(
              'modulos.incertezas.formulario.componentes.incerteza_padrao'
            ),
            sortable: true,
            value: 'incertezaPadrao',
            formatter: (value) => {
              if (value || value == 0) {
                return helpers.formatarNumeroComPrecisao(value);
              }
            },
          },
        ],
      },
    };
  },
  computed: {
    indicadores() {
      return [
        {
          title: this.$t('modulos.incertezas.indicadores.incerteza_combinada'),
          value: this.tabela.indicadores.incertezaCombinada
            ? helpers.formatarNumeroComPrecisao(
                this.tabela.indicadores.incertezaCombinada
              )
            : 0,
        },
        {
          title: this.$t('modulos.incertezas.indicadores.veff'),
          value: this.tabela.indicadores.veff
            ? helpers.formatarNumeroComPrecisao(this.tabela.indicadores.veff)
            : 0,
        },
        {
          title: this.$t('modulos.incertezas.indicadores.fator_k'),
          value: this.tabela.indicadores.fatorK
            ? helpers.formatarNumeroComPrecisao(this.tabela.indicadores.fatorK)
            : 0,
        },
        {
          title: this.$t('modulos.incertezas.indicadores.incerteza_expandida'),
          value: this.tabela.indicadores.incertezaExpandida
            ? helpers.formatarNumeroComPrecisao(
                this.tabela.indicadores.incertezaExpandida
              )
            : 0,
        },
        {
          title: this.$t(
            'modulos.incertezas.indicadores.incerteza_expandida_com_arredondamento'
          ),
          value: this.tabela.indicadores.incertezaExpandidaArrendondamento
            ? helpers.formatarNumeroComPrecisao(
                this.tabela.indicadores.incertezaExpandidaArrendondamento
              )
            : 0,
        },
      ];
    },
    naoPodeMoverParaBaixo() {
      if (this.revisao || this.tabela.selecionados.length === 0) return true;

      for (const selecionado of this.tabela.selecionados) {
        const index = this.tabela.dados.findIndex(
          (dado) => dado.id === selecionado.id
        );

        if (index < this.tabela.dados.length - 1) return false;
      }
      return true;
    },
    naoPodeMoverParaCima() {
      if (this.revisao || this.tabela.selecionados === 0) return true;

      for (const selecionado of this.tabela.selecionados) {
        const index = this.tabela.dados.findIndex(
          (dado) => dado.id === selecionado.id
        );

        if (index > 0) return false;
      }

      return true;
    },
  },
  watch: {
    'tabela.dados': {
      handler() {
        if (!this.primeiraVez) {
          this.$emit('modificar-formulario');
        }
        this.primeiraVez = false;
      },
      deep: true,
    },
  },
  mounted() {
    this.listarRegistros();
  },
  methods: {
    cancelar() {
      this.$emit('cancelar');
    },
    listarRegistros() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      IncertezasService.listarComponentes(this.id)
        .then((res) => {
          const componentes = res.data.componentes.map((dado) => ({
            id: dado.xi,
            ...dado,
          }));

          this.tabela.dados = componentes;

          this.tabela.indicadores.fatorK = res.data.fatorK;
          this.tabela.indicadores.incertezaCombinada =
            res.data.incertezaCombinada;
          this.tabela.indicadores.incertezaExpandida =
            res.data.incertezaExpandida;
          this.tabela.indicadores.incertezaExpandidaArrendondamento =
            res.data.incertezaExpandidaArrendondamento;
          this.tabela.indicadores.veff = res.data.veff;

          this.$emit('tem-componentes-criados', this.tabela.dados.length > 0);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.$emit('atualizar-diagrama');
        });
    },
    confirmacaoPublicar() {
      this.$emit('publicar');
    },
    confirmacaoSalvarContinuar() {
      this.salvarContinuar();
    },
    confirmacaoSalvarSair() {
      this.confirmarSalvar().then(this.salvarSair);
    },
    salvarContinuar() {
      this.salvar('continuar');
    },
    salvarSair() {
      this.salvar('sair');
    },
    async salvar(acao) {
      this.$store.dispatch('Layout/iniciarCarregamento');

      const payload = { componentesIds: this.tabela.dados.map(({ id }) => id) };

      await IncertezasService.salvarSequencia(this.id, payload)
        .then(() => {
          this.$emit('modificar-formulario', false);
          this.primeiraVez = true;
          this.toastSucesso(
            this.$t('modulos.incertezas.formulario.salvar_sequencia')
          );
          this.listarRegistros();
          this.tabela.selecionados = [];

          if (acao === 'continuar') {
            this.$emit('ir-para-aba-grafico-contribuicoes');
          } else if (acao === 'sair') {
            this.$router.push({ name: 'incertezas' });
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    movimentarItem(direcao) {
      const incremento = direcao === 'cima' ? -1 : 1;

      let novaLista = this.tabela.dados;

      const index = novaLista.findIndex(
        (dado) => dado.id === this.tabela.selecionados[0].id
      );

      const copy = novaLista[index + incremento];

      novaLista[index + incremento] = novaLista[index];
      novaLista[index] = copy;

      this.tabela.dados = [...novaLista];
    },
    excluirItem() {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');

        IncertezasService.excluirItem(this.tabela.selecionados[0].id)
          .then(() => {
            this.tabela.selecionados = [];
            this.toastSucesso(
              this.$t('modulos.incertezas.excluir_componente_sucesso')
            );
            this.listarRegistros();
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    abrirModalFormularioCriarComponente() {
      this.$emit('criar-componente');
    },
    abrirModalFormularioEditarComponente(item) {
      this.$emit('editar-componente', item);
    },
    duplicarComponente(item) {
      this.$store.dispatch('Layout/iniciarCarregamento');

      IncertezasService.duplicarComponente(item.id)
        .then((response) => {
          this.toastSucesso(
            this.$t('modulos.incertezas.duplicar_componente_sucesso', {
              xi: response.data,
            })
          );

          this.listarRegistros();
        })
        .catch((error) => {
          this.toastErro(
            this.$t(`geral.erros.${error.response.data.errors[0]}`)
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    voltar: function () {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.dados-cabecalho-item {
  background-color: #f9fafb;

  border: 1px solid #e4e7ec;
  border-radius: 8px;

  padding: 8px 16px;

  display: flex;
  justify-content: center;
}

.dados-cabecalho-item > span {
  color: #98a2b3;

  font-size: 14px;
  font-weight: 500;

  white-space: nowrap;
}
</style>
