<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <modal-padrao
    ref="modal-componente"
    max-width="90%"
    persistente
    :titulo-ok="$t('geral.botoes.salvar')"
    :titulo-cancelar="$t('geral.botoes.voltar')"
    :mostraBotaoSalvar="!revisao"
    @ok="confirmacaoSalvar"
    @modal-fechado="modalFechado"
  >
    <template v-slot:titulo>
      <div class="d-flex align-center">
        <span>{{ titulo }}</span>
        <v-chip
          class="ml-2"
          small
          color="white"
        >
          {{
            $t('modulos.incertezas.formulario.componentes.codigo_incerteza', {
              codigo: incerteza.codigo,
            })
          }}
        </v-chip>
      </div>
    </template>
    <div>
      <v-form ref="form">
        <div class="row mt-2">
          <input-text
            v-model="form.dados.descricao"
            class="col-12 col-md-5"
            :label="$t('modulos.incertezas.formulario.componentes.descricao')"
            :disabled="revisao"
            obrigatorio
          />
          <input-select
            v-model="form.dados.fonteIncerteza"
            class="col-12 col-md-3"
            :disabled="revisao"
            :label="
              $t('modulos.incertezas.formulario.componentes.fonte_incerteza')
            "
            :options="opcoes.fontesIncerteza"
            obrigatorio
          />
          <input-radio
            v-model="form.dados.tipoA"
            :disabled="revisao"
            class="col"
            :divider="false"
            :class="{
              'col-md-4': !form.dados.id,
              'col-md-auto': form.dados.id,
            }"
            :label="$t('modulos.incertezas.formulario.componentes.tipoA')"
            :options="opcoes.tipoA"
          />
          <input-text
            v-if="form.dados.id"
            v-model="form.dados.xi"
            class="col"
            :label="$t('modulos.incertezas.formulario.componentes.xi')"
            disabled
          />
        </div>
        <div class="row">
          <input-unidade-medida
            v-model="form.dados.unidadeMedidaContribuicaoId"
            :disabled="revisao"
            class="col-12 col-md-4"
            :label="
              $t(
                'modulos.incertezas.formulario.componentes.unidade_da_contribuicao'
              )
            "
            :multiplos="false"
            obrigatorio
          />
          <input-select
            v-model="form.dados.origemContribuicao"
            :disabled="revisao"
            class="col-12 col-md-4"
            :label="
              $t(
                'modulos.incertezas.formulario.componentes.origem_da_contribuicao'
              )
            "
            :options="opcoes.origemContribuicao"
            obrigatorio
          />
          <!-- NÃO APAGAR ESSE INPUT ESPERANDO POSICIONAMENTO DA K&L -->
          <input-incerteza
            v-if="false"
            v-model="form.dados.incertezaAuxiliarId"
            class="col-12 col-md-4"
            :label="
              $t('modulos.incertezas.formulario.componentes.incerteza_auxiliar')
            "
            apenas-publicados
            sem-revisao
            nivel-unico-incerteza-auxiliar
            :formula="incerteza.formulaId"
            :ignore-incerteza-id="incerteza.id"
            :disabled="!origemContribuicaoEhIncerteza || revisao"
            :multiplos="false"
          />
          <!-- :obrigatorio="origemContribuicaoEhIncerteza" -->
          <!-- NÃO APAGAR ESSE INPUT ESPERANDO POSICIONAMENTO DA K&L -->
          <input-select
            v-model="form.dados.variavel"
            class="col-12 col-md-4"
            :label="$t('modulos.incertezas.formulario.componentes.variavel')"
            :placeholder="$t('geral.inputs.selecione')"
            :options="variaveis"
            :disabled="revisao || variavelDesabilitado"
            :obrigatorio="variavelObrigatoria"
            retornar-objeto
            clearable
          />

          <input-ide
            ref="formulaContribuicao"
            v-model="form.dados.formulaContribuicao"
            class="col-12 col-md-12"
            obrigatorio
            :disabled="revisao"
            :label="
              $t(
                'modulos.incertezas.formulario.componentes.formula_da_contribuicao'
              )
            "
            :formula-mensurando-id="
              incerteza.formulaId && incerteza.formulaId.value
            "
          />
        </div>
        <div class="row">
          <input-select
            v-model="form.dados.distribuicaoEstatistica"
            class="col-12 col-md-3"
            :label="
              $t(
                'modulos.incertezas.formulario.componentes.distribuicao_estatistica'
              )
            "
            :options="opcoes.distribuicaoEstatistica"
            obrigatorio
            :disabled="revisao || origemContribuicaoIncertezaTipoANao"
          />
          <input-ide
            ref="formulaDE"
            v-model="form.dados.formulaDE"
            class="col-12 col-md-9"
            :label="$t('modulos.incertezas.formulario.componentes.formula_de')"
            :disabled="
              form.dados.distribuicaoEstatistica !== 'Normal' || revisao || origemContribuicaoIncertezaTipoANao
            "
            :obrigatorio="
              form.dados.distribuicaoEstatistica === 'Normal' &&
                form.dados.tipoA
            "
            :formula-mensurando-id="
              incerteza.formulaId && incerteza.formulaId.value
            "
          />
          <input-unidade-medida
            v-model="form.dados.unidadeMedidaCiId"
            class="col-12 col-md-3"
            :label="$t('modulos.incertezas.formulario.componentes.unidade_ci')"
            :multiplos="false"
            obrigatorio
            :disabled="revisao"
          />
          <input-ide
            ref="formulaCI"
            v-model="form.dados.formulaCi"
            class="col-12 col-md-6"
            :label="$t('modulos.incertezas.formulario.componentes.formula_ci')"
            :disabled="estruturaUtilizadaEhMetodosNumericos || revisao"
            :obrigatorio="estruturaUtilizadaEhDerivadaParcial"
            :formula-mensurando-id="
              incerteza.formulaId && incerteza.formulaId.value
            "
          />
          <input-select
            v-model="form.dados.metodoNumericoCi"
            class="col-12 col-md-3"
            :label="
              $t('modulos.incertezas.formulario.componentes.metodo_numerico_ci')
            "
            :obrigatorio="estruturaUtilizadaEhMetodosNumericos"
            :disabled="estruturaUtilizadaEhDerivadaParcial || revisao"
            :options="opcoes.metodoCI"
          />

          <input-unidade-medida
            v-model="form.dados.unidadeMedidaIncertezaPadraoId"
            class="col-12 col-md-4"
            :disabled="revisao"
            :label="
              $t(
                'modulos.incertezas.formulario.componentes.unidade_da_incerteza_padrao'
              )
            "
            :multiplos="false"
            obrigatorio
          />
          <input-text
            v-model="form.dados.grausLiberdadeFormulaTexto"
            class="col-12 col-md-5"
            :label="
              $t('modulos.incertezas.formulario.componentes.grau_liberdade')
            "
            disabled
          />
        </div>
        <accordion-padrao
          class="mb-4"
          :titulo="
            $t(
              'modulos.incertezas.formulario.componentes.valores_de_teste.titulo'
            )
          "
        >
          <div class="row">
            <input-text
              v-model="form.dados.teste.valorTesteContribuicao"
              type="number"
              class="col-12 col-md-3"
              :label="
                $t(
                  'modulos.incertezas.formulario.componentes.valores_de_teste.contribuicao'
                )
              "
              :disabled="revisao"
              :placeholder="
                $t(
                  'modulos.incertezas.formulario.componentes.valores_de_teste.contribuicao'
                )
              "
              obrigatorio
            />
            <input-text
              v-model="form.dados.teste.valorTesteDe"
              type="number"
              class="col-12 col-md-2"
              :label="
                $t(
                  'modulos.incertezas.formulario.componentes.valores_de_teste.de'
                )
              "
              :disabled="revisao"
              :regras-personalizadas="regraDE()"
              :error="error.DE.status"
              :error-messages="error.DE.message"
              :min="1"
              :placeholder="
                $t(
                  'modulos.incertezas.formulario.componentes.valores_de_teste.de'
                )
              "
              obrigatorio
            />
            <input-text
              v-model="form.dados.teste.valorTesteCi"
              type="number"
              class="col-12 col-md-2"
              :label="
                $t(
                  'modulos.incertezas.formulario.componentes.valores_de_teste.ci'
                )
              "
              :disabled="revisao"
              :placeholder="
                $t(
                  'modulos.incertezas.formulario.componentes.valores_de_teste.ci'
                )
              "
              obrigatorio
            />
            <input-text
              v-model="form.dados.teste.grausLiberdade"
              type="number"
              class="col-12 col-md-3"
              :label="
                $t(
                  'modulos.incertezas.formulario.componentes.valores_de_teste.graus_liberdade'
                )
              "
              :disabled="revisao"
              obrigatorio
              :placeholder="
                $t(
                  'modulos.incertezas.formulario.componentes.valores_de_teste.graus_liberdade'
                )
              "
              :regras-personalizadas="regraNumeroDeCiclos()"
            />
            <input-text
              v-model="form.dados.teste.incertezaPadrao"
              type="number"
              disabled
              class="col-12 col-md-2"
              :label="
                $t(
                  'modulos.incertezas.formulario.componentes.valores_de_teste.incerteza_padrao'
                )
              "
              :placeholder="
                $t(
                  'modulos.incertezas.formulario.componentes.valores_de_teste.incerteza_padrao'
                )
              "
            />
          </div>
        </accordion-padrao>
      </v-form>
    </div>
  </modal-padrao>
</template>

<script>
import IncertezasService from '@/common/services/cadastros/IncertezasService';
import { InputUnidadeMedida, InputIncerteza } from '@/components/inputs';
import EnumeradorService from '@/common/services/cadastros/EnumeradorService';
import FormulaService from '@/common/services/cadastros/FormulaService';
import { orderBy } from 'lodash';
import { DropdownModel } from '@/common/models/geral/DropdownModel';
export default {
  components: {
    InputUnidadeMedida,
    InputIncerteza,
  },
  props: {
    form: { type: Object, default: () => {} },
    incerteza: { type: Object, default: () => {} },
    listaUnidadesDeMedida: { type: Array, default: () => [] },
    revisao: { type: Boolean, default: false },
    unidadeMedidaFormulaMensurando: { type: Object, default: () => {} },
    opcoesMetodoCi: { type: Array, default: () => [] },
  },
  data() {
    return {
      modo: '',
      formulas: {
        RetangularTriangular: 'SQRT(3)*SQRT(6)',
        Normal: '',
        Retangular: 'SQRT(3)',
        Triangular: 'SQRT(6)',
      },
      error: {
        DE: {
          status: false,
          message: '',
        },
      },
      devePreencherIncertezaDoDado: false,
      devePreencherFormulaDoDado: false,
      opcoes: {
        distribuicaoEstatistica: [],
        origemContribuicao: [],
        fontesIncerteza: [],
        tipoA: [
          { text: this.$t('geral.botoes.sim'), value: true },
          { text: this.$t('geral.botoes.nao'), value: false },
        ],
        variaveis: [],
        metodoCI: [],
      },
      valorTesteDeAutomatico: {
        Retangular: 1.732051,
        RetangularTriangular: 4.242641,
        Triangular: 2.44949,
      },
      distribuicaoEstatisticaWatcher: null,
      origemDistribuicaoWatcher: null,
      variavelWatcher: null,
      tipoAWatcher: null,
    };
  },
  computed: {
    origemContribuicaoIncertezaTipoANao() {
      return (
        this.form.dados.origemContribuicao === 'Incerteza' &&
        !this.form.dados.tipoA
      );
    },
    origemContribuicaoEhIncerteza() {
      return this.form.dados.origemContribuicao === 'Incerteza';
    },
    estruturaUtilizadaEhDerivadaParcial() {
      return this.incerteza.estruturaUtilizada === 'DerivadaParcial';
    },
    estruturaUtilizadaEhMetodosNumericos() {
      return this.incerteza.estruturaUtilizada === 'MetodosNumericos';
    },
    variavelDesabilitado() {
      if (
        !this.form.dados.tipoA &&
        this.form.dados.origemContribuicao !== 'Incerteza'
      ) {
        return true;
      }
      return false;
    },
    variavelObrigatoria() {
      if (
        (!this.form.dados.tipoA &&
          this.form.dados.origemContribuicao === 'Incerteza') ||
        this.form.dados.tipoA
      ) {
        return true;
      }

      return false;
    },
    variaveis() {
      if (this.form.dados.tipoA) {
        return [...this.opcoes.variaveis, { text: 'SMC', value: null }];
      }
      return this.opcoes.variaveis;
    },
    titulo() {
      const { name } = this.$route;
      if (name == 'incertezas-revisoes-visualizar')
        return `${this.$t(
          'modulos.incertezas.formulario.componentes.visualizar_componente'
        )}`;
      if (this.modo === 'criacao')
        return `${this.$t(
          'modulos.incertezas.formulario.componentes.novo_componente'
        )}`;

      if (this.modo === 'edicao')
        return `${this.$t(
          'modulos.incertezas.formulario.componentes.editar_componente'
        )}`;

      return '';
    },
  },
  watch: {
    'form.dados.teste': {
      deep: true,
      immediate: true,
      handler(teste) {
        if (
          (teste.valorTesteCi || teste.valorTesteCi == 0) &&
          (teste.valorTesteDe || teste.valorTesteDe == 0) &&
          (teste.valorTesteContribuicao || teste.valorTesteContribuicao == 0)
        ) {
          this.form.dados.teste.incertezaPadrao =
            (parseFloat(teste.valorTesteContribuicao) *
              parseFloat(teste.valorTesteCi)) /
            parseFloat(teste.valorTesteDe);
        }
      },
    },
  },
  mounted() {
    this.listarOrigemContribuicao();
    this.listarDistribuicaoEstatistica();
    this.buscarFontesIncerteza();
    this.preencherUnidadeMedida();
  },
  methods: {
    regraNumeroDeCiclos: function () {
      if (!this.form.dados.teste.grausLiberdade) return [];

      const numeroDeCiclorMairQueUm = (v) => {
        if (parseFloat(v) < 1)
          return this.$t(
            'modulos.incertezas.formulario.componentes.error.regra_graus_liberdade'
          );
        else if (parseFloat(v) > 1000)
          return this.$t(
            'modulos.incertezas.formulario.componentes.error.regra_graus_liberdade_max'
          );
        else return true;
      };


      return [numeroDeCiclorMairQueUm];
    },
    regraDE() {
      if (this.form.dados.teste.de === '0') {
        return [
          this.$t(
            'modulos.incertezas.formulario.componentes.error.regraDE.zero'
          ),
        ];
      }

      if (!this.form.dados.teste.valorTesteDe) {
        return [
          this.$t(
            'modulos.incertezas.formulario.componentes.error.regraDE.obrigatorio'
          ),
        ];
      }

      return [];
    },
    modalFechado() {
      if (this.distribuicaoEstatisticaWatcher)
        this.distribuicaoEstatisticaWatcher();
      if (this.origemDistribuicaoWatcher) this.origemDistribuicaoWatcher();
      if (this.variavelWatcher) this.variavelWatcher();
    },
    abrirModal(modo) {
      if (this.origemDistribuicaoWatcher) {
        this.origemDistribuicaoWatcher();
      }
      if (this.variavelWatcher) {
        this.variavelWatcher();
      }
      this.modo = modo;
      this.$refs['modal-componente'].abrirModal();
      this.$nextTick(() => {
        this.$refs['form'].resetValidation();
        this.$refs['formulaContribuicao'].desvalidar();
        this.$refs['formulaDE'].desvalidar();
        this.$refs['formulaCI'].desvalidar();
        this.distribuicaoEstatisticaWatcher = this.$watch(
          'form.dados.distribuicaoEstatistica',
          (value) => {
            this.form.dados.formulaDE = this.formulas[value];
            if (value === 'Normal') return;
            this.form.dados.teste.valorTesteDe =
              this.valorTesteDeAutomatico[value];
          }
        );
      });
      this.origemDistribuicaoWatcher = this.$watch(
        'form.dados.origemContribuicao',
        (origemDistribuicao) => {
          //NÃO APAGAR - ESSE IF ERA A REGRA QUANDO TINHA O INPUT INCERTEZA AUXILIAR
          // if (origemDistribuicao === 'Incerteza')
          //   return
          //   this.form.dados.formulaContribuicao = null;
          // else {
          // }
          console.log(origemDistribuicao);
          if(origemDistribuicao === 'Incerteza' && !this.form.dados.tipoA)
            {
              this.form.dados.distribuicaoEstatistica = 'Normal';
            }
          this.form.dados.incertezaAuxiliarId = null;
          this.form.dados.variavel = undefined;
        }
      );
      this.variavelWatcher = this.$watch('form.dados.variavel', (variavel) => {
        this.form.dados.grausLiberdadeFormulaTexto =
          this.grauLiberdadeAutomatico(variavel, this.form.dados.tipoA);
        if (
          !this.form.dados.tipoA &&
          this.form.dados.origemContribuicao === 'Incerteza' &&
          !!variavel?.text
        ) {
          this.form.dados.formulaDE = `MAX({${variavel?.text}.instrumentoPadrao.ultimoCertificado.fatorK})`;
        } //else this.form.dados.formulaDE = '';

        if (variavel && variavel?.text === 'SMC') {
          this.form.dados.arredondamentoSmc = 'ResolucaoSMC';
        } else if (variavel && variavel?.text !== 'SMC')
          this.form.dados.arredondamentoSmc = 'ResolucaoSMP';
        else this.form.dados.arredondamentoSmc = '';
      });
      this.tipoAWatcher = this.$watch('form.dados.tipoA', (tipoA) => {
        if(!tipoA && this.form.dados.origemContribuicao === 'Incerteza')
          this.form.dados.distribuicaoEstatistica = 'Normal';
        this.form.dados.variavel = undefined;
        this.form.grausLiberdadeFormulaTexto = '';
        this.form.arredondamentoSmc = '';
      });
    },
    fecharModal() {
      this.$refs['modal-componente'].fecharModal();
    },
    confirmacaoSalvar() {
      this.confirmarSalvar().then(this.salvar);
    },
    salvar() {
      this.$refs['formulaContribuicao'].validar();
      this.$refs['formulaCI'].validar();
      this.$refs['formulaDE'].validar();
      this.$emit('salvar');
    },
    buscarFontesIncerteza() {
      EnumeradorService.buscar('EnumFonteIncerteza').then((res) => {
        this.opcoes.fontesIncerteza = res;
      });
    },
    listarOrigemContribuicao() {
      this.$store.dispatch('Layout/iniciarCarregamento');

      IncertezasService.listarEnumOrigemContribuicao()
        .then((res) => {
          this.opcoes.origemContribuicao = orderBy(res.data, ['text'], ['asc']);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    listarDistribuicaoEstatistica() {
      this.$store.dispatch('Layout/iniciarCarregamento');

      IncertezasService.listarEnumDistribuicaoEstatistica()
        .then((res) => {
          this.opcoes.distribuicaoEstatistica = orderBy(
            res.data,
            ['text'],
            ['asc']
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    preencherUnidadeMedida() {
      this.form.dados.unidadeMedidaContribuicaoId =
        this.incerteza.unidadeMedidaId;
      this.form.dados.unidadeMedidaCiId = this.incerteza.unidadeMedidaId;
      this.form.dados.unidadeMedidaIncertezaPadraoId =
        this.incerteza.unidadeMedidaId;
    },
    buscarVariaveisMensurando(id) {
      FormulaService.buscar(id).then((res) => {
        this.opcoes.metodoCI = res.data.metodosNumericos;
        this.opcoes.variaveis = new DropdownModel(
          res.data.variaveis,
          'simbolo'
        );
      });
    },
    grauLiberdadeAutomatico(variavel = false, tipoA) {
      if (!variavel?.text) {
        this.form.dados.teste.grausLiberdade = 1000;
        return '1000';
      }
      if (variavel?.text === 'SMC' && tipoA) {
        this.form.dados.teste.grausLiberdade = 2;
        return `{numeroCiclosSmc} - 1`;
      }
      if (variavel?.text && tipoA) {
        this.form.dados.teste.grausLiberdade = 2;
        return `{numeroCiclosSmp${variavel?.text}} - 1`;
      }
      if (variavel?.text && !tipoA) {
        this.form.dados.teste.grausLiberdade = 2;
        return `MIN({${variavel?.text}.instrumentoPadrao.ultimoCertificado.veff)})`;
      }
    },
  },
};
</script>
