/*eslint-disable*/
!(function () {
  function n(n) {
    return null != n && !isNaN(n);
  }
  function t(n) {
    return n.length;
  }
  function e(n) {
    for (var t = 1; (n * t) % 1; ) t *= 10;
    return t;
  }
  function r(n, t) {
    try {
      for (var e in t)
        Object.defineProperty(n.prototype, e, { value: t[e], enumerable: !1 });
    } catch (r) {
      n.prototype = t;
    }
  }
  function u() {}
  function i(n) {
    return aa + n in this;
  }
  function o(n) {
    return (n = aa + n), n in this && delete this[n];
  }
  function a() {
    var n = [];
    return (
      this.forEach(function (t) {
        n.push(t);
      }),
      n
    );
  }
  function c() {
    var n = 0;
    for (var t in this) t.charCodeAt(0) === ca && ++n;
    return n;
  }
  function s() {
    for (var n in this) if (n.charCodeAt(0) === ca) return !1;
    return !0;
  }
  function l() {}
  function f(n, t, e) {
    return function () {
      var r = e.apply(t, arguments);
      return r === t ? n : r;
    };
  }
  function h(n, t) {
    if (t in n) return t;
    t = t.charAt(0).toUpperCase() + t.substring(1);
    for (var e = 0, r = sa.length; r > e; ++e) {
      var u = sa[e] + t;
      if (u in n) return u;
    }
  }
  function g() {}
  function p() {}
  function v(n) {
    function t() {
      for (var t, r = e, u = -1, i = r.length; ++u < i; )
        (t = r[u].on) && t.apply(this, arguments);
      return n;
    }
    var e = [],
      r = new u();
    return (
      (t.on = function (t, u) {
        var i,
          o = r.get(t);
        return arguments.length < 2
          ? o && o.on
          : (o &&
              ((o.on = null),
              (e = e.slice(0, (i = e.indexOf(o))).concat(e.slice(i + 1))),
              r.remove(t)),
            u && e.push(r.set(t, { on: u })),
            n);
      }),
      t
    );
  }
  function d() {
    Xo.event.preventDefault();
  }
  function m() {
    for (var n, t = Xo.event; (n = t.sourceEvent); ) t = n;
    return t;
  }
  function y(n) {
    for (var t = new p(), e = 0, r = arguments.length; ++e < r; )
      t[arguments[e]] = v(t);
    return (
      (t.of = function (e, r) {
        return function (u) {
          try {
            var i = (u.sourceEvent = Xo.event);
            (u.target = n), (Xo.event = u), t[u.type].apply(e, r);
          } finally {
            Xo.event = i;
          }
        };
      }),
      t
    );
  }
  function x(n) {
    return fa(n, da), n;
  }
  function M(n) {
    return 'function' == typeof n
      ? n
      : function () {
          return ha(n, this);
        };
  }
  function _(n) {
    return 'function' == typeof n
      ? n
      : function () {
          return ga(n, this);
        };
  }
  function b(n, t) {
    function e() {
      this.removeAttribute(n);
    }
    function r() {
      this.removeAttributeNS(n.space, n.local);
    }
    function u() {
      this.setAttribute(n, t);
    }
    function i() {
      this.setAttributeNS(n.space, n.local, t);
    }
    function o() {
      var e = t.apply(this, arguments);
      null == e ? this.removeAttribute(n) : this.setAttribute(n, e);
    }
    function a() {
      var e = t.apply(this, arguments);
      null == e
        ? this.removeAttributeNS(n.space, n.local)
        : this.setAttributeNS(n.space, n.local, e);
    }
    return (
      (n = Xo.ns.qualify(n)),
      null == t
        ? n.local
          ? r
          : e
        : 'function' == typeof t
        ? n.local
          ? a
          : o
        : n.local
        ? i
        : u
    );
  }
  function w(n) {
    return n.trim().replace(/\s+/g, ' ');
  }
  function S(n) {
    return new RegExp('(?:^|\\s+)' + Xo.requote(n) + '(?:\\s+|$)', 'g');
  }
  function k(n) {
    return n.trim().split(/^|\s+/);
  }
  function E(n, t) {
    function e() {
      for (var e = -1; ++e < u; ) n[e](this, t);
    }
    function r() {
      for (var e = -1, r = t.apply(this, arguments); ++e < u; ) n[e](this, r);
    }
    n = k(n).map(A);
    var u = n.length;
    return 'function' == typeof t ? r : e;
  }
  function A(n) {
    var t = S(n);
    return function (e, r) {
      if ((u = e.classList)) return r ? u.add(n) : u.remove(n);
      var u = e.getAttribute('class') || '';
      r
        ? ((t.lastIndex = 0),
          t.test(u) || e.setAttribute('class', w(u + ' ' + n)))
        : e.setAttribute('class', w(u.replace(t, ' ')));
    };
  }
  function C(n, t, e) {
    function r() {
      this.style.removeProperty(n);
    }
    function u() {
      this.style.setProperty(n, t, e);
    }
    function i() {
      var r = t.apply(this, arguments);
      null == r
        ? this.style.removeProperty(n)
        : this.style.setProperty(n, r, e);
    }
    return null == t ? r : 'function' == typeof t ? i : u;
  }
  function N(n, t) {
    function e() {
      delete this[n];
    }
    function r() {
      this[n] = t;
    }
    function u() {
      var e = t.apply(this, arguments);
      null == e ? delete this[n] : (this[n] = e);
    }
    return null == t ? e : 'function' == typeof t ? u : r;
  }
  function L(n) {
    return 'function' == typeof n
      ? n
      : (n = Xo.ns.qualify(n)).local
      ? function () {
          return this.ownerDocument.createElementNS(n.space, n.local);
        }
      : function () {
          return this.ownerDocument.createElementNS(this.namespaceURI, n);
        };
  }
  function z(n) {
    return { __data__: n };
  }
  function q(n) {
    return function () {
      return va(this, n);
    };
  }
  function T(n) {
    return (
      arguments.length || (n = Xo.ascending),
      function (t, e) {
        return t && e ? n(t.__data__, e.__data__) : !t - !e;
      }
    );
  }
  function R(n, t) {
    for (var e = 0, r = n.length; r > e; e++)
      for (var u, i = n[e], o = 0, a = i.length; a > o; o++)
        (u = i[o]) && t(u, o, e);
    return n;
  }
  function D(n) {
    return fa(n, ya), n;
  }
  function P(n) {
    var t, e;
    return function (r, u, i) {
      var o,
        a = n[i].update,
        c = a.length;
      for (
        i != e && ((e = i), (t = 0)), u >= t && (t = u + 1);
        !(o = a[t]) && ++t < c;

      );
      return o;
    };
  }
  function U() {
    var n = this.__transition__;
    n && ++n.active;
  }
  function j(n, t, e) {
    function r() {
      var t = this[o];
      t && (this.removeEventListener(n, t, t.$), delete this[o]);
    }
    function u() {
      var u = c(t, Bo(arguments));
      r.call(this),
        this.addEventListener(n, (this[o] = u), (u.$ = e)),
        (u._ = t);
    }
    function i() {
      var t,
        e = new RegExp('^__on([^.]+)' + Xo.requote(n) + '$');
      for (var r in this)
        if ((t = r.match(e))) {
          var u = this[r];
          this.removeEventListener(t[1], u, u.$), delete this[r];
        }
    }
    var o = '__on' + n,
      a = n.indexOf('.'),
      c = H;
    a > 0 && (n = n.substring(0, a));
    var s = Ma.get(n);
    return s && ((n = s), (c = F)), a ? (t ? u : r) : t ? g : i;
  }
  function H(n, t) {
    return function (e) {
      var r = Xo.event;
      (Xo.event = e), (t[0] = this.__data__);
      try {
        n.apply(this, t);
      } finally {
        Xo.event = r;
      }
    };
  }
  function F(n, t) {
    var e = H(n, t);
    return function (n) {
      var t = this,
        r = n.relatedTarget;
      (r && (r === t || 8 & r.compareDocumentPosition(t))) || e.call(t, n);
    };
  }
  function O() {
    var n = '.dragsuppress-' + ++ba,
      t = 'click' + n,
      e = Xo.select(Go)
        .on('touchmove' + n, d)
        .on('dragstart' + n, d)
        .on('selectstart' + n, d);
    if (_a) {
      var r = Jo.style,
        u = r[_a];
      r[_a] = 'none';
    }
    return function (i) {
      function o() {
        e.on(t, null);
      }
      e.on(n, null),
        _a && (r[_a] = u),
        i &&
          (e.on(
            t,
            function () {
              d(), o();
            },
            !0
          ),
          setTimeout(o, 0));
    };
  }
  function Y(n, t) {
    t.changedTouches && (t = t.changedTouches[0]);
    var e = n.ownerSVGElement || n;
    if (e.createSVGPoint) {
      var r = e.createSVGPoint();
      if (0 > wa && (Go.scrollX || Go.scrollY)) {
        e = Xo.select('body')
          .append('svg')
          .style(
            {
              position: 'absolute',
              top: 0,
              left: 0,
              margin: 0,
              padding: 0,
              border: 'none',
            },
            'important'
          );
        var u = e[0][0].getScreenCTM();
        (wa = !(u.f || u.e)), e.remove();
      }
      return (
        wa
          ? ((r.x = t.pageX), (r.y = t.pageY))
          : ((r.x = t.clientX), (r.y = t.clientY)),
        (r = r.matrixTransform(n.getScreenCTM().inverse())),
        [r.x, r.y]
      );
    }
    var i = n.getBoundingClientRect();
    return [t.clientX - i.left - n.clientLeft, t.clientY - i.top - n.clientTop];
  }
  function I(n) {
    return n > 0 ? 1 : 0 > n ? -1 : 0;
  }
  function Z(n, t, e) {
    return (t[0] - n[0]) * (e[1] - n[1]) - (t[1] - n[1]) * (e[0] - n[0]);
  }
  function V(n) {
    return n > 1 ? 0 : -1 > n ? Sa : Math.acos(n);
  }
  function X(n) {
    return n > 1 ? Ea : -1 > n ? -Ea : Math.asin(n);
  }
  function $(n) {
    return ((n = Math.exp(n)) - 1 / n) / 2;
  }
  function B(n) {
    return ((n = Math.exp(n)) + 1 / n) / 2;
  }
  function W(n) {
    return ((n = Math.exp(2 * n)) - 1) / (n + 1);
  }
  function J(n) {
    return (n = Math.sin(n / 2)) * n;
  }
  function G() {}
  function K(n, t, e) {
    return new Q(n, t, e);
  }
  function Q(n, t, e) {
    (this.h = n), (this.s = t), (this.l = e);
  }
  function nt(n, t, e) {
    function r(n) {
      return (
        n > 360 ? (n -= 360) : 0 > n && (n += 360),
        60 > n
          ? i + ((o - i) * n) / 60
          : 180 > n
          ? o
          : 240 > n
          ? i + ((o - i) * (240 - n)) / 60
          : i
      );
    }
    function u(n) {
      return Math.round(255 * r(n));
    }
    var i, o;
    return (
      (n = isNaN(n) ? 0 : (n %= 360) < 0 ? n + 360 : n),
      (t = isNaN(t) ? 0 : 0 > t ? 0 : t > 1 ? 1 : t),
      (e = 0 > e ? 0 : e > 1 ? 1 : e),
      (o = 0.5 >= e ? e * (1 + t) : e + t - e * t),
      (i = 2 * e - o),
      gt(u(n + 120), u(n), u(n - 120))
    );
  }
  function tt(n, t, e) {
    return new et(n, t, e);
  }
  function et(n, t, e) {
    (this.h = n), (this.c = t), (this.l = e);
  }
  function rt(n, t, e) {
    return (
      isNaN(n) && (n = 0),
      isNaN(t) && (t = 0),
      ut(e, Math.cos((n *= Na)) * t, Math.sin(n) * t)
    );
  }
  function ut(n, t, e) {
    return new it(n, t, e);
  }
  function it(n, t, e) {
    (this.l = n), (this.a = t), (this.b = e);
  }
  function ot(n, t, e) {
    var r = (n + 16) / 116,
      u = r + t / 500,
      i = r - e / 200;
    return (
      (u = ct(u) * Fa),
      (r = ct(r) * Oa),
      (i = ct(i) * Ya),
      gt(
        lt(3.2404542 * u - 1.5371385 * r - 0.4985314 * i),
        lt(-0.969266 * u + 1.8760108 * r + 0.041556 * i),
        lt(0.0556434 * u - 0.2040259 * r + 1.0572252 * i)
      )
    );
  }
  function at(n, t, e) {
    return n > 0
      ? tt(Math.atan2(e, t) * La, Math.sqrt(t * t + e * e), n)
      : tt(0 / 0, 0 / 0, n);
  }
  function ct(n) {
    return n > 0.206893034 ? n * n * n : (n - 4 / 29) / 7.787037;
  }
  function st(n) {
    return n > 0.008856 ? Math.pow(n, 1 / 3) : 7.787037 * n + 4 / 29;
  }
  function lt(n) {
    return Math.round(
      255 * (0.00304 >= n ? 12.92 * n : 1.055 * Math.pow(n, 1 / 2.4) - 0.055)
    );
  }
  function ft(n) {
    return gt(n >> 16, 255 & (n >> 8), 255 & n);
  }
  function ht(n) {
    return ft(n) + '';
  }
  function gt(n, t, e) {
    return new pt(n, t, e);
  }
  function pt(n, t, e) {
    (this.r = n), (this.g = t), (this.b = e);
  }
  function vt(n) {
    return 16 > n
      ? '0' + Math.max(0, n).toString(16)
      : Math.min(255, n).toString(16);
  }
  function dt(n, t, e) {
    var r,
      u,
      i,
      o = 0,
      a = 0,
      c = 0;
    if ((r = /([a-z]+)\((.*)\)/i.exec(n)))
      switch (((u = r[2].split(',')), r[1])) {
        case 'hsl':
          return e(
            parseFloat(u[0]),
            parseFloat(u[1]) / 100,
            parseFloat(u[2]) / 100
          );
        case 'rgb':
          return t(Mt(u[0]), Mt(u[1]), Mt(u[2]));
      }
    return (i = Va.get(n))
      ? t(i.r, i.g, i.b)
      : (null != n &&
          '#' === n.charAt(0) &&
          (4 === n.length
            ? ((o = n.charAt(1)),
              (o += o),
              (a = n.charAt(2)),
              (a += a),
              (c = n.charAt(3)),
              (c += c))
            : 7 === n.length &&
              ((o = n.substring(1, 3)),
              (a = n.substring(3, 5)),
              (c = n.substring(5, 7))),
          (o = parseInt(o, 16)),
          (a = parseInt(a, 16)),
          (c = parseInt(c, 16))),
        t(o, a, c));
  }
  function mt(n, t, e) {
    var r,
      u,
      i = Math.min((n /= 255), (t /= 255), (e /= 255)),
      o = Math.max(n, t, e),
      a = o - i,
      c = (o + i) / 2;
    return (
      a
        ? ((u = 0.5 > c ? a / (o + i) : a / (2 - o - i)),
          (r =
            n == o
              ? (t - e) / a + (e > t ? 6 : 0)
              : t == o
              ? (e - n) / a + 2
              : (n - t) / a + 4),
          (r *= 60))
        : ((r = 0 / 0), (u = c > 0 && 1 > c ? 0 : r)),
      K(r, u, c)
    );
  }
  function yt(n, t, e) {
    (n = xt(n)), (t = xt(t)), (e = xt(e));
    var r = st((0.4124564 * n + 0.3575761 * t + 0.1804375 * e) / Fa),
      u = st((0.2126729 * n + 0.7151522 * t + 0.072175 * e) / Oa),
      i = st((0.0193339 * n + 0.119192 * t + 0.9503041 * e) / Ya);
    return ut(116 * u - 16, 500 * (r - u), 200 * (u - i));
  }
  function xt(n) {
    return (n /= 255) <= 0.04045
      ? n / 12.92
      : Math.pow((n + 0.055) / 1.055, 2.4);
  }
  function Mt(n) {
    var t = parseFloat(n);
    return '%' === n.charAt(n.length - 1) ? Math.round(2.55 * t) : t;
  }
  function _t(n) {
    return 'function' == typeof n
      ? n
      : function () {
          return n;
        };
  }
  function bt(n) {
    return n;
  }
  function wt(n) {
    return function (t, e, r) {
      return (
        2 === arguments.length &&
          'function' == typeof e &&
          ((r = e), (e = null)),
        St(t, e, n, r)
      );
    };
  }
  function St(n, t, e, r) {
    function u() {
      var n,
        t = c.status;
      if ((!t && c.responseText) || (t >= 200 && 300 > t) || 304 === t) {
        try {
          n = e.call(i, c);
        } catch (r) {
          return o.error.call(i, r), void 0;
        }
        o.load.call(i, n);
      } else o.error.call(i, c);
    }
    var i = {},
      o = Xo.dispatch('beforesend', 'progress', 'load', 'error'),
      a = {},
      c = new XMLHttpRequest(),
      s = null;
    return (
      !Go.XDomainRequest ||
        'withCredentials' in c ||
        !/^(http(s)?:)?\/\//.test(n) ||
        (c = new XDomainRequest()),
      'onload' in c
        ? (c.onload = c.onerror = u)
        : (c.onreadystatechange = function () {
            c.readyState > 3 && u();
          }),
      (c.onprogress = function (n) {
        var t = Xo.event;
        Xo.event = n;
        try {
          o.progress.call(i, c);
        } finally {
          Xo.event = t;
        }
      }),
      (i.header = function (n, t) {
        return (
          (n = (n + '').toLowerCase()),
          arguments.length < 2
            ? a[n]
            : (null == t ? delete a[n] : (a[n] = t + ''), i)
        );
      }),
      (i.mimeType = function (n) {
        return arguments.length ? ((t = null == n ? null : n + ''), i) : t;
      }),
      (i.responseType = function (n) {
        return arguments.length ? ((s = n), i) : s;
      }),
      (i.response = function (n) {
        return (e = n), i;
      }),
      ['get', 'post'].forEach(function (n) {
        i[n] = function () {
          return i.send.apply(i, [n].concat(Bo(arguments)));
        };
      }),
      (i.send = function (e, r, u) {
        if (
          (2 === arguments.length &&
            'function' == typeof r &&
            ((u = r), (r = null)),
          c.open(e, n, !0),
          null == t || 'accept' in a || (a.accept = t + ',*/*'),
          c.setRequestHeader)
        )
          for (var l in a) c.setRequestHeader(l, a[l]);
        return (
          null != t && c.overrideMimeType && c.overrideMimeType(t),
          null != s && (c.responseType = s),
          null != u &&
            i.on('error', u).on('load', function (n) {
              u(null, n);
            }),
          o.beforesend.call(i, c),
          c.send(null == r ? null : r),
          i
        );
      }),
      (i.abort = function () {
        return c.abort(), i;
      }),
      Xo.rebind(i, o, 'on'),
      null == r ? i : i.get(kt(r))
    );
  }
  function kt(n) {
    return 1 === n.length
      ? function (t, e) {
          n(null == t ? e : null);
        }
      : n;
  }
  function Et() {
    var n = At(),
      t = Ct() - n;
    t > 24
      ? (isFinite(t) && (clearTimeout(Wa), (Wa = setTimeout(Et, t))), (Ba = 0))
      : ((Ba = 1), Ga(Et));
  }
  function At() {
    var n = Date.now();
    for (Ja = Xa; Ja; ) n >= Ja.t && (Ja.f = Ja.c(n - Ja.t)), (Ja = Ja.n);
    return n;
  }
  function Ct() {
    for (var n, t = Xa, e = 1 / 0; t; )
      t.f
        ? (t = n ? (n.n = t.n) : (Xa = t.n))
        : (t.t < e && (e = t.t), (t = (n = t).n));
    return ($a = n), e;
  }
  function Nt(n, t) {
    return t - (n ? Math.ceil(Math.log(n) / Math.LN10) : 1);
  }
  function Lt(n, t) {
    var e = Math.pow(10, 3 * oa(8 - t));
    return {
      scale:
        t > 8
          ? function (n) {
              return n / e;
            }
          : function (n) {
              return n * e;
            },
      symbol: n,
    };
  }
  function zt(n) {
    var t = n.decimal,
      e = n.thousands,
      r = n.grouping,
      u = n.currency,
      i = r
        ? function (n) {
            for (var t = n.length, u = [], i = 0, o = r[0]; t > 0 && o > 0; )
              u.push(n.substring((t -= o), t + o)),
                (o = r[(i = (i + 1) % r.length)]);
            return u.reverse().join(e);
          }
        : bt;
    return function (n) {
      var e = Qa.exec(n),
        r = e[1] || ' ',
        o = e[2] || '>',
        a = e[3] || '',
        c = e[4] || '',
        s = e[5],
        l = +e[6],
        f = e[7],
        h = e[8],
        g = e[9],
        p = 1,
        v = '',
        d = '',
        m = !1;
      switch (
        (h && (h = +h.substring(1)),
        (s || ('0' === r && '=' === o)) &&
          ((s = r = '0'), (o = '='), f && (l -= Math.floor((l - 1) / 4))),
        g)
      ) {
        case 'n':
          (f = !0), (g = 'g');
          break;
        case '%':
          (p = 100), (d = '%'), (g = 'f');
          break;
        case 'p':
          (p = 100), (d = '%'), (g = 'r');
          break;
        case 'b':
        case 'o':
        case 'x':
        case 'X':
          '#' === c && (v = '0' + g.toLowerCase());
        case 'c':
        case 'd':
          (m = !0), (h = 0);
          break;
        case 's':
          (p = -1), (g = 'r');
      }
      '$' === c && ((v = u[0]), (d = u[1])),
        'r' != g || h || (g = 'g'),
        null != h &&
          ('g' == g
            ? (h = Math.max(1, Math.min(21, h)))
            : ('e' == g || 'f' == g) && (h = Math.max(0, Math.min(20, h)))),
        (g = nc.get(g) || qt);
      var y = s && f;
      return function (n) {
        if (m && n % 1) return '';
        var e = 0 > n || (0 === n && 0 > 1 / n) ? ((n = -n), '-') : a;
        if (0 > p) {
          var u = Xo.formatPrefix(n, h);
          (n = u.scale(n)), (d = u.symbol);
        } else n *= p;
        n = g(n, h);
        var c = n.lastIndexOf('.'),
          x = 0 > c ? n : n.substring(0, c),
          M = 0 > c ? '' : t + n.substring(c + 1);
        !s && f && (x = i(x));
        var _ = v.length + x.length + M.length + (y ? 0 : e.length),
          b = l > _ ? new Array((_ = l - _ + 1)).join(r) : '';
        return (
          y && (x = i(b + x)),
          (e += v),
          (n = x + M),
          ('<' === o
            ? e + n + b
            : '>' === o
            ? b + e + n
            : '^' === o
            ? b.substring(0, (_ >>= 1)) + e + n + b.substring(_)
            : e + (y ? n : b + n)) + d
        );
      };
    };
  }
  function qt(n) {
    return n + '';
  }
  function Tt() {
    this._ = new Date(
      arguments.length > 1 ? Date.UTC.apply(this, arguments) : arguments[0]
    );
  }
  function Rt(n, t, e) {
    function r(t) {
      var e = n(t),
        r = i(e, 1);
      return r - t > t - e ? e : r;
    }
    function u(e) {
      return t((e = n(new ec(e - 1))), 1), e;
    }
    function i(n, e) {
      return t((n = new ec(+n)), e), n;
    }
    function o(n, r, i) {
      var o = u(n),
        a = [];
      if (i > 1) for (; r > o; ) e(o) % i || a.push(new Date(+o)), t(o, 1);
      else for (; r > o; ) a.push(new Date(+o)), t(o, 1);
      return a;
    }
    function a(n, t, e) {
      try {
        ec = Tt;
        var r = new Tt();
        return (r._ = n), o(r, t, e);
      } finally {
        ec = Date;
      }
    }
    (n.floor = n), (n.round = r), (n.ceil = u), (n.offset = i), (n.range = o);
    var c = (n.utc = Dt(n));
    return (
      (c.floor = c),
      (c.round = Dt(r)),
      (c.ceil = Dt(u)),
      (c.offset = Dt(i)),
      (c.range = a),
      n
    );
  }
  function Dt(n) {
    return function (t, e) {
      try {
        ec = Tt;
        var r = new Tt();
        return (r._ = t), n(r, e)._;
      } finally {
        ec = Date;
      }
    };
  }
  function Pt(n) {
    function t(n) {
      function t(t) {
        for (var e, u, i, o = [], a = -1, c = 0; ++a < r; )
          37 === n.charCodeAt(a) &&
            (o.push(n.substring(c, a)),
            null != (u = uc[(e = n.charAt(++a))]) && (e = n.charAt(++a)),
            (i = C[e]) && (e = i(t, null == u ? ('e' === e ? ' ' : '0') : u)),
            o.push(e),
            (c = a + 1));
        return o.push(n.substring(c, a)), o.join('');
      }
      var r = n.length;
      return (
        (t.parse = function (t) {
          var r = { y: 1900, m: 0, d: 1, H: 0, M: 0, S: 0, L: 0, Z: null },
            u = e(r, n, t, 0);
          if (u != t.length) return null;
          'p' in r && (r.H = (r.H % 12) + 12 * r.p);
          var i = null != r.Z && ec !== Tt,
            o = new (i ? Tt : ec)();
          return (
            'j' in r
              ? o.setFullYear(r.y, 0, r.j)
              : 'w' in r && ('W' in r || 'U' in r)
              ? (o.setFullYear(r.y, 0, 1),
                o.setFullYear(
                  r.y,
                  0,
                  'W' in r
                    ? ((r.w + 6) % 7) + 7 * r.W - ((o.getDay() + 5) % 7)
                    : r.w + 7 * r.U - ((o.getDay() + 6) % 7)
                ))
              : o.setFullYear(r.y, r.m, r.d),
            o.setHours(
              r.H + Math.floor(r.Z / 100),
              r.M + (r.Z % 100),
              r.S,
              r.L
            ),
            i ? o._ : o
          );
        }),
        (t.toString = function () {
          return n;
        }),
        t
      );
    }
    function e(n, t, e, r) {
      for (var u, i, o, a = 0, c = t.length, s = e.length; c > a; ) {
        if (r >= s) return -1;
        if (((u = t.charCodeAt(a++)), 37 === u)) {
          if (
            ((o = t.charAt(a++)),
            (i = N[o in uc ? t.charAt(a++) : o]),
            !i || (r = i(n, e, r)) < 0)
          )
            return -1;
        } else if (u != e.charCodeAt(r++)) return -1;
      }
      return r;
    }
    function r(n, t, e) {
      b.lastIndex = 0;
      var r = b.exec(t.substring(e));
      return r ? ((n.w = w.get(r[0].toLowerCase())), e + r[0].length) : -1;
    }
    function u(n, t, e) {
      M.lastIndex = 0;
      var r = M.exec(t.substring(e));
      return r ? ((n.w = _.get(r[0].toLowerCase())), e + r[0].length) : -1;
    }
    function i(n, t, e) {
      E.lastIndex = 0;
      var r = E.exec(t.substring(e));
      return r ? ((n.m = A.get(r[0].toLowerCase())), e + r[0].length) : -1;
    }
    function o(n, t, e) {
      S.lastIndex = 0;
      var r = S.exec(t.substring(e));
      return r ? ((n.m = k.get(r[0].toLowerCase())), e + r[0].length) : -1;
    }
    function a(n, t, r) {
      return e(n, C.c.toString(), t, r);
    }
    function c(n, t, r) {
      return e(n, C.x.toString(), t, r);
    }
    function s(n, t, r) {
      return e(n, C.X.toString(), t, r);
    }
    function l(n, t, e) {
      var r = x.get(t.substring(e, (e += 2)).toLowerCase());
      return null == r ? -1 : ((n.p = r), e);
    }
    var f = n.dateTime,
      h = n.date,
      g = n.time,
      p = n.periods,
      v = n.days,
      d = n.shortDays,
      m = n.months,
      y = n.shortMonths;
    (t.utc = function (n) {
      function e(n) {
        try {
          ec = Tt;
          var t = new ec();
          return (t._ = n), r(t);
        } finally {
          ec = Date;
        }
      }
      var r = t(n);
      return (
        (e.parse = function (n) {
          try {
            ec = Tt;
            var t = r.parse(n);
            return t && t._;
          } finally {
            ec = Date;
          }
        }),
        (e.toString = r.toString),
        e
      );
    }),
      (t.multi = t.utc.multi = ee);
    var x = Xo.map(),
      M = jt(v),
      _ = Ht(v),
      b = jt(d),
      w = Ht(d),
      S = jt(m),
      k = Ht(m),
      E = jt(y),
      A = Ht(y);
    p.forEach(function (n, t) {
      x.set(n.toLowerCase(), t);
    });
    var C = {
        a: function (n) {
          return d[n.getDay()];
        },
        A: function (n) {
          return v[n.getDay()];
        },
        b: function (n) {
          return y[n.getMonth()];
        },
        B: function (n) {
          return m[n.getMonth()];
        },
        c: t(f),
        d: function (n, t) {
          return Ut(n.getDate(), t, 2);
        },
        e: function (n, t) {
          return Ut(n.getDate(), t, 2);
        },
        H: function (n, t) {
          return Ut(n.getHours(), t, 2);
        },
        I: function (n, t) {
          return Ut(n.getHours() % 12 || 12, t, 2);
        },
        j: function (n, t) {
          return Ut(1 + tc.dayOfYear(n), t, 3);
        },
        L: function (n, t) {
          return Ut(n.getMilliseconds(), t, 3);
        },
        m: function (n, t) {
          return Ut(n.getMonth() + 1, t, 2);
        },
        M: function (n, t) {
          return Ut(n.getMinutes(), t, 2);
        },
        p: function (n) {
          return p[+(n.getHours() >= 12)];
        },
        S: function (n, t) {
          return Ut(n.getSeconds(), t, 2);
        },
        U: function (n, t) {
          return Ut(tc.sundayOfYear(n), t, 2);
        },
        w: function (n) {
          return n.getDay();
        },
        W: function (n, t) {
          return Ut(tc.mondayOfYear(n), t, 2);
        },
        x: t(h),
        X: t(g),
        y: function (n, t) {
          return Ut(n.getFullYear() % 100, t, 2);
        },
        Y: function (n, t) {
          return Ut(n.getFullYear() % 1e4, t, 4);
        },
        Z: ne,
        '%': function () {
          return '%';
        },
      },
      N = {
        a: r,
        A: u,
        b: i,
        B: o,
        c: a,
        d: Bt,
        e: Bt,
        H: Jt,
        I: Jt,
        j: Wt,
        L: Qt,
        m: $t,
        M: Gt,
        p: l,
        S: Kt,
        U: Ot,
        w: Ft,
        W: Yt,
        x: c,
        X: s,
        y: Zt,
        Y: It,
        Z: Vt,
        '%': te,
      };
    return t;
  }
  function Ut(n, t, e) {
    var r = 0 > n ? '-' : '',
      u = (r ? -n : n) + '',
      i = u.length;
    return r + (e > i ? new Array(e - i + 1).join(t) + u : u);
  }
  function jt(n) {
    return new RegExp('^(?:' + n.map(Xo.requote).join('|') + ')', 'i');
  }
  function Ht(n) {
    for (var t = new u(), e = -1, r = n.length; ++e < r; )
      t.set(n[e].toLowerCase(), e);
    return t;
  }
  function Ft(n, t, e) {
    ic.lastIndex = 0;
    var r = ic.exec(t.substring(e, e + 1));
    return r ? ((n.w = +r[0]), e + r[0].length) : -1;
  }
  function Ot(n, t, e) {
    ic.lastIndex = 0;
    var r = ic.exec(t.substring(e));
    return r ? ((n.U = +r[0]), e + r[0].length) : -1;
  }
  function Yt(n, t, e) {
    ic.lastIndex = 0;
    var r = ic.exec(t.substring(e));
    return r ? ((n.W = +r[0]), e + r[0].length) : -1;
  }
  function It(n, t, e) {
    ic.lastIndex = 0;
    var r = ic.exec(t.substring(e, e + 4));
    return r ? ((n.y = +r[0]), e + r[0].length) : -1;
  }
  function Zt(n, t, e) {
    ic.lastIndex = 0;
    var r = ic.exec(t.substring(e, e + 2));
    return r ? ((n.y = Xt(+r[0])), e + r[0].length) : -1;
  }
  function Vt(n, t, e) {
    return /^[+-]\d{4}$/.test((t = t.substring(e, e + 5)))
      ? ((n.Z = +t), e + 5)
      : -1;
  }
  function Xt(n) {
    return n + (n > 68 ? 1900 : 2e3);
  }
  function $t(n, t, e) {
    ic.lastIndex = 0;
    var r = ic.exec(t.substring(e, e + 2));
    return r ? ((n.m = r[0] - 1), e + r[0].length) : -1;
  }
  function Bt(n, t, e) {
    ic.lastIndex = 0;
    var r = ic.exec(t.substring(e, e + 2));
    return r ? ((n.d = +r[0]), e + r[0].length) : -1;
  }
  function Wt(n, t, e) {
    ic.lastIndex = 0;
    var r = ic.exec(t.substring(e, e + 3));
    return r ? ((n.j = +r[0]), e + r[0].length) : -1;
  }
  function Jt(n, t, e) {
    ic.lastIndex = 0;
    var r = ic.exec(t.substring(e, e + 2));
    return r ? ((n.H = +r[0]), e + r[0].length) : -1;
  }
  function Gt(n, t, e) {
    ic.lastIndex = 0;
    var r = ic.exec(t.substring(e, e + 2));
    return r ? ((n.M = +r[0]), e + r[0].length) : -1;
  }
  function Kt(n, t, e) {
    ic.lastIndex = 0;
    var r = ic.exec(t.substring(e, e + 2));
    return r ? ((n.S = +r[0]), e + r[0].length) : -1;
  }
  function Qt(n, t, e) {
    ic.lastIndex = 0;
    var r = ic.exec(t.substring(e, e + 3));
    return r ? ((n.L = +r[0]), e + r[0].length) : -1;
  }
  function ne(n) {
    var t = n.getTimezoneOffset(),
      e = t > 0 ? '-' : '+',
      r = ~~(oa(t) / 60),
      u = oa(t) % 60;
    return e + Ut(r, '0', 2) + Ut(u, '0', 2);
  }
  function te(n, t, e) {
    oc.lastIndex = 0;
    var r = oc.exec(t.substring(e, e + 1));
    return r ? e + r[0].length : -1;
  }
  function ee(n) {
    for (var t = n.length, e = -1; ++e < t; ) n[e][0] = this(n[e][0]);
    return function (t) {
      for (var e = 0, r = n[e]; !r[1](t); ) r = n[++e];
      return r[0](t);
    };
  }
  function re() {}
  function ue(n, t, e) {
    var r = (e.s = n + t),
      u = r - n,
      i = r - u;
    e.t = n - i + (t - u);
  }
  function ie(n, t) {
    n && lc.hasOwnProperty(n.type) && lc[n.type](n, t);
  }
  function oe(n, t, e) {
    var r,
      u = -1,
      i = n.length - e;
    for (t.lineStart(); ++u < i; ) (r = n[u]), t.point(r[0], r[1], r[2]);
    t.lineEnd();
  }
  function ae(n, t) {
    var e = -1,
      r = n.length;
    for (t.polygonStart(); ++e < r; ) oe(n[e], t, 1);
    t.polygonEnd();
  }
  function ce() {
    function n(n, t) {
      (n *= Na), (t = (t * Na) / 2 + Sa / 4);
      var e = n - r,
        o = Math.cos(t),
        a = Math.sin(t),
        c = i * a,
        s = u * o + c * Math.cos(e),
        l = c * Math.sin(e);
      hc.add(Math.atan2(l, s)), (r = n), (u = o), (i = a);
    }
    var t, e, r, u, i;
    (gc.point = function (o, a) {
      (gc.point = n),
        (r = (t = o) * Na),
        (u = Math.cos((a = ((e = a) * Na) / 2 + Sa / 4))),
        (i = Math.sin(a));
    }),
      (gc.lineEnd = function () {
        n(t, e);
      });
  }
  function se(n) {
    var t = n[0],
      e = n[1],
      r = Math.cos(e);
    return [r * Math.cos(t), r * Math.sin(t), Math.sin(e)];
  }
  function le(n, t) {
    return n[0] * t[0] + n[1] * t[1] + n[2] * t[2];
  }
  function fe(n, t) {
    return [
      n[1] * t[2] - n[2] * t[1],
      n[2] * t[0] - n[0] * t[2],
      n[0] * t[1] - n[1] * t[0],
    ];
  }
  function he(n, t) {
    (n[0] += t[0]), (n[1] += t[1]), (n[2] += t[2]);
  }
  function ge(n, t) {
    return [n[0] * t, n[1] * t, n[2] * t];
  }
  function pe(n) {
    var t = Math.sqrt(n[0] * n[0] + n[1] * n[1] + n[2] * n[2]);
    (n[0] /= t), (n[1] /= t), (n[2] /= t);
  }
  function ve(n) {
    return [Math.atan2(n[1], n[0]), X(n[2])];
  }
  function de(n, t) {
    return oa(n[0] - t[0]) < Aa && oa(n[1] - t[1]) < Aa;
  }
  function me(n, t) {
    n *= Na;
    var e = Math.cos((t *= Na));
    ye(e * Math.cos(n), e * Math.sin(n), Math.sin(t));
  }
  function ye(n, t, e) {
    ++pc, (dc += (n - dc) / pc), (mc += (t - mc) / pc), (yc += (e - yc) / pc);
  }
  function xe() {
    function n(n, u) {
      n *= Na;
      var i = Math.cos((u *= Na)),
        o = i * Math.cos(n),
        a = i * Math.sin(n),
        c = Math.sin(u),
        s = Math.atan2(
          Math.sqrt(
            (s = e * c - r * a) * s +
              (s = r * o - t * c) * s +
              (s = t * a - e * o) * s
          ),
          t * o + e * a + r * c
        );
      (vc += s),
        (xc += s * (t + (t = o))),
        (Mc += s * (e + (e = a))),
        (_c += s * (r + (r = c))),
        ye(t, e, r);
    }
    var t, e, r;
    kc.point = function (u, i) {
      u *= Na;
      var o = Math.cos((i *= Na));
      (t = o * Math.cos(u)),
        (e = o * Math.sin(u)),
        (r = Math.sin(i)),
        (kc.point = n),
        ye(t, e, r);
    };
  }
  function Me() {
    kc.point = me;
  }
  function _e() {
    function n(n, t) {
      n *= Na;
      var e = Math.cos((t *= Na)),
        o = e * Math.cos(n),
        a = e * Math.sin(n),
        c = Math.sin(t),
        s = u * c - i * a,
        l = i * o - r * c,
        f = r * a - u * o,
        h = Math.sqrt(s * s + l * l + f * f),
        g = r * o + u * a + i * c,
        p = h && -V(g) / h,
        v = Math.atan2(h, g);
      (bc += p * s),
        (wc += p * l),
        (Sc += p * f),
        (vc += v),
        (xc += v * (r + (r = o))),
        (Mc += v * (u + (u = a))),
        (_c += v * (i + (i = c))),
        ye(r, u, i);
    }
    var t, e, r, u, i;
    (kc.point = function (o, a) {
      (t = o), (e = a), (kc.point = n), (o *= Na);
      var c = Math.cos((a *= Na));
      (r = c * Math.cos(o)),
        (u = c * Math.sin(o)),
        (i = Math.sin(a)),
        ye(r, u, i);
    }),
      (kc.lineEnd = function () {
        n(t, e), (kc.lineEnd = Me), (kc.point = me);
      });
  }
  function be() {
    return !0;
  }
  function we(n, t, e, r, u) {
    var i = [],
      o = [];
    if (
      (n.forEach(function (n) {
        if (!((t = n.length - 1) <= 0)) {
          var t,
            e = n[0],
            r = n[t];
          if (de(e, r)) {
            u.lineStart();
            for (var a = 0; t > a; ++a) u.point((e = n[a])[0], e[1]);
            return u.lineEnd(), void 0;
          }
          var c = new ke(e, n, null, !0),
            s = new ke(e, null, c, !1);
          (c.o = s),
            i.push(c),
            o.push(s),
            (c = new ke(r, n, null, !1)),
            (s = new ke(r, null, c, !0)),
            (c.o = s),
            i.push(c),
            o.push(s);
        }
      }),
      o.sort(t),
      Se(i),
      Se(o),
      i.length)
    ) {
      for (var a = 0, c = e, s = o.length; s > a; ++a) o[a].e = c = !c;
      for (var l, f, h = i[0]; ; ) {
        for (var g = h, p = !0; g.v; ) if ((g = g.n) === h) return;
        (l = g.z), u.lineStart();
        do {
          if (((g.v = g.o.v = !0), g.e)) {
            if (p)
              for (var a = 0, s = l.length; s > a; ++a)
                u.point((f = l[a])[0], f[1]);
            else r(g.x, g.n.x, 1, u);
            g = g.n;
          } else {
            if (p) {
              l = g.p.z;
              for (var a = l.length - 1; a >= 0; --a)
                u.point((f = l[a])[0], f[1]);
            } else r(g.x, g.p.x, -1, u);
            g = g.p;
          }
          (g = g.o), (l = g.z), (p = !p);
        } while (!g.v);
        u.lineEnd();
      }
    }
  }
  function Se(n) {
    if ((t = n.length)) {
      for (var t, e, r = 0, u = n[0]; ++r < t; )
        (u.n = e = n[r]), (e.p = u), (u = e);
      (u.n = e = n[0]), (e.p = u);
    }
  }
  function ke(n, t, e, r) {
    (this.x = n),
      (this.z = t),
      (this.o = e),
      (this.e = r),
      (this.v = !1),
      (this.n = this.p = null);
  }
  function Ee(n, t, e, r) {
    return function (u, i) {
      function o(t, e) {
        var r = u(t, e);
        n((t = r[0]), (e = r[1])) && i.point(t, e);
      }
      function a(n, t) {
        var e = u(n, t);
        d.point(e[0], e[1]);
      }
      function c() {
        (y.point = a), d.lineStart();
      }
      function s() {
        (y.point = o), d.lineEnd();
      }
      function l(n, t) {
        v.push([n, t]);
        var e = u(n, t);
        M.point(e[0], e[1]);
      }
      function f() {
        M.lineStart(), (v = []);
      }
      function h() {
        l(v[0][0], v[0][1]), M.lineEnd();
        var n,
          t = M.clean(),
          e = x.buffer(),
          r = e.length;
        if ((v.pop(), p.push(v), (v = null), r)) {
          if (1 & t) {
            n = e[0];
            var u,
              r = n.length - 1,
              o = -1;
            for (i.lineStart(); ++o < r; ) i.point((u = n[o])[0], u[1]);
            return i.lineEnd(), void 0;
          }
          r > 1 && 2 & t && e.push(e.pop().concat(e.shift())),
            g.push(e.filter(Ae));
        }
      }
      var g,
        p,
        v,
        d = t(i),
        m = u.invert(r[0], r[1]),
        y = {
          point: o,
          lineStart: c,
          lineEnd: s,
          polygonStart: function () {
            (y.point = l),
              (y.lineStart = f),
              (y.lineEnd = h),
              (g = []),
              (p = []),
              i.polygonStart();
          },
          polygonEnd: function () {
            (y.point = o),
              (y.lineStart = c),
              (y.lineEnd = s),
              (g = Xo.merge(g));
            var n = Le(m, p);
            g.length
              ? we(g, Ne, n, e, i)
              : n && (i.lineStart(), e(null, null, 1, i), i.lineEnd()),
              i.polygonEnd(),
              (g = p = null);
          },
          sphere: function () {
            i.polygonStart(),
              i.lineStart(),
              e(null, null, 1, i),
              i.lineEnd(),
              i.polygonEnd();
          },
        },
        x = Ce(),
        M = t(x);
      return y;
    };
  }
  function Ae(n) {
    return n.length > 1;
  }
  function Ce() {
    var n,
      t = [];
    return {
      lineStart: function () {
        t.push((n = []));
      },
      point: function (t, e) {
        n.push([t, e]);
      },
      lineEnd: g,
      buffer: function () {
        var e = t;
        return (t = []), (n = null), e;
      },
      rejoin: function () {
        t.length > 1 && t.push(t.pop().concat(t.shift()));
      },
    };
  }
  function Ne(n, t) {
    return (
      ((n = n.x)[0] < 0 ? n[1] - Ea - Aa : Ea - n[1]) -
      ((t = t.x)[0] < 0 ? t[1] - Ea - Aa : Ea - t[1])
    );
  }
  function Le(n, t) {
    var e = n[0],
      r = n[1],
      u = [Math.sin(e), -Math.cos(e), 0],
      i = 0,
      o = 0;
    hc.reset();
    for (var a = 0, c = t.length; c > a; ++a) {
      var s = t[a],
        l = s.length;
      if (l)
        for (
          var f = s[0],
            h = f[0],
            g = f[1] / 2 + Sa / 4,
            p = Math.sin(g),
            v = Math.cos(g),
            d = 1;
          ;

        ) {
          d === l && (d = 0), (n = s[d]);
          var m = n[0],
            y = n[1] / 2 + Sa / 4,
            x = Math.sin(y),
            M = Math.cos(y),
            _ = m - h,
            b = oa(_) > Sa,
            w = p * x;
          if (
            (hc.add(Math.atan2(w * Math.sin(_), v * M + w * Math.cos(_))),
            (i += b ? _ + (_ >= 0 ? ka : -ka) : _),
            b ^ (h >= e) ^ (m >= e))
          ) {
            var S = fe(se(f), se(n));
            pe(S);
            var k = fe(u, S);
            pe(k);
            var E = (b ^ (_ >= 0) ? -1 : 1) * X(k[2]);
            (r > E || (r === E && (S[0] || S[1]))) &&
              (o += b ^ (_ >= 0) ? 1 : -1);
          }
          if (!d++) break;
          (h = m), (p = x), (v = M), (f = n);
        }
    }
    return (-Aa > i || (Aa > i && 0 > hc)) ^ (1 & o);
  }
  function ze(n) {
    var t,
      e = 0 / 0,
      r = 0 / 0,
      u = 0 / 0;
    return {
      lineStart: function () {
        n.lineStart(), (t = 1);
      },
      point: function (i, o) {
        var a = i > 0 ? Sa : -Sa,
          c = oa(i - e);
        oa(c - Sa) < Aa
          ? (n.point(e, (r = (r + o) / 2 > 0 ? Ea : -Ea)),
            n.point(u, r),
            n.lineEnd(),
            n.lineStart(),
            n.point(a, r),
            n.point(i, r),
            (t = 0))
          : u !== a &&
            c >= Sa &&
            (oa(e - u) < Aa && (e -= u * Aa),
            oa(i - a) < Aa && (i -= a * Aa),
            (r = qe(e, r, i, o)),
            n.point(u, r),
            n.lineEnd(),
            n.lineStart(),
            n.point(a, r),
            (t = 0)),
          n.point((e = i), (r = o)),
          (u = a);
      },
      lineEnd: function () {
        n.lineEnd(), (e = r = 0 / 0);
      },
      clean: function () {
        return 2 - t;
      },
    };
  }
  function qe(n, t, e, r) {
    var u,
      i,
      o = Math.sin(n - e);
    return oa(o) > Aa
      ? Math.atan(
          (Math.sin(t) * (i = Math.cos(r)) * Math.sin(e) -
            Math.sin(r) * (u = Math.cos(t)) * Math.sin(n)) /
            (u * i * o)
        )
      : (t + r) / 2;
  }
  function Te(n, t, e, r) {
    var u;
    if (null == n)
      (u = e * Ea),
        r.point(-Sa, u),
        r.point(0, u),
        r.point(Sa, u),
        r.point(Sa, 0),
        r.point(Sa, -u),
        r.point(0, -u),
        r.point(-Sa, -u),
        r.point(-Sa, 0),
        r.point(-Sa, u);
    else if (oa(n[0] - t[0]) > Aa) {
      var i = n[0] < t[0] ? Sa : -Sa;
      (u = (e * i) / 2), r.point(-i, u), r.point(0, u), r.point(i, u);
    } else r.point(t[0], t[1]);
  }
  function Re(n) {
    function t(n, t) {
      return Math.cos(n) * Math.cos(t) > i;
    }
    function e(n) {
      var e, i, c, s, l;
      return {
        lineStart: function () {
          (s = c = !1), (l = 1);
        },
        point: function (f, h) {
          var g,
            p = [f, h],
            v = t(f, h),
            d = o ? (v ? 0 : u(f, h)) : v ? u(f + (0 > f ? Sa : -Sa), h) : 0;
          if (
            (!e && (s = c = v) && n.lineStart(),
            v !== c &&
              ((g = r(e, p)),
              (de(e, g) || de(p, g)) &&
                ((p[0] += Aa), (p[1] += Aa), (v = t(p[0], p[1])))),
            v !== c)
          )
            (l = 0),
              v
                ? (n.lineStart(), (g = r(p, e)), n.point(g[0], g[1]))
                : ((g = r(e, p)), n.point(g[0], g[1]), n.lineEnd()),
              (e = g);
          else if (a && e && o ^ v) {
            var m;
            d & i ||
              !(m = r(p, e, !0)) ||
              ((l = 0),
              o
                ? (n.lineStart(),
                  n.point(m[0][0], m[0][1]),
                  n.point(m[1][0], m[1][1]),
                  n.lineEnd())
                : (n.point(m[1][0], m[1][1]),
                  n.lineEnd(),
                  n.lineStart(),
                  n.point(m[0][0], m[0][1])));
          }
          !v || (e && de(e, p)) || n.point(p[0], p[1]),
            (e = p),
            (c = v),
            (i = d);
        },
        lineEnd: function () {
          c && n.lineEnd(), (e = null);
        },
        clean: function () {
          return l | ((s && c) << 1);
        },
      };
    }
    function r(n, t, e) {
      var r = se(n),
        u = se(t),
        o = [1, 0, 0],
        a = fe(r, u),
        c = le(a, a),
        s = a[0],
        l = c - s * s;
      if (!l) return !e && n;
      var f = (i * c) / l,
        h = (-i * s) / l,
        g = fe(o, a),
        p = ge(o, f),
        v = ge(a, h);
      he(p, v);
      var d = g,
        m = le(p, d),
        y = le(d, d),
        x = m * m - y * (le(p, p) - 1);
      if (!(0 > x)) {
        var M = Math.sqrt(x),
          _ = ge(d, (-m - M) / y);
        if ((he(_, p), (_ = ve(_)), !e)) return _;
        var b,
          w = n[0],
          S = t[0],
          k = n[1],
          E = t[1];
        w > S && ((b = w), (w = S), (S = b));
        var A = S - w,
          C = oa(A - Sa) < Aa,
          N = C || Aa > A;
        if (
          (!C && k > E && ((b = k), (k = E), (E = b)),
          N
            ? C
              ? (k + E > 0) ^ (_[1] < (oa(_[0] - w) < Aa ? k : E))
              : k <= _[1] && _[1] <= E
            : (A > Sa) ^ (w <= _[0] && _[0] <= S))
        ) {
          var L = ge(d, (-m + M) / y);
          return he(L, p), [_, ve(L)];
        }
      }
    }
    function u(t, e) {
      var r = o ? n : Sa - n,
        u = 0;
      return (
        -r > t ? (u |= 1) : t > r && (u |= 2),
        -r > e ? (u |= 4) : e > r && (u |= 8),
        u
      );
    }
    var i = Math.cos(n),
      o = i > 0,
      a = oa(i) > Aa,
      c = cr(n, 6 * Na);
    return Ee(t, e, c, o ? [0, -n] : [-Sa, n - Sa]);
  }
  function De(n, t, e, r) {
    return function (u) {
      var i,
        o = u.a,
        a = u.b,
        c = o.x,
        s = o.y,
        l = a.x,
        f = a.y,
        h = 0,
        g = 1,
        p = l - c,
        v = f - s;
      if (((i = n - c), p || !(i > 0))) {
        if (((i /= p), 0 > p)) {
          if (h > i) return;
          g > i && (g = i);
        } else if (p > 0) {
          if (i > g) return;
          i > h && (h = i);
        }
        if (((i = e - c), p || !(0 > i))) {
          if (((i /= p), 0 > p)) {
            if (i > g) return;
            i > h && (h = i);
          } else if (p > 0) {
            if (h > i) return;
            g > i && (g = i);
          }
          if (((i = t - s), v || !(i > 0))) {
            if (((i /= v), 0 > v)) {
              if (h > i) return;
              g > i && (g = i);
            } else if (v > 0) {
              if (i > g) return;
              i > h && (h = i);
            }
            if (((i = r - s), v || !(0 > i))) {
              if (((i /= v), 0 > v)) {
                if (i > g) return;
                i > h && (h = i);
              } else if (v > 0) {
                if (h > i) return;
                g > i && (g = i);
              }
              return (
                h > 0 && (u.a = { x: c + h * p, y: s + h * v }),
                1 > g && (u.b = { x: c + g * p, y: s + g * v }),
                u
              );
            }
          }
        }
      }
    };
  }
  function Pe(n, t, e, r) {
    function u(r, u) {
      return oa(r[0] - n) < Aa
        ? u > 0
          ? 0
          : 3
        : oa(r[0] - e) < Aa
        ? u > 0
          ? 2
          : 1
        : oa(r[1] - t) < Aa
        ? u > 0
          ? 1
          : 0
        : u > 0
        ? 3
        : 2;
    }
    function i(n, t) {
      return o(n.x, t.x);
    }
    function o(n, t) {
      var e = u(n, 1),
        r = u(t, 1);
      return e !== r
        ? e - r
        : 0 === e
        ? t[1] - n[1]
        : 1 === e
        ? n[0] - t[0]
        : 2 === e
        ? n[1] - t[1]
        : t[0] - n[0];
    }
    return function (a) {
      function c(n) {
        for (var t = 0, e = d.length, r = n[1], u = 0; e > u; ++u)
          for (var i, o = 1, a = d[u], c = a.length, s = a[0]; c > o; ++o)
            (i = a[o]),
              s[1] <= r
                ? i[1] > r && Z(s, i, n) > 0 && ++t
                : i[1] <= r && Z(s, i, n) < 0 && --t,
              (s = i);
        return 0 !== t;
      }
      function s(i, a, c, s) {
        var l = 0,
          f = 0;
        if (
          null == i ||
          (l = u(i, c)) !== (f = u(a, c)) ||
          (o(i, a) < 0) ^ (c > 0)
        ) {
          do s.point(0 === l || 3 === l ? n : e, l > 1 ? r : t);
          while ((l = (l + c + 4) % 4) !== f);
        } else s.point(a[0], a[1]);
      }
      function l(u, i) {
        return u >= n && e >= u && i >= t && r >= i;
      }
      function f(n, t) {
        l(n, t) && a.point(n, t);
      }
      function h() {
        (N.point = p),
          d && d.push((m = [])),
          (S = !0),
          (w = !1),
          (_ = b = 0 / 0);
      }
      function g() {
        v && (p(y, x), M && w && A.rejoin(), v.push(A.buffer())),
          (N.point = f),
          w && a.lineEnd();
      }
      function p(n, t) {
        (n = Math.max(-Ac, Math.min(Ac, n))),
          (t = Math.max(-Ac, Math.min(Ac, t)));
        var e = l(n, t);
        if ((d && m.push([n, t]), S))
          (y = n),
            (x = t),
            (M = e),
            (S = !1),
            e && (a.lineStart(), a.point(n, t));
        else if (e && w) a.point(n, t);
        else {
          var r = { a: { x: _, y: b }, b: { x: n, y: t } };
          C(r)
            ? (w || (a.lineStart(), a.point(r.a.x, r.a.y)),
              a.point(r.b.x, r.b.y),
              e || a.lineEnd(),
              (k = !1))
            : e && (a.lineStart(), a.point(n, t), (k = !1));
        }
        (_ = n), (b = t), (w = e);
      }
      var v,
        d,
        m,
        y,
        x,
        M,
        _,
        b,
        w,
        S,
        k,
        E = a,
        A = Ce(),
        C = De(n, t, e, r),
        N = {
          point: f,
          lineStart: h,
          lineEnd: g,
          polygonStart: function () {
            (a = A), (v = []), (d = []), (k = !0);
          },
          polygonEnd: function () {
            (a = E), (v = Xo.merge(v));
            var t = c([n, r]),
              e = k && t,
              u = v.length;
            (e || u) &&
              (a.polygonStart(),
              e && (a.lineStart(), s(null, null, 1, a), a.lineEnd()),
              u && we(v, i, t, s, a),
              a.polygonEnd()),
              (v = d = m = null);
          },
        };
      return N;
    };
  }
  function Ue(n, t) {
    function e(e, r) {
      return (e = n(e, r)), t(e[0], e[1]);
    }
    return (
      n.invert &&
        t.invert &&
        (e.invert = function (e, r) {
          return (e = t.invert(e, r)), e && n.invert(e[0], e[1]);
        }),
      e
    );
  }
  function je(n) {
    var t = 0,
      e = Sa / 3,
      r = nr(n),
      u = r(t, e);
    return (
      (u.parallels = function (n) {
        return arguments.length
          ? r((t = (n[0] * Sa) / 180), (e = (n[1] * Sa) / 180))
          : [180 * (t / Sa), 180 * (e / Sa)];
      }),
      u
    );
  }
  function He(n, t) {
    function e(n, t) {
      var e = Math.sqrt(i - 2 * u * Math.sin(t)) / u;
      return [e * Math.sin((n *= u)), o - e * Math.cos(n)];
    }
    var r = Math.sin(n),
      u = (r + Math.sin(t)) / 2,
      i = 1 + r * (2 * u - r),
      o = Math.sqrt(i) / u;
    return (
      (e.invert = function (n, t) {
        var e = o - t;
        return [
          Math.atan2(n, e) / u,
          X((i - (n * n + e * e) * u * u) / (2 * u)),
        ];
      }),
      e
    );
  }
  function Fe() {
    function n(n, t) {
      (Nc += u * n - r * t), (r = n), (u = t);
    }
    var t, e, r, u;
    (Rc.point = function (i, o) {
      (Rc.point = n), (t = r = i), (e = u = o);
    }),
      (Rc.lineEnd = function () {
        n(t, e);
      });
  }
  function Oe(n, t) {
    Lc > n && (Lc = n),
      n > qc && (qc = n),
      zc > t && (zc = t),
      t > Tc && (Tc = t);
  }
  function Ye() {
    function n(n, t) {
      o.push('M', n, ',', t, i);
    }
    function t(n, t) {
      o.push('M', n, ',', t), (a.point = e);
    }
    function e(n, t) {
      o.push('L', n, ',', t);
    }
    function r() {
      a.point = n;
    }
    function u() {
      o.push('Z');
    }
    var i = Ie(4.5),
      o = [],
      a = {
        point: n,
        lineStart: function () {
          a.point = t;
        },
        lineEnd: r,
        polygonStart: function () {
          a.lineEnd = u;
        },
        polygonEnd: function () {
          (a.lineEnd = r), (a.point = n);
        },
        pointRadius: function (n) {
          return (i = Ie(n)), a;
        },
        result: function () {
          if (o.length) {
            var n = o.join('');
            return (o = []), n;
          }
        },
      };
    return a;
  }
  function Ie(n) {
    return (
      'm0,' +
      n +
      'a' +
      n +
      ',' +
      n +
      ' 0 1,1 0,' +
      -2 * n +
      'a' +
      n +
      ',' +
      n +
      ' 0 1,1 0,' +
      2 * n +
      'z'
    );
  }
  function Ze(n, t) {
    (dc += n), (mc += t), ++yc;
  }
  function Ve() {
    function n(n, r) {
      var u = n - t,
        i = r - e,
        o = Math.sqrt(u * u + i * i);
      (xc += (o * (t + n)) / 2),
        (Mc += (o * (e + r)) / 2),
        (_c += o),
        Ze((t = n), (e = r));
    }
    var t, e;
    Pc.point = function (r, u) {
      (Pc.point = n), Ze((t = r), (e = u));
    };
  }
  function Xe() {
    Pc.point = Ze;
  }
  function $e() {
    function n(n, t) {
      var e = n - r,
        i = t - u,
        o = Math.sqrt(e * e + i * i);
      (xc += (o * (r + n)) / 2),
        (Mc += (o * (u + t)) / 2),
        (_c += o),
        (o = u * n - r * t),
        (bc += o * (r + n)),
        (wc += o * (u + t)),
        (Sc += 3 * o),
        Ze((r = n), (u = t));
    }
    var t, e, r, u;
    (Pc.point = function (i, o) {
      (Pc.point = n), Ze((t = r = i), (e = u = o));
    }),
      (Pc.lineEnd = function () {
        n(t, e);
      });
  }
  function Be(n) {
    function t(t, e) {
      n.moveTo(t, e), n.arc(t, e, o, 0, ka);
    }
    function e(t, e) {
      n.moveTo(t, e), (a.point = r);
    }
    function r(t, e) {
      n.lineTo(t, e);
    }
    function u() {
      a.point = t;
    }
    function i() {
      n.closePath();
    }
    var o = 4.5,
      a = {
        point: t,
        lineStart: function () {
          a.point = e;
        },
        lineEnd: u,
        polygonStart: function () {
          a.lineEnd = i;
        },
        polygonEnd: function () {
          (a.lineEnd = u), (a.point = t);
        },
        pointRadius: function (n) {
          return (o = n), a;
        },
        result: g,
      };
    return a;
  }
  function We(n) {
    function t(n) {
      return (a ? r : e)(n);
    }
    function e(t) {
      return Ke(t, function (e, r) {
        (e = n(e, r)), t.point(e[0], e[1]);
      });
    }
    function r(t) {
      function e(e, r) {
        (e = n(e, r)), t.point(e[0], e[1]);
      }
      function r() {
        (x = 0 / 0), (S.point = i), t.lineStart();
      }
      function i(e, r) {
        var i = se([e, r]),
          o = n(e, r);
        u(
          x,
          M,
          y,
          _,
          b,
          w,
          (x = o[0]),
          (M = o[1]),
          (y = e),
          (_ = i[0]),
          (b = i[1]),
          (w = i[2]),
          a,
          t
        ),
          t.point(x, M);
      }
      function o() {
        (S.point = e), t.lineEnd();
      }
      function c() {
        r(), (S.point = s), (S.lineEnd = l);
      }
      function s(n, t) {
        i((f = n), (h = t)),
          (g = x),
          (p = M),
          (v = _),
          (d = b),
          (m = w),
          (S.point = i);
      }
      function l() {
        u(x, M, y, _, b, w, g, p, f, v, d, m, a, t), (S.lineEnd = o), o();
      }
      var f,
        h,
        g,
        p,
        v,
        d,
        m,
        y,
        x,
        M,
        _,
        b,
        w,
        S = {
          point: e,
          lineStart: r,
          lineEnd: o,
          polygonStart: function () {
            t.polygonStart(), (S.lineStart = c);
          },
          polygonEnd: function () {
            t.polygonEnd(), (S.lineStart = r);
          },
        };
      return S;
    }
    function u(t, e, r, a, c, s, l, f, h, g, p, v, d, m) {
      var y = l - t,
        x = f - e,
        M = y * y + x * x;
      if (M > 4 * i && d--) {
        var _ = a + g,
          b = c + p,
          w = s + v,
          S = Math.sqrt(_ * _ + b * b + w * w),
          k = Math.asin((w /= S)),
          E =
            oa(oa(w) - 1) < Aa || oa(r - h) < Aa
              ? (r + h) / 2
              : Math.atan2(b, _),
          A = n(E, k),
          C = A[0],
          N = A[1],
          L = C - t,
          z = N - e,
          q = x * L - y * z;
        ((q * q) / M > i ||
          oa((y * L + x * z) / M - 0.5) > 0.3 ||
          o > a * g + c * p + s * v) &&
          (u(t, e, r, a, c, s, C, N, E, (_ /= S), (b /= S), w, d, m),
          m.point(C, N),
          u(C, N, E, _, b, w, l, f, h, g, p, v, d, m));
      }
    }
    var i = 0.5,
      o = Math.cos(30 * Na),
      a = 16;
    return (
      (t.precision = function (n) {
        return arguments.length
          ? ((a = (i = n * n) > 0 && 16), t)
          : Math.sqrt(i);
      }),
      t
    );
  }
  function Je(n) {
    var t = We(function (t, e) {
      return n([t * La, e * La]);
    });
    return function (n) {
      return tr(t(n));
    };
  }
  function Ge(n) {
    this.stream = n;
  }
  function Ke(n, t) {
    return {
      point: t,
      sphere: function () {
        n.sphere();
      },
      lineStart: function () {
        n.lineStart();
      },
      lineEnd: function () {
        n.lineEnd();
      },
      polygonStart: function () {
        n.polygonStart();
      },
      polygonEnd: function () {
        n.polygonEnd();
      },
    };
  }
  function Qe(n) {
    return nr(function () {
      return n;
    })();
  }
  function nr(n) {
    function t(n) {
      return (n = a(n[0] * Na, n[1] * Na)), [n[0] * h + c, s - n[1] * h];
    }
    function e(n) {
      return (
        (n = a.invert((n[0] - c) / h, (s - n[1]) / h)),
        n && [n[0] * La, n[1] * La]
      );
    }
    function r() {
      a = Ue((o = ur(m, y, x)), i);
      var n = i(v, d);
      return (c = g - n[0] * h), (s = p + n[1] * h), u();
    }
    function u() {
      return l && ((l.valid = !1), (l = null)), t;
    }
    var i,
      o,
      a,
      c,
      s,
      l,
      f = We(function (n, t) {
        return (n = i(n, t)), [n[0] * h + c, s - n[1] * h];
      }),
      h = 150,
      g = 480,
      p = 250,
      v = 0,
      d = 0,
      m = 0,
      y = 0,
      x = 0,
      M = Ec,
      _ = bt,
      b = null,
      w = null;
    return (
      (t.stream = function (n) {
        return l && (l.valid = !1), (l = tr(M(o, f(_(n))))), (l.valid = !0), l;
      }),
      (t.clipAngle = function (n) {
        return arguments.length
          ? ((M = null == n ? ((b = n), Ec) : Re((b = +n) * Na)), u())
          : b;
      }),
      (t.clipExtent = function (n) {
        return arguments.length
          ? ((w = n),
            (_ = n ? Pe(n[0][0], n[0][1], n[1][0], n[1][1]) : bt),
            u())
          : w;
      }),
      (t.scale = function (n) {
        return arguments.length ? ((h = +n), r()) : h;
      }),
      (t.translate = function (n) {
        return arguments.length ? ((g = +n[0]), (p = +n[1]), r()) : [g, p];
      }),
      (t.center = function (n) {
        return arguments.length
          ? ((v = (n[0] % 360) * Na), (d = (n[1] % 360) * Na), r())
          : [v * La, d * La];
      }),
      (t.rotate = function (n) {
        return arguments.length
          ? ((m = (n[0] % 360) * Na),
            (y = (n[1] % 360) * Na),
            (x = n.length > 2 ? (n[2] % 360) * Na : 0),
            r())
          : [m * La, y * La, x * La];
      }),
      Xo.rebind(t, f, 'precision'),
      function () {
        return (i = n.apply(this, arguments)), (t.invert = i.invert && e), r();
      }
    );
  }
  function tr(n) {
    return Ke(n, function (t, e) {
      n.point(t * Na, e * Na);
    });
  }
  function er(n, t) {
    return [n, t];
  }
  function rr(n, t) {
    return [n > Sa ? n - ka : -Sa > n ? n + ka : n, t];
  }
  function ur(n, t, e) {
    return n ? (t || e ? Ue(or(n), ar(t, e)) : or(n)) : t || e ? ar(t, e) : rr;
  }
  function ir(n) {
    return function (t, e) {
      return (t += n), [t > Sa ? t - ka : -Sa > t ? t + ka : t, e];
    };
  }
  function or(n) {
    var t = ir(n);
    return (t.invert = ir(-n)), t;
  }
  function ar(n, t) {
    function e(n, t) {
      var e = Math.cos(t),
        a = Math.cos(n) * e,
        c = Math.sin(n) * e,
        s = Math.sin(t),
        l = s * r + a * u;
      return [Math.atan2(c * i - l * o, a * r - s * u), X(l * i + c * o)];
    }
    var r = Math.cos(n),
      u = Math.sin(n),
      i = Math.cos(t),
      o = Math.sin(t);
    return (
      (e.invert = function (n, t) {
        var e = Math.cos(t),
          a = Math.cos(n) * e,
          c = Math.sin(n) * e,
          s = Math.sin(t),
          l = s * i - c * o;
        return [Math.atan2(c * i + s * o, a * r + l * u), X(l * r - a * u)];
      }),
      e
    );
  }
  function cr(n, t) {
    var e = Math.cos(n),
      r = Math.sin(n);
    return function (u, i, o, a) {
      var c = o * t;
      null != u
        ? ((u = sr(e, u)),
          (i = sr(e, i)),
          (o > 0 ? i > u : u > i) && (u += o * ka))
        : ((u = n + o * ka), (i = n - 0.5 * c));
      for (var s, l = u; o > 0 ? l > i : i > l; l -= c)
        a.point((s = ve([e, -r * Math.cos(l), -r * Math.sin(l)]))[0], s[1]);
    };
  }
  function sr(n, t) {
    var e = se(t);
    (e[0] -= n), pe(e);
    var r = V(-e[1]);
    return ((-e[2] < 0 ? -r : r) + 2 * Math.PI - Aa) % (2 * Math.PI);
  }
  function lr(n, t, e) {
    var r = Xo.range(n, t - Aa, e).concat(t);
    return function (n) {
      return r.map(function (t) {
        return [n, t];
      });
    };
  }
  function fr(n, t, e) {
    var r = Xo.range(n, t - Aa, e).concat(t);
    return function (n) {
      return r.map(function (t) {
        return [t, n];
      });
    };
  }
  function hr(n) {
    return n.source;
  }
  function gr(n) {
    return n.target;
  }
  function pr(n, t, e, r) {
    var u = Math.cos(t),
      i = Math.sin(t),
      o = Math.cos(r),
      a = Math.sin(r),
      c = u * Math.cos(n),
      s = u * Math.sin(n),
      l = o * Math.cos(e),
      f = o * Math.sin(e),
      h = 2 * Math.asin(Math.sqrt(J(r - t) + u * o * J(e - n))),
      g = 1 / Math.sin(h),
      p = h
        ? function (n) {
            var t = Math.sin((n *= h)) * g,
              e = Math.sin(h - n) * g,
              r = e * c + t * l,
              u = e * s + t * f,
              o = e * i + t * a;
            return [
              Math.atan2(u, r) * La,
              Math.atan2(o, Math.sqrt(r * r + u * u)) * La,
            ];
          }
        : function () {
            return [n * La, t * La];
          };
    return (p.distance = h), p;
  }
  function vr() {
    function n(n, u) {
      var i = Math.sin((u *= Na)),
        o = Math.cos(u),
        a = oa((n *= Na) - t),
        c = Math.cos(a);
      (Uc += Math.atan2(
        Math.sqrt((a = o * Math.sin(a)) * a + (a = r * i - e * o * c) * a),
        e * i + r * o * c
      )),
        (t = n),
        (e = i),
        (r = o);
    }
    var t, e, r;
    (jc.point = function (u, i) {
      (t = u * Na),
        (e = Math.sin((i *= Na))),
        (r = Math.cos(i)),
        (jc.point = n);
    }),
      (jc.lineEnd = function () {
        jc.point = jc.lineEnd = g;
      });
  }
  function dr(n, t) {
    function e(t, e) {
      var r = Math.cos(t),
        u = Math.cos(e),
        i = n(r * u);
      return [i * u * Math.sin(t), i * Math.sin(e)];
    }
    return (
      (e.invert = function (n, e) {
        var r = Math.sqrt(n * n + e * e),
          u = t(r),
          i = Math.sin(u),
          o = Math.cos(u);
        return [Math.atan2(n * i, r * o), Math.asin(r && (e * i) / r)];
      }),
      e
    );
  }
  function mr(n, t) {
    function e(n, t) {
      var e = oa(oa(t) - Ea) < Aa ? 0 : o / Math.pow(u(t), i);
      return [e * Math.sin(i * n), o - e * Math.cos(i * n)];
    }
    var r = Math.cos(n),
      u = function (n) {
        return Math.tan(Sa / 4 + n / 2);
      },
      i =
        n === t
          ? Math.sin(n)
          : Math.log(r / Math.cos(t)) / Math.log(u(t) / u(n)),
      o = (r * Math.pow(u(n), i)) / i;
    return i
      ? ((e.invert = function (n, t) {
          var e = o - t,
            r = I(i) * Math.sqrt(n * n + e * e);
          return [
            Math.atan2(n, e) / i,
            2 * Math.atan(Math.pow(o / r, 1 / i)) - Ea,
          ];
        }),
        e)
      : xr;
  }
  function yr(n, t) {
    function e(n, t) {
      var e = i - t;
      return [e * Math.sin(u * n), i - e * Math.cos(u * n)];
    }
    var r = Math.cos(n),
      u = n === t ? Math.sin(n) : (r - Math.cos(t)) / (t - n),
      i = r / u + n;
    return oa(u) < Aa
      ? er
      : ((e.invert = function (n, t) {
          var e = i - t;
          return [Math.atan2(n, e) / u, i - I(u) * Math.sqrt(n * n + e * e)];
        }),
        e);
  }
  function xr(n, t) {
    return [n, Math.log(Math.tan(Sa / 4 + t / 2))];
  }
  function Mr(n) {
    var t,
      e = Qe(n),
      r = e.scale,
      u = e.translate,
      i = e.clipExtent;
    return (
      (e.scale = function () {
        var n = r.apply(e, arguments);
        return n === e ? (t ? e.clipExtent(null) : e) : n;
      }),
      (e.translate = function () {
        var n = u.apply(e, arguments);
        return n === e ? (t ? e.clipExtent(null) : e) : n;
      }),
      (e.clipExtent = function (n) {
        var o = i.apply(e, arguments);
        if (o === e) {
          if ((t = null == n)) {
            var a = Sa * r(),
              c = u();
            i([
              [c[0] - a, c[1] - a],
              [c[0] + a, c[1] + a],
            ]);
          }
        } else t && (o = null);
        return o;
      }),
      e.clipExtent(null)
    );
  }
  function _r(n, t) {
    return [Math.log(Math.tan(Sa / 4 + t / 2)), -n];
  }
  function br(n) {
    return n[0];
  }
  function wr(n) {
    return n[1];
  }
  function Sr(n) {
    for (var t = n.length, e = [0, 1], r = 2, u = 2; t > u; u++) {
      for (; r > 1 && Z(n[e[r - 2]], n[e[r - 1]], n[u]) <= 0; ) --r;
      e[r++] = u;
    }
    return e.slice(0, r);
  }
  function kr(n, t) {
    return n[0] - t[0] || n[1] - t[1];
  }
  function Er(n, t, e) {
    return (e[0] - t[0]) * (n[1] - t[1]) < (e[1] - t[1]) * (n[0] - t[0]);
  }
  function Ar(n, t, e, r) {
    var u = n[0],
      i = e[0],
      o = t[0] - u,
      a = r[0] - i,
      c = n[1],
      s = e[1],
      l = t[1] - c,
      f = r[1] - s,
      h = (a * (c - s) - f * (u - i)) / (f * o - a * l);
    return [u + h * o, c + h * l];
  }
  function Cr(n) {
    var t = n[0],
      e = n[n.length - 1];
    return !(t[0] - e[0] || t[1] - e[1]);
  }
  function Nr() {
    Jr(this), (this.edge = this.site = this.circle = null);
  }
  function Lr(n) {
    var t = Jc.pop() || new Nr();
    return (t.site = n), t;
  }
  function zr(n) {
    Or(n), $c.remove(n), Jc.push(n), Jr(n);
  }
  function qr(n) {
    var t = n.circle,
      e = t.x,
      r = t.cy,
      u = { x: e, y: r },
      i = n.P,
      o = n.N,
      a = [n];
    zr(n);
    for (
      var c = i;
      c.circle && oa(e - c.circle.x) < Aa && oa(r - c.circle.cy) < Aa;

    )
      (i = c.P), a.unshift(c), zr(c), (c = i);
    a.unshift(c), Or(c);
    for (
      var s = o;
      s.circle && oa(e - s.circle.x) < Aa && oa(r - s.circle.cy) < Aa;

    )
      (o = s.N), a.push(s), zr(s), (s = o);
    a.push(s), Or(s);
    var l,
      f = a.length;
    for (l = 1; f > l; ++l)
      (s = a[l]), (c = a[l - 1]), $r(s.edge, c.site, s.site, u);
    (c = a[0]),
      (s = a[f - 1]),
      (s.edge = Vr(c.site, s.site, null, u)),
      Fr(c),
      Fr(s);
  }
  function Tr(n) {
    for (var t, e, r, u, i = n.x, o = n.y, a = $c._; a; )
      if (((r = Rr(a, o) - i), r > Aa)) a = a.L;
      else {
        if (((u = i - Dr(a, o)), !(u > Aa))) {
          r > -Aa
            ? ((t = a.P), (e = a))
            : u > -Aa
            ? ((t = a), (e = a.N))
            : (t = e = a);
          break;
        }
        if (!a.R) {
          t = a;
          break;
        }
        a = a.R;
      }
    var c = Lr(n);
    if (($c.insert(t, c), t || e)) {
      if (t === e)
        return (
          Or(t),
          (e = Lr(t.site)),
          $c.insert(c, e),
          (c.edge = e.edge = Vr(t.site, c.site)),
          Fr(t),
          Fr(e),
          void 0
        );
      if (!e) return (c.edge = Vr(t.site, c.site)), void 0;
      Or(t), Or(e);
      var s = t.site,
        l = s.x,
        f = s.y,
        h = n.x - l,
        g = n.y - f,
        p = e.site,
        v = p.x - l,
        d = p.y - f,
        m = 2 * (h * d - g * v),
        y = h * h + g * g,
        x = v * v + d * d,
        M = { x: (d * y - g * x) / m + l, y: (h * x - v * y) / m + f };
      $r(e.edge, s, p, M),
        (c.edge = Vr(s, n, null, M)),
        (e.edge = Vr(n, p, null, M)),
        Fr(t),
        Fr(e);
    }
  }
  function Rr(n, t) {
    var e = n.site,
      r = e.x,
      u = e.y,
      i = u - t;
    if (!i) return r;
    var o = n.P;
    if (!o) return -1 / 0;
    e = o.site;
    var a = e.x,
      c = e.y,
      s = c - t;
    if (!s) return a;
    var l = a - r,
      f = 1 / i - 1 / s,
      h = l / s;
    return f
      ? (-h +
          Math.sqrt(
            h * h - 2 * f * ((l * l) / (-2 * s) - c + s / 2 + u - i / 2)
          )) /
          f +
          r
      : (r + a) / 2;
  }
  function Dr(n, t) {
    var e = n.N;
    if (e) return Rr(e, t);
    var r = n.site;
    return r.y === t ? r.x : 1 / 0;
  }
  function Pr(n) {
    (this.site = n), (this.edges = []);
  }
  function Ur(n) {
    for (
      var t,
        e,
        r,
        u,
        i,
        o,
        a,
        c,
        s,
        l,
        f = n[0][0],
        h = n[1][0],
        g = n[0][1],
        p = n[1][1],
        v = Xc,
        d = v.length;
      d--;

    )
      if (((i = v[d]), i && i.prepare()))
        for (a = i.edges, c = a.length, o = 0; c > o; )
          (l = a[o].end()),
            (r = l.x),
            (u = l.y),
            (s = a[++o % c].start()),
            (t = s.x),
            (e = s.y),
            (oa(r - t) > Aa || oa(u - e) > Aa) &&
              (a.splice(
                o,
                0,
                new Br(
                  Xr(
                    i.site,
                    l,
                    oa(r - f) < Aa && p - u > Aa
                      ? { x: f, y: oa(t - f) < Aa ? e : p }
                      : oa(u - p) < Aa && h - r > Aa
                      ? { x: oa(e - p) < Aa ? t : h, y: p }
                      : oa(r - h) < Aa && u - g > Aa
                      ? { x: h, y: oa(t - h) < Aa ? e : g }
                      : oa(u - g) < Aa && r - f > Aa
                      ? { x: oa(e - g) < Aa ? t : f, y: g }
                      : null
                  ),
                  i.site,
                  null
                )
              ),
              ++c);
  }
  function jr(n, t) {
    return t.angle - n.angle;
  }
  function Hr() {
    Jr(this), (this.x = this.y = this.arc = this.site = this.cy = null);
  }
  function Fr(n) {
    var t = n.P,
      e = n.N;
    if (t && e) {
      var r = t.site,
        u = n.site,
        i = e.site;
      if (r !== i) {
        var o = u.x,
          a = u.y,
          c = r.x - o,
          s = r.y - a,
          l = i.x - o,
          f = i.y - a,
          h = 2 * (c * f - s * l);
        if (!(h >= -Ca)) {
          var g = c * c + s * s,
            p = l * l + f * f,
            v = (f * g - s * p) / h,
            d = (c * p - l * g) / h,
            f = d + a,
            m = Gc.pop() || new Hr();
          (m.arc = n),
            (m.site = u),
            (m.x = v + o),
            (m.y = f + Math.sqrt(v * v + d * d)),
            (m.cy = f),
            (n.circle = m);
          for (var y = null, x = Wc._; x; )
            if (m.y < x.y || (m.y === x.y && m.x <= x.x)) {
              if (!x.L) {
                y = x.P;
                break;
              }
              x = x.L;
            } else {
              if (!x.R) {
                y = x;
                break;
              }
              x = x.R;
            }
          Wc.insert(y, m), y || (Bc = m);
        }
      }
    }
  }
  function Or(n) {
    var t = n.circle;
    t &&
      (t.P || (Bc = t.N), Wc.remove(t), Gc.push(t), Jr(t), (n.circle = null));
  }
  function Yr(n) {
    for (
      var t, e = Vc, r = De(n[0][0], n[0][1], n[1][0], n[1][1]), u = e.length;
      u--;

    )
      (t = e[u]),
        (!Ir(t, n) ||
          !r(t) ||
          (oa(t.a.x - t.b.x) < Aa && oa(t.a.y - t.b.y) < Aa)) &&
          ((t.a = t.b = null), e.splice(u, 1));
  }
  function Ir(n, t) {
    var e = n.b;
    if (e) return !0;
    var r,
      u,
      i = n.a,
      o = t[0][0],
      a = t[1][0],
      c = t[0][1],
      s = t[1][1],
      l = n.l,
      f = n.r,
      h = l.x,
      g = l.y,
      p = f.x,
      v = f.y,
      d = (h + p) / 2,
      m = (g + v) / 2;
    if (v === g) {
      if (o > d || d >= a) return;
      if (h > p) {
        if (i) {
          if (i.y >= s) return;
        } else i = { x: d, y: c };
        e = { x: d, y: s };
      } else {
        if (i) {
          if (i.y < c) return;
        } else i = { x: d, y: s };
        e = { x: d, y: c };
      }
    } else if (((r = (h - p) / (v - g)), (u = m - r * d), -1 > r || r > 1))
      if (h > p) {
        if (i) {
          if (i.y >= s) return;
        } else i = { x: (c - u) / r, y: c };
        e = { x: (s - u) / r, y: s };
      } else {
        if (i) {
          if (i.y < c) return;
        } else i = { x: (s - u) / r, y: s };
        e = { x: (c - u) / r, y: c };
      }
    else if (v > g) {
      if (i) {
        if (i.x >= a) return;
      } else i = { x: o, y: r * o + u };
      e = { x: a, y: r * a + u };
    } else {
      if (i) {
        if (i.x < o) return;
      } else i = { x: a, y: r * a + u };
      e = { x: o, y: r * o + u };
    }
    return (n.a = i), (n.b = e), !0;
  }
  function Zr(n, t) {
    (this.l = n), (this.r = t), (this.a = this.b = null);
  }
  function Vr(n, t, e, r) {
    var u = new Zr(n, t);
    return (
      Vc.push(u),
      e && $r(u, n, t, e),
      r && $r(u, t, n, r),
      Xc[n.i].edges.push(new Br(u, n, t)),
      Xc[t.i].edges.push(new Br(u, t, n)),
      u
    );
  }
  function Xr(n, t, e) {
    var r = new Zr(n, null);
    return (r.a = t), (r.b = e), Vc.push(r), r;
  }
  function $r(n, t, e, r) {
    n.a || n.b
      ? n.l === e
        ? (n.b = r)
        : (n.a = r)
      : ((n.a = r), (n.l = t), (n.r = e));
  }
  function Br(n, t, e) {
    var r = n.a,
      u = n.b;
    (this.edge = n),
      (this.site = t),
      (this.angle = e
        ? Math.atan2(e.y - t.y, e.x - t.x)
        : n.l === t
        ? Math.atan2(u.x - r.x, r.y - u.y)
        : Math.atan2(r.x - u.x, u.y - r.y));
  }
  function Wr() {
    this._ = null;
  }
  function Jr(n) {
    n.U = n.C = n.L = n.R = n.P = n.N = null;
  }
  function Gr(n, t) {
    var e = t,
      r = t.R,
      u = e.U;
    u ? (u.L === e ? (u.L = r) : (u.R = r)) : (n._ = r),
      (r.U = u),
      (e.U = r),
      (e.R = r.L),
      e.R && (e.R.U = e),
      (r.L = e);
  }
  function Kr(n, t) {
    var e = t,
      r = t.L,
      u = e.U;
    u ? (u.L === e ? (u.L = r) : (u.R = r)) : (n._ = r),
      (r.U = u),
      (e.U = r),
      (e.L = r.R),
      e.L && (e.L.U = e),
      (r.R = e);
  }
  function Qr(n) {
    for (; n.L; ) n = n.L;
    return n;
  }
  function nu(n, t) {
    var e,
      r,
      u,
      i = n.sort(tu).pop();
    for (Vc = [], Xc = new Array(n.length), $c = new Wr(), Wc = new Wr(); ; )
      if (((u = Bc), i && (!u || i.y < u.y || (i.y === u.y && i.x < u.x))))
        (i.x !== e || i.y !== r) &&
          ((Xc[i.i] = new Pr(i)), Tr(i), (e = i.x), (r = i.y)),
          (i = n.pop());
      else {
        if (!u) break;
        qr(u.arc);
      }
    t && (Yr(t), Ur(t));
    var o = { cells: Xc, edges: Vc };
    return ($c = Wc = Vc = Xc = null), o;
  }
  function tu(n, t) {
    return t.y - n.y || t.x - n.x;
  }
  function eu(n, t, e) {
    return (n.x - e.x) * (t.y - n.y) - (n.x - t.x) * (e.y - n.y);
  }
  function ru(n) {
    return n.x;
  }
  function uu(n) {
    return n.y;
  }
  function iu() {
    return { leaf: !0, nodes: [], point: null, x: null, y: null };
  }
  function ou(n, t, e, r, u, i) {
    if (!n(t, e, r, u, i)) {
      var o = 0.5 * (e + u),
        a = 0.5 * (r + i),
        c = t.nodes;
      c[0] && ou(n, c[0], e, r, o, a),
        c[1] && ou(n, c[1], o, r, u, a),
        c[2] && ou(n, c[2], e, a, o, i),
        c[3] && ou(n, c[3], o, a, u, i);
    }
  }
  function au(n, t) {
    (n = Xo.rgb(n)), (t = Xo.rgb(t));
    var e = n.r,
      r = n.g,
      u = n.b,
      i = t.r - e,
      o = t.g - r,
      a = t.b - u;
    return function (n) {
      return (
        '#' +
        vt(Math.round(e + i * n)) +
        vt(Math.round(r + o * n)) +
        vt(Math.round(u + a * n))
      );
    };
  }
  function cu(n, t) {
    var e,
      r = {},
      u = {};
    for (e in n) e in t ? (r[e] = fu(n[e], t[e])) : (u[e] = n[e]);
    for (e in t) e in n || (u[e] = t[e]);
    return function (n) {
      for (e in r) u[e] = r[e](n);
      return u;
    };
  }
  function su(n, t) {
    return (
      (t -= n = +n),
      function (e) {
        return n + t * e;
      }
    );
  }
  function lu(n, t) {
    var e,
      r,
      u,
      i,
      o,
      a = 0,
      c = 0,
      s = [],
      l = [];
    for (n += '', t += '', Qc.lastIndex = 0, r = 0; (e = Qc.exec(t)); ++r)
      e.index && s.push(t.substring(a, (c = e.index))),
        l.push({ i: s.length, x: e[0] }),
        s.push(null),
        (a = Qc.lastIndex);
    for (
      a < t.length && s.push(t.substring(a)), r = 0, i = l.length;
      (e = Qc.exec(n)) && i > r;
      ++r
    )
      if (((o = l[r]), o.x == e[0])) {
        if (o.i)
          if (null == s[o.i + 1])
            for (s[o.i - 1] += o.x, s.splice(o.i, 1), u = r + 1; i > u; ++u)
              l[u].i--;
          else
            for (
              s[o.i - 1] += o.x + s[o.i + 1], s.splice(o.i, 2), u = r + 1;
              i > u;
              ++u
            )
              l[u].i -= 2;
        else if (null == s[o.i + 1]) s[o.i] = o.x;
        else
          for (
            s[o.i] = o.x + s[o.i + 1], s.splice(o.i + 1, 1), u = r + 1;
            i > u;
            ++u
          )
            l[u].i--;
        l.splice(r, 1), i--, r--;
      } else o.x = su(parseFloat(e[0]), parseFloat(o.x));
    for (; i > r; )
      (o = l.pop()),
        null == s[o.i + 1]
          ? (s[o.i] = o.x)
          : ((s[o.i] = o.x + s[o.i + 1]), s.splice(o.i + 1, 1)),
        i--;
    return 1 === s.length
      ? null == s[0]
        ? ((o = l[0].x),
          function (n) {
            return o(n) + '';
          })
        : function () {
            return t;
          }
      : function (n) {
          for (r = 0; i > r; ++r) s[(o = l[r]).i] = o.x(n);
          return s.join('');
        };
  }
  function fu(n, t) {
    for (
      var e, r = Xo.interpolators.length;
      --r >= 0 && !(e = Xo.interpolators[r](n, t));

    );
    return e;
  }
  function hu(n, t) {
    var e,
      r = [],
      u = [],
      i = n.length,
      o = t.length,
      a = Math.min(n.length, t.length);
    for (e = 0; a > e; ++e) r.push(fu(n[e], t[e]));
    for (; i > e; ++e) u[e] = n[e];
    for (; o > e; ++e) u[e] = t[e];
    return function (n) {
      for (e = 0; a > e; ++e) u[e] = r[e](n);
      return u;
    };
  }
  function gu(n) {
    return function (t) {
      return 0 >= t ? 0 : t >= 1 ? 1 : n(t);
    };
  }
  function pu(n) {
    return function (t) {
      return 1 - n(1 - t);
    };
  }
  function vu(n) {
    return function (t) {
      return 0.5 * (0.5 > t ? n(2 * t) : 2 - n(2 - 2 * t));
    };
  }
  function du(n) {
    return n * n;
  }
  function mu(n) {
    return n * n * n;
  }
  function yu(n) {
    if (0 >= n) return 0;
    if (n >= 1) return 1;
    var t = n * n,
      e = t * n;
    return 4 * (0.5 > n ? e : 3 * (n - t) + e - 0.75);
  }
  function xu(n) {
    return function (t) {
      return Math.pow(t, n);
    };
  }
  function Mu(n) {
    return 1 - Math.cos(n * Ea);
  }
  function _u(n) {
    return Math.pow(2, 10 * (n - 1));
  }
  function bu(n) {
    return 1 - Math.sqrt(1 - n * n);
  }
  function wu(n, t) {
    var e;
    return (
      arguments.length < 2 && (t = 0.45),
      arguments.length
        ? (e = (t / ka) * Math.asin(1 / n))
        : ((n = 1), (e = t / 4)),
      function (r) {
        return 1 + n * Math.pow(2, -10 * r) * Math.sin(((r - e) * ka) / t);
      }
    );
  }
  function Su(n) {
    return (
      n || (n = 1.70158),
      function (t) {
        return t * t * ((n + 1) * t - n);
      }
    );
  }
  function ku(n) {
    return 1 / 2.75 > n
      ? 7.5625 * n * n
      : 2 / 2.75 > n
      ? 7.5625 * (n -= 1.5 / 2.75) * n + 0.75
      : 2.5 / 2.75 > n
      ? 7.5625 * (n -= 2.25 / 2.75) * n + 0.9375
      : 7.5625 * (n -= 2.625 / 2.75) * n + 0.984375;
  }
  function Eu(n, t) {
    (n = Xo.hcl(n)), (t = Xo.hcl(t));
    var e = n.h,
      r = n.c,
      u = n.l,
      i = t.h - e,
      o = t.c - r,
      a = t.l - u;
    return (
      isNaN(o) && ((o = 0), (r = isNaN(r) ? t.c : r)),
      isNaN(i)
        ? ((i = 0), (e = isNaN(e) ? t.h : e))
        : i > 180
        ? (i -= 360)
        : -180 > i && (i += 360),
      function (n) {
        return rt(e + i * n, r + o * n, u + a * n) + '';
      }
    );
  }
  function Au(n, t) {
    (n = Xo.hsl(n)), (t = Xo.hsl(t));
    var e = n.h,
      r = n.s,
      u = n.l,
      i = t.h - e,
      o = t.s - r,
      a = t.l - u;
    return (
      isNaN(o) && ((o = 0), (r = isNaN(r) ? t.s : r)),
      isNaN(i)
        ? ((i = 0), (e = isNaN(e) ? t.h : e))
        : i > 180
        ? (i -= 360)
        : -180 > i && (i += 360),
      function (n) {
        return nt(e + i * n, r + o * n, u + a * n) + '';
      }
    );
  }
  function Cu(n, t) {
    (n = Xo.lab(n)), (t = Xo.lab(t));
    var e = n.l,
      r = n.a,
      u = n.b,
      i = t.l - e,
      o = t.a - r,
      a = t.b - u;
    return function (n) {
      return ot(e + i * n, r + o * n, u + a * n) + '';
    };
  }
  function Nu(n, t) {
    return (
      (t -= n),
      function (e) {
        return Math.round(n + t * e);
      }
    );
  }
  function Lu(n) {
    var t = [n.a, n.b],
      e = [n.c, n.d],
      r = qu(t),
      u = zu(t, e),
      i = qu(Tu(e, t, -u)) || 0;
    t[0] * e[1] < e[0] * t[1] &&
      ((t[0] *= -1), (t[1] *= -1), (r *= -1), (u *= -1)),
      (this.rotate =
        (r ? Math.atan2(t[1], t[0]) : Math.atan2(-e[0], e[1])) * La),
      (this.translate = [n.e, n.f]),
      (this.scale = [r, i]),
      (this.skew = i ? Math.atan2(u, i) * La : 0);
  }
  function zu(n, t) {
    return n[0] * t[0] + n[1] * t[1];
  }
  function qu(n) {
    var t = Math.sqrt(zu(n, n));
    return t && ((n[0] /= t), (n[1] /= t)), t;
  }
  function Tu(n, t, e) {
    return (n[0] += e * t[0]), (n[1] += e * t[1]), n;
  }
  function Ru(n, t) {
    var e,
      r = [],
      u = [],
      i = Xo.transform(n),
      o = Xo.transform(t),
      a = i.translate,
      c = o.translate,
      s = i.rotate,
      l = o.rotate,
      f = i.skew,
      h = o.skew,
      g = i.scale,
      p = o.scale;
    return (
      a[0] != c[0] || a[1] != c[1]
        ? (r.push('translate(', null, ',', null, ')'),
          u.push({ i: 1, x: su(a[0], c[0]) }, { i: 3, x: su(a[1], c[1]) }))
        : c[0] || c[1]
        ? r.push('translate(' + c + ')')
        : r.push(''),
      s != l
        ? (s - l > 180 ? (l += 360) : l - s > 180 && (s += 360),
          u.push({
            i: r.push(r.pop() + 'rotate(', null, ')') - 2,
            x: su(s, l),
          }))
        : l && r.push(r.pop() + 'rotate(' + l + ')'),
      f != h
        ? u.push({ i: r.push(r.pop() + 'skewX(', null, ')') - 2, x: su(f, h) })
        : h && r.push(r.pop() + 'skewX(' + h + ')'),
      g[0] != p[0] || g[1] != p[1]
        ? ((e = r.push(r.pop() + 'scale(', null, ',', null, ')')),
          u.push(
            { i: e - 4, x: su(g[0], p[0]) },
            { i: e - 2, x: su(g[1], p[1]) }
          ))
        : (1 != p[0] || 1 != p[1]) && r.push(r.pop() + 'scale(' + p + ')'),
      (e = u.length),
      function (n) {
        for (var t, i = -1; ++i < e; ) r[(t = u[i]).i] = t.x(n);
        return r.join('');
      }
    );
  }
  function Du(n, t) {
    return (
      (t = t - (n = +n) ? 1 / (t - n) : 0),
      function (e) {
        return (e - n) * t;
      }
    );
  }
  function Pu(n, t) {
    return (
      (t = t - (n = +n) ? 1 / (t - n) : 0),
      function (e) {
        return Math.max(0, Math.min(1, (e - n) * t));
      }
    );
  }
  function Uu(n) {
    for (var t = n.source, e = n.target, r = Hu(t, e), u = [t]; t !== r; )
      (t = t.parent), u.push(t);
    for (var i = u.length; e !== r; ) u.splice(i, 0, e), (e = e.parent);
    return u;
  }
  function ju(n) {
    for (var t = [], e = n.parent; null != e; )
      t.push(n), (n = e), (e = e.parent);
    return t.push(n), t;
  }
  function Hu(n, t) {
    if (n === t) return n;
    for (
      var e = ju(n), r = ju(t), u = e.pop(), i = r.pop(), o = null;
      u === i;

    )
      (o = u), (u = e.pop()), (i = r.pop());
    return o;
  }
  function Fu(n) {
    n.fixed |= 2;
  }
  function Ou(n) {
    n.fixed &= -7;
  }
  function Yu(n) {
    (n.fixed |= 4), (n.px = n.x), (n.py = n.y);
  }
  function Iu(n) {
    n.fixed &= -5;
  }
  function Zu(n, t, e) {
    var r = 0,
      u = 0;
    if (((n.charge = 0), !n.leaf))
      for (var i, o = n.nodes, a = o.length, c = -1; ++c < a; )
        (i = o[c]),
          null != i &&
            (Zu(i, t, e),
            (n.charge += i.charge),
            (r += i.charge * i.cx),
            (u += i.charge * i.cy));
    if (n.point) {
      n.leaf ||
        ((n.point.x += Math.random() - 0.5),
        (n.point.y += Math.random() - 0.5));
      var s = t * e[n.point.index];
      (n.charge += n.pointCharge = s),
        (r += s * n.point.x),
        (u += s * n.point.y);
    }
    (n.cx = r / n.charge), (n.cy = u / n.charge);
  }
  function Vu(n, t) {
    return (
      Xo.rebind(n, t, 'sort', 'children', 'value'),
      (n.nodes = n),
      (n.links = Wu),
      n
    );
  }
  function Xu(n) {
    return n.children;
  }
  function $u(n) {
    return n.value;
  }
  function Bu(n, t) {
    return t.value - n.value;
  }
  function Wu(n) {
    return Xo.merge(
      n.map(function (n) {
        return (n.children || []).map(function (t) {
          return { source: n, target: t };
        });
      })
    );
  }
  function Ju(n) {
    return n.x;
  }
  function Gu(n) {
    return n.y;
  }
  function Ku(n, t, e) {
    (n.y0 = t), (n.y = e);
  }
  function Qu(n) {
    return Xo.range(n.length);
  }
  function ni(n) {
    for (var t = -1, e = n[0].length, r = []; ++t < e; ) r[t] = 0;
    return r;
  }
  function ti(n) {
    for (var t, e = 1, r = 0, u = n[0][1], i = n.length; i > e; ++e)
      (t = n[e][1]) > u && ((r = e), (u = t));
    return r;
  }
  function ei(n) {
    return n.reduce(ri, 0);
  }
  function ri(n, t) {
    return n + t[1];
  }
  function ui(n, t) {
    return ii(n, Math.ceil(Math.log(t.length) / Math.LN2 + 1));
  }
  function ii(n, t) {
    for (var e = -1, r = +n[0], u = (n[1] - r) / t, i = []; ++e <= t; )
      i[e] = u * e + r;
    return i;
  }
  function oi(n) {
    return [Xo.min(n), Xo.max(n)];
  }
  function ai(n, t) {
    return n.parent == t.parent ? 1 : 2;
  }
  function ci(n) {
    var t = n.children;
    return t && t.length ? t[0] : n._tree.thread;
  }
  function si(n) {
    var t,
      e = n.children;
    return e && (t = e.length) ? e[t - 1] : n._tree.thread;
  }
  function li(n, t) {
    var e = n.children;
    if (e && (u = e.length))
      for (var r, u, i = -1; ++i < u; ) t((r = li(e[i], t)), n) > 0 && (n = r);
    return n;
  }
  function fi(n, t) {
    return n.x - t.x;
  }
  function hi(n, t) {
    return t.x - n.x;
  }
  function gi(n, t) {
    return n.depth - t.depth;
  }
  function pi(n, t) {
    function e(n, r) {
      var u = n.children;
      if (u && (o = u.length))
        for (var i, o, a = null, c = -1; ++c < o; )
          (i = u[c]), e(i, a), (a = i);
      t(n, r);
    }
    e(n, null);
  }
  function vi(n) {
    for (var t, e = 0, r = 0, u = n.children, i = u.length; --i >= 0; )
      (t = u[i]._tree),
        (t.prelim += e),
        (t.mod += e),
        (e += t.shift + (r += t.change));
  }
  function di(n, t, e) {
    (n = n._tree), (t = t._tree);
    var r = e / (t.number - n.number);
    (n.change += r),
      (t.change -= r),
      (t.shift += e),
      (t.prelim += e),
      (t.mod += e);
  }
  function mi(n, t, e) {
    return n._tree.ancestor.parent == t.parent ? n._tree.ancestor : e;
  }
  function yi(n, t) {
    return n.value - t.value;
  }
  function xi(n, t) {
    var e = n._pack_next;
    (n._pack_next = t),
      (t._pack_prev = n),
      (t._pack_next = e),
      (e._pack_prev = t);
  }
  function Mi(n, t) {
    (n._pack_next = t), (t._pack_prev = n);
  }
  function _i(n, t) {
    var e = t.x - n.x,
      r = t.y - n.y,
      u = n.r + t.r;
    return 0.999 * u * u > e * e + r * r;
  }
  function bi(n) {
    function t(n) {
      (l = Math.min(n.x - n.r, l)),
        (f = Math.max(n.x + n.r, f)),
        (h = Math.min(n.y - n.r, h)),
        (g = Math.max(n.y + n.r, g));
    }
    if ((e = n.children) && (s = e.length)) {
      var e,
        r,
        u,
        i,
        o,
        a,
        c,
        s,
        l = 1 / 0,
        f = -1 / 0,
        h = 1 / 0,
        g = -1 / 0;
      if (
        (e.forEach(wi),
        (r = e[0]),
        (r.x = -r.r),
        (r.y = 0),
        t(r),
        s > 1 && ((u = e[1]), (u.x = u.r), (u.y = 0), t(u), s > 2))
      )
        for (
          i = e[2],
            Ei(r, u, i),
            t(i),
            xi(r, i),
            r._pack_prev = i,
            xi(i, u),
            u = r._pack_next,
            o = 3;
          s > o;
          o++
        ) {
          Ei(r, u, (i = e[o]));
          var p = 0,
            v = 1,
            d = 1;
          for (a = u._pack_next; a !== u; a = a._pack_next, v++)
            if (_i(a, i)) {
              p = 1;
              break;
            }
          if (1 == p)
            for (
              c = r._pack_prev;
              c !== a._pack_prev && !_i(c, i);
              c = c._pack_prev, d++
            );
          p
            ? (d > v || (v == d && u.r < r.r) ? Mi(r, (u = a)) : Mi((r = c), u),
              o--)
            : (xi(r, i), (u = i), t(i));
        }
      var m = (l + f) / 2,
        y = (h + g) / 2,
        x = 0;
      for (o = 0; s > o; o++)
        (i = e[o]),
          (i.x -= m),
          (i.y -= y),
          (x = Math.max(x, i.r + Math.sqrt(i.x * i.x + i.y * i.y)));
      (n.r = x), e.forEach(Si);
    }
  }
  function wi(n) {
    n._pack_next = n._pack_prev = n;
  }
  function Si(n) {
    delete n._pack_next, delete n._pack_prev;
  }
  function ki(n, t, e, r) {
    var u = n.children;
    if (((n.x = t += r * n.x), (n.y = e += r * n.y), (n.r *= r), u))
      for (var i = -1, o = u.length; ++i < o; ) ki(u[i], t, e, r);
  }
  function Ei(n, t, e) {
    var r = n.r + e.r,
      u = t.x - n.x,
      i = t.y - n.y;
    if (r && (u || i)) {
      var o = t.r + e.r,
        a = u * u + i * i;
      (o *= o), (r *= r);
      var c = 0.5 + (r - o) / (2 * a),
        s =
          Math.sqrt(Math.max(0, 2 * o * (r + a) - (r -= a) * r - o * o)) /
          (2 * a);
      (e.x = n.x + c * u + s * i), (e.y = n.y + c * i - s * u);
    } else (e.x = n.x + r), (e.y = n.y);
  }
  function Ai(n) {
    return (
      1 +
      Xo.max(n, function (n) {
        return n.y;
      })
    );
  }
  function Ci(n) {
    return (
      n.reduce(function (n, t) {
        return n + t.x;
      }, 0) / n.length
    );
  }
  function Ni(n) {
    var t = n.children;
    return t && t.length ? Ni(t[0]) : n;
  }
  function Li(n) {
    var t,
      e = n.children;
    return e && (t = e.length) ? Li(e[t - 1]) : n;
  }
  function zi(n) {
    return { x: n.x, y: n.y, dx: n.dx, dy: n.dy };
  }
  function qi(n, t) {
    var e = n.x + t[3],
      r = n.y + t[0],
      u = n.dx - t[1] - t[3],
      i = n.dy - t[0] - t[2];
    return (
      0 > u && ((e += u / 2), (u = 0)),
      0 > i && ((r += i / 2), (i = 0)),
      { x: e, y: r, dx: u, dy: i }
    );
  }
  function Ti(n) {
    var t = n[0],
      e = n[n.length - 1];
    return e > t ? [t, e] : [e, t];
  }
  function Ri(n) {
    return n.rangeExtent ? n.rangeExtent() : Ti(n.range());
  }
  function Di(n, t, e, r) {
    var u = e(n[0], n[1]),
      i = r(t[0], t[1]);
    return function (n) {
      return i(u(n));
    };
  }
  function Pi(n, t) {
    var e,
      r = 0,
      u = n.length - 1,
      i = n[r],
      o = n[u];
    return (
      i > o && ((e = r), (r = u), (u = e), (e = i), (i = o), (o = e)),
      (n[r] = t.floor(i)),
      (n[u] = t.ceil(o)),
      n
    );
  }
  function Ui(n) {
    return n
      ? {
          floor: function (t) {
            return Math.floor(t / n) * n;
          },
          ceil: function (t) {
            return Math.ceil(t / n) * n;
          },
        }
      : ls;
  }
  function ji(n, t, e, r) {
    var u = [],
      i = [],
      o = 0,
      a = Math.min(n.length, t.length) - 1;
    for (
      n[a] < n[0] && ((n = n.slice().reverse()), (t = t.slice().reverse()));
      ++o <= a;

    )
      u.push(e(n[o - 1], n[o])), i.push(r(t[o - 1], t[o]));
    return function (t) {
      var e = Xo.bisect(n, t, 1, a) - 1;
      return i[e](u[e](t));
    };
  }
  function Hi(n, t, e, r) {
    function u() {
      var u = Math.min(n.length, t.length) > 2 ? ji : Di,
        c = r ? Pu : Du;
      return (o = u(n, t, c, e)), (a = u(t, n, c, fu)), i;
    }
    function i(n) {
      return o(n);
    }
    var o, a;
    return (
      (i.invert = function (n) {
        return a(n);
      }),
      (i.domain = function (t) {
        return arguments.length ? ((n = t.map(Number)), u()) : n;
      }),
      (i.range = function (n) {
        return arguments.length ? ((t = n), u()) : t;
      }),
      (i.rangeRound = function (n) {
        return i.range(n).interpolate(Nu);
      }),
      (i.clamp = function (n) {
        return arguments.length ? ((r = n), u()) : r;
      }),
      (i.interpolate = function (n) {
        return arguments.length ? ((e = n), u()) : e;
      }),
      (i.ticks = function (t) {
        return Ii(n, t);
      }),
      (i.tickFormat = function (t, e) {
        return Zi(n, t, e);
      }),
      (i.nice = function (t) {
        return Oi(n, t), u();
      }),
      (i.copy = function () {
        return Hi(n, t, e, r);
      }),
      u()
    );
  }
  function Fi(n, t) {
    return Xo.rebind(n, t, 'range', 'rangeRound', 'interpolate', 'clamp');
  }
  function Oi(n, t) {
    return Pi(n, Ui(Yi(n, t)[2]));
  }
  function Yi(n, t) {
    null == t && (t = 10);
    var e = Ti(n),
      r = e[1] - e[0],
      u = Math.pow(10, Math.floor(Math.log(r / t) / Math.LN10)),
      i = (t / r) * u;
    return (
      0.15 >= i ? (u *= 10) : 0.35 >= i ? (u *= 5) : 0.75 >= i && (u *= 2),
      (e[0] = Math.ceil(e[0] / u) * u),
      (e[1] = Math.floor(e[1] / u) * u + 0.5 * u),
      (e[2] = u),
      e
    );
  }
  function Ii(n, t) {
    return Xo.range.apply(Xo, Yi(n, t));
  }
  function Zi(n, t, e) {
    var r = Yi(n, t);
    return Xo.format(
      e
        ? e.replace(Qa, function (n, t, e, u, i, o, a, c, s, l) {
            return [t, e, u, i, o, a, c, s || '.' + Xi(l, r), l].join('');
          })
        : ',.' + Vi(r[2]) + 'f'
    );
  }
  function Vi(n) {
    return -Math.floor(Math.log(n) / Math.LN10 + 0.01);
  }
  function Xi(n, t) {
    var e = Vi(t[2]);
    return n in fs
      ? Math.abs(e - Vi(Math.max(Math.abs(t[0]), Math.abs(t[1])))) +
          +('e' !== n)
      : e - 2 * ('%' === n);
  }
  function $i(n, t, e, r) {
    function u(n) {
      return (
        (e ? Math.log(0 > n ? 0 : n) : -Math.log(n > 0 ? 0 : -n)) / Math.log(t)
      );
    }
    function i(n) {
      return e ? Math.pow(t, n) : -Math.pow(t, -n);
    }
    function o(t) {
      return n(u(t));
    }
    return (
      (o.invert = function (t) {
        return i(n.invert(t));
      }),
      (o.domain = function (t) {
        return arguments.length
          ? ((e = t[0] >= 0), n.domain((r = t.map(Number)).map(u)), o)
          : r;
      }),
      (o.base = function (e) {
        return arguments.length ? ((t = +e), n.domain(r.map(u)), o) : t;
      }),
      (o.nice = function () {
        var t = Pi(r.map(u), e ? Math : gs);
        return n.domain(t), (r = t.map(i)), o;
      }),
      (o.ticks = function () {
        var n = Ti(r),
          o = [],
          a = n[0],
          c = n[1],
          s = Math.floor(u(a)),
          l = Math.ceil(u(c)),
          f = t % 1 ? 2 : t;
        if (isFinite(l - s)) {
          if (e) {
            for (; l > s; s++) for (var h = 1; f > h; h++) o.push(i(s) * h);
            o.push(i(s));
          } else
            for (o.push(i(s)); s++ < l; )
              for (var h = f - 1; h > 0; h--) o.push(i(s) * h);
          for (s = 0; o[s] < a; s++);
          for (l = o.length; o[l - 1] > c; l--);
          o = o.slice(s, l);
        }
        return o;
      }),
      (o.tickFormat = function (n, t) {
        if (!arguments.length) return hs;
        arguments.length < 2
          ? (t = hs)
          : 'function' != typeof t && (t = Xo.format(t));
        var r,
          a = Math.max(0.1, n / o.ticks().length),
          c = e ? ((r = 1e-12), Math.ceil) : ((r = -1e-12), Math.floor);
        return function (n) {
          return n / i(c(u(n) + r)) <= a ? t(n) : '';
        };
      }),
      (o.copy = function () {
        return $i(n.copy(), t, e, r);
      }),
      Fi(o, n)
    );
  }
  function Bi(n, t, e) {
    function r(t) {
      return n(u(t));
    }
    var u = Wi(t),
      i = Wi(1 / t);
    return (
      (r.invert = function (t) {
        return i(n.invert(t));
      }),
      (r.domain = function (t) {
        return arguments.length ? (n.domain((e = t.map(Number)).map(u)), r) : e;
      }),
      (r.ticks = function (n) {
        return Ii(e, n);
      }),
      (r.tickFormat = function (n, t) {
        return Zi(e, n, t);
      }),
      (r.nice = function (n) {
        return r.domain(Oi(e, n));
      }),
      (r.exponent = function (o) {
        return arguments.length
          ? ((u = Wi((t = o))), (i = Wi(1 / t)), n.domain(e.map(u)), r)
          : t;
      }),
      (r.copy = function () {
        return Bi(n.copy(), t, e);
      }),
      Fi(r, n)
    );
  }
  function Wi(n) {
    return function (t) {
      return 0 > t ? -Math.pow(-t, n) : Math.pow(t, n);
    };
  }
  function Ji(n, t) {
    function e(e) {
      return o[
        ((i.get(e) || ('range' === t.t && i.set(e, n.push(e)))) - 1) % o.length
      ];
    }
    function r(t, e) {
      return Xo.range(n.length).map(function (n) {
        return t + e * n;
      });
    }
    var i, o, a;
    return (
      (e.domain = function (r) {
        if (!arguments.length) return n;
        (n = []), (i = new u());
        for (var o, a = -1, c = r.length; ++a < c; )
          i.has((o = r[a])) || i.set(o, n.push(o));
        return e[t.t].apply(e, t.a);
      }),
      (e.range = function (n) {
        return arguments.length
          ? ((o = n), (a = 0), (t = { t: 'range', a: arguments }), e)
          : o;
      }),
      (e.rangePoints = function (u, i) {
        arguments.length < 2 && (i = 0);
        var c = u[0],
          s = u[1],
          l = (s - c) / (Math.max(1, n.length - 1) + i);
        return (
          (o = r(n.length < 2 ? (c + s) / 2 : c + (l * i) / 2, l)),
          (a = 0),
          (t = { t: 'rangePoints', a: arguments }),
          e
        );
      }),
      (e.rangeBands = function (u, i, c) {
        arguments.length < 2 && (i = 0), arguments.length < 3 && (c = i);
        var s = u[1] < u[0],
          l = u[s - 0],
          f = u[1 - s],
          h = (f - l) / (n.length - i + 2 * c);
        return (
          (o = r(l + h * c, h)),
          s && o.reverse(),
          (a = h * (1 - i)),
          (t = { t: 'rangeBands', a: arguments }),
          e
        );
      }),
      (e.rangeRoundBands = function (u, i, c) {
        arguments.length < 2 && (i = 0), arguments.length < 3 && (c = i);
        var s = u[1] < u[0],
          l = u[s - 0],
          f = u[1 - s],
          h = Math.floor((f - l) / (n.length - i + 2 * c)),
          g = f - l - (n.length - i) * h;
        return (
          (o = r(l + Math.round(g / 2), h)),
          s && o.reverse(),
          (a = Math.round(h * (1 - i))),
          (t = { t: 'rangeRoundBands', a: arguments }),
          e
        );
      }),
      (e.rangeBand = function () {
        return a;
      }),
      (e.rangeExtent = function () {
        return Ti(t.a[0]);
      }),
      (e.copy = function () {
        return Ji(n, t);
      }),
      e.domain(n)
    );
  }
  function Gi(n, t) {
    function e() {
      var e = 0,
        i = t.length;
      for (u = []; ++e < i; ) u[e - 1] = Xo.quantile(n, e / i);
      return r;
    }
    function r(n) {
      return isNaN((n = +n)) ? void 0 : t[Xo.bisect(u, n)];
    }
    var u;
    return (
      (r.domain = function (t) {
        return arguments.length
          ? ((n = t
              .filter(function (n) {
                return !isNaN(n);
              })
              .sort(Xo.ascending)),
            e())
          : n;
      }),
      (r.range = function (n) {
        return arguments.length ? ((t = n), e()) : t;
      }),
      (r.quantiles = function () {
        return u;
      }),
      (r.invertExtent = function (e) {
        return (
          (e = t.indexOf(e)),
          0 > e
            ? [0 / 0, 0 / 0]
            : [e > 0 ? u[e - 1] : n[0], e < u.length ? u[e] : n[n.length - 1]]
        );
      }),
      (r.copy = function () {
        return Gi(n, t);
      }),
      e()
    );
  }
  function Ki(n, t, e) {
    function r(t) {
      return e[Math.max(0, Math.min(o, Math.floor(i * (t - n))))];
    }
    function u() {
      return (i = e.length / (t - n)), (o = e.length - 1), r;
    }
    var i, o;
    return (
      (r.domain = function (e) {
        return arguments.length
          ? ((n = +e[0]), (t = +e[e.length - 1]), u())
          : [n, t];
      }),
      (r.range = function (n) {
        return arguments.length ? ((e = n), u()) : e;
      }),
      (r.invertExtent = function (t) {
        return (
          (t = e.indexOf(t)), (t = 0 > t ? 0 / 0 : t / i + n), [t, t + 1 / i]
        );
      }),
      (r.copy = function () {
        return Ki(n, t, e);
      }),
      u()
    );
  }
  function Qi(n, t) {
    function e(e) {
      return e >= e ? t[Xo.bisect(n, e)] : void 0;
    }
    return (
      (e.domain = function (t) {
        return arguments.length ? ((n = t), e) : n;
      }),
      (e.range = function (n) {
        return arguments.length ? ((t = n), e) : t;
      }),
      (e.invertExtent = function (e) {
        return (e = t.indexOf(e)), [n[e - 1], n[e]];
      }),
      (e.copy = function () {
        return Qi(n, t);
      }),
      e
    );
  }
  function no(n) {
    function t(n) {
      return +n;
    }
    return (
      (t.invert = t),
      (t.domain = t.range =
        function (e) {
          return arguments.length ? ((n = e.map(t)), t) : n;
        }),
      (t.ticks = function (t) {
        return Ii(n, t);
      }),
      (t.tickFormat = function (t, e) {
        return Zi(n, t, e);
      }),
      (t.copy = function () {
        return no(n);
      }),
      t
    );
  }
  function to(n) {
    return n.innerRadius;
  }
  function eo(n) {
    return n.outerRadius;
  }
  function ro(n) {
    return n.startAngle;
  }
  function uo(n) {
    return n.endAngle;
  }
  function io(n) {
    function t(t) {
      function o() {
        s.push('M', i(n(l), a));
      }
      for (
        var c, s = [], l = [], f = -1, h = t.length, g = _t(e), p = _t(r);
        ++f < h;

      )
        u.call(this, (c = t[f]), f)
          ? l.push([+g.call(this, c, f), +p.call(this, c, f)])
          : l.length && (o(), (l = []));
      return l.length && o(), s.length ? s.join('') : null;
    }
    var e = br,
      r = wr,
      u = be,
      i = oo,
      o = i.key,
      a = 0.7;
    return (
      (t.x = function (n) {
        return arguments.length ? ((e = n), t) : e;
      }),
      (t.y = function (n) {
        return arguments.length ? ((r = n), t) : r;
      }),
      (t.defined = function (n) {
        return arguments.length ? ((u = n), t) : u;
      }),
      (t.interpolate = function (n) {
        return arguments.length
          ? ((o = 'function' == typeof n ? (i = n) : (i = Ms.get(n) || oo).key),
            t)
          : o;
      }),
      (t.tension = function (n) {
        return arguments.length ? ((a = n), t) : a;
      }),
      t
    );
  }
  function oo(n) {
    return n.join('L');
  }
  function ao(n) {
    return oo(n) + 'Z';
  }
  function co(n) {
    for (var t = 0, e = n.length, r = n[0], u = [r[0], ',', r[1]]; ++t < e; )
      u.push('H', (r[0] + (r = n[t])[0]) / 2, 'V', r[1]);
    return e > 1 && u.push('H', r[0]), u.join('');
  }
  function so(n) {
    for (var t = 0, e = n.length, r = n[0], u = [r[0], ',', r[1]]; ++t < e; )
      u.push('V', (r = n[t])[1], 'H', r[0]);
    return u.join('');
  }
  function lo(n) {
    for (var t = 0, e = n.length, r = n[0], u = [r[0], ',', r[1]]; ++t < e; )
      u.push('H', (r = n[t])[0], 'V', r[1]);
    return u.join('');
  }
  function fo(n, t) {
    return n.length < 4 ? oo(n) : n[1] + po(n.slice(1, n.length - 1), vo(n, t));
  }
  function ho(n, t) {
    return n.length < 3
      ? oo(n)
      : n[0] +
          po((n.push(n[0]), n), vo([n[n.length - 2]].concat(n, [n[1]]), t));
  }
  function go(n, t) {
    return n.length < 3 ? oo(n) : n[0] + po(n, vo(n, t));
  }
  function po(n, t) {
    if (t.length < 1 || (n.length != t.length && n.length != t.length + 2))
      return oo(n);
    var e = n.length != t.length,
      r = '',
      u = n[0],
      i = n[1],
      o = t[0],
      a = o,
      c = 1;
    if (
      (e &&
        ((r +=
          'Q' +
          (i[0] - (2 * o[0]) / 3) +
          ',' +
          (i[1] - (2 * o[1]) / 3) +
          ',' +
          i[0] +
          ',' +
          i[1]),
        (u = n[1]),
        (c = 2)),
      t.length > 1)
    ) {
      (a = t[1]),
        (i = n[c]),
        c++,
        (r +=
          'C' +
          (u[0] + o[0]) +
          ',' +
          (u[1] + o[1]) +
          ',' +
          (i[0] - a[0]) +
          ',' +
          (i[1] - a[1]) +
          ',' +
          i[0] +
          ',' +
          i[1]);
      for (var s = 2; s < t.length; s++, c++)
        (i = n[c]),
          (a = t[s]),
          (r +=
            'S' +
            (i[0] - a[0]) +
            ',' +
            (i[1] - a[1]) +
            ',' +
            i[0] +
            ',' +
            i[1]);
    }
    if (e) {
      var l = n[c];
      r +=
        'Q' +
        (i[0] + (2 * a[0]) / 3) +
        ',' +
        (i[1] + (2 * a[1]) / 3) +
        ',' +
        l[0] +
        ',' +
        l[1];
    }
    return r;
  }
  function vo(n, t) {
    for (
      var e, r = [], u = (1 - t) / 2, i = n[0], o = n[1], a = 1, c = n.length;
      ++a < c;

    )
      (e = i),
        (i = o),
        (o = n[a]),
        r.push([u * (o[0] - e[0]), u * (o[1] - e[1])]);
    return r;
  }
  function mo(n) {
    if (n.length < 3) return oo(n);
    var t = 1,
      e = n.length,
      r = n[0],
      u = r[0],
      i = r[1],
      o = [u, u, u, (r = n[1])[0]],
      a = [i, i, i, r[1]],
      c = [u, ',', i, 'L', _o(ws, o), ',', _o(ws, a)];
    for (n.push(n[e - 1]); ++t <= e; )
      (r = n[t]), o.shift(), o.push(r[0]), a.shift(), a.push(r[1]), bo(c, o, a);
    return n.pop(), c.push('L', r), c.join('');
  }
  function yo(n) {
    if (n.length < 4) return oo(n);
    for (var t, e = [], r = -1, u = n.length, i = [0], o = [0]; ++r < 3; )
      (t = n[r]), i.push(t[0]), o.push(t[1]);
    for (e.push(_o(ws, i) + ',' + _o(ws, o)), --r; ++r < u; )
      (t = n[r]), i.shift(), i.push(t[0]), o.shift(), o.push(t[1]), bo(e, i, o);
    return e.join('');
  }
  function xo(n) {
    for (var t, e, r = -1, u = n.length, i = u + 4, o = [], a = []; ++r < 4; )
      (e = n[r % u]), o.push(e[0]), a.push(e[1]);
    for (t = [_o(ws, o), ',', _o(ws, a)], --r; ++r < i; )
      (e = n[r % u]),
        o.shift(),
        o.push(e[0]),
        a.shift(),
        a.push(e[1]),
        bo(t, o, a);
    return t.join('');
  }
  function Mo(n, t) {
    var e = n.length - 1;
    if (e)
      for (
        var r,
          u,
          i = n[0][0],
          o = n[0][1],
          a = n[e][0] - i,
          c = n[e][1] - o,
          s = -1;
        ++s <= e;

      )
        (r = n[s]),
          (u = s / e),
          (r[0] = t * r[0] + (1 - t) * (i + u * a)),
          (r[1] = t * r[1] + (1 - t) * (o + u * c));
    return mo(n);
  }
  function _o(n, t) {
    return n[0] * t[0] + n[1] * t[1] + n[2] * t[2] + n[3] * t[3];
  }
  function bo(n, t, e) {
    n.push(
      'C',
      _o(_s, t),
      ',',
      _o(_s, e),
      ',',
      _o(bs, t),
      ',',
      _o(bs, e),
      ',',
      _o(ws, t),
      ',',
      _o(ws, e)
    );
  }
  function wo(n, t) {
    return (t[1] - n[1]) / (t[0] - n[0]);
  }
  function So(n) {
    for (
      var t = 0,
        e = n.length - 1,
        r = [],
        u = n[0],
        i = n[1],
        o = (r[0] = wo(u, i));
      ++t < e;

    )
      r[t] = (o + (o = wo((u = i), (i = n[t + 1])))) / 2;
    return (r[t] = o), r;
  }
  function ko(n) {
    for (var t, e, r, u, i = [], o = So(n), a = -1, c = n.length - 1; ++a < c; )
      (t = wo(n[a], n[a + 1])),
        oa(t) < Aa
          ? (o[a] = o[a + 1] = 0)
          : ((e = o[a] / t),
            (r = o[a + 1] / t),
            (u = e * e + r * r),
            u > 9 &&
              ((u = (3 * t) / Math.sqrt(u)),
              (o[a] = u * e),
              (o[a + 1] = u * r)));
    for (a = -1; ++a <= c; )
      (u =
        (n[Math.min(c, a + 1)][0] - n[Math.max(0, a - 1)][0]) /
        (6 * (1 + o[a] * o[a]))),
        i.push([u || 0, o[a] * u || 0]);
    return i;
  }
  function Eo(n) {
    return n.length < 3 ? oo(n) : n[0] + po(n, ko(n));
  }
  function Ao(n) {
    for (var t, e, r, u = -1, i = n.length; ++u < i; )
      (t = n[u]),
        (e = t[0]),
        (r = t[1] + ys),
        (t[0] = e * Math.cos(r)),
        (t[1] = e * Math.sin(r));
    return n;
  }
  function Co(n) {
    function t(t) {
      function c() {
        v.push('M', a(n(m), f), l, s(n(d.reverse()), f), 'Z');
      }
      for (
        var h,
          g,
          p,
          v = [],
          d = [],
          m = [],
          y = -1,
          x = t.length,
          M = _t(e),
          _ = _t(u),
          b =
            e === r
              ? function () {
                  return g;
                }
              : _t(r),
          w =
            u === i
              ? function () {
                  return p;
                }
              : _t(i);
        ++y < x;

      )
        o.call(this, (h = t[y]), y)
          ? (d.push([(g = +M.call(this, h, y)), (p = +_.call(this, h, y))]),
            m.push([+b.call(this, h, y), +w.call(this, h, y)]))
          : d.length && (c(), (d = []), (m = []));
      return d.length && c(), v.length ? v.join('') : null;
    }
    var e = br,
      r = br,
      u = 0,
      i = wr,
      o = be,
      a = oo,
      c = a.key,
      s = a,
      l = 'L',
      f = 0.7;
    return (
      (t.x = function (n) {
        return arguments.length ? ((e = r = n), t) : r;
      }),
      (t.x0 = function (n) {
        return arguments.length ? ((e = n), t) : e;
      }),
      (t.x1 = function (n) {
        return arguments.length ? ((r = n), t) : r;
      }),
      (t.y = function (n) {
        return arguments.length ? ((u = i = n), t) : i;
      }),
      (t.y0 = function (n) {
        return arguments.length ? ((u = n), t) : u;
      }),
      (t.y1 = function (n) {
        return arguments.length ? ((i = n), t) : i;
      }),
      (t.defined = function (n) {
        return arguments.length ? ((o = n), t) : o;
      }),
      (t.interpolate = function (n) {
        return arguments.length
          ? ((c = 'function' == typeof n ? (a = n) : (a = Ms.get(n) || oo).key),
            (s = a.reverse || a),
            (l = a.closed ? 'M' : 'L'),
            t)
          : c;
      }),
      (t.tension = function (n) {
        return arguments.length ? ((f = n), t) : f;
      }),
      t
    );
  }
  function No(n) {
    return n.radius;
  }
  function Lo(n) {
    return [n.x, n.y];
  }
  function zo(n) {
    return function () {
      var t = n.apply(this, arguments),
        e = t[0],
        r = t[1] + ys;
      return [e * Math.cos(r), e * Math.sin(r)];
    };
  }
  function qo() {
    return 64;
  }
  function To() {
    return 'circle';
  }
  function Ro(n) {
    var t = Math.sqrt(n / Sa);
    return (
      'M0,' +
      t +
      'A' +
      t +
      ',' +
      t +
      ' 0 1,1 0,' +
      -t +
      'A' +
      t +
      ',' +
      t +
      ' 0 1,1 0,' +
      t +
      'Z'
    );
  }
  function Do(n, t) {
    return fa(n, Ns), (n.id = t), n;
  }
  function Po(n, t, e, r) {
    var u = n.id;
    return R(
      n,
      'function' == typeof e
        ? function (n, i, o) {
            n.__transition__[u].tween.set(t, r(e.call(n, n.__data__, i, o)));
          }
        : ((e = r(e)),
          function (n) {
            n.__transition__[u].tween.set(t, e);
          })
    );
  }
  function Uo(n) {
    return (
      null == n && (n = ''),
      function () {
        this.textContent = n;
      }
    );
  }
  function jo(n, t, e, r) {
    var i = n.__transition__ || (n.__transition__ = { active: 0, count: 0 }),
      o = i[e];
    if (!o) {
      var a = r.time;
      (o = i[e] =
        {
          tween: new u(),
          time: a,
          ease: r.ease,
          delay: r.delay,
          duration: r.duration,
        }),
        ++i.count,
        Xo.timer(
          function (r) {
            function u(r) {
              return i.active > e
                ? s()
                : ((i.active = e),
                  o.event && o.event.start.call(n, l, t),
                  o.tween.forEach(function (e, r) {
                    (r = r.call(n, l, t)) && v.push(r);
                  }),
                  Xo.timer(
                    function () {
                      return (p.c = c(r || 1) ? be : c), 1;
                    },
                    0,
                    a
                  ),
                  void 0);
            }
            function c(r) {
              if (i.active !== e) return s();
              for (var u = r / g, a = f(u), c = v.length; c > 0; )
                v[--c].call(n, a);
              return u >= 1
                ? (o.event && o.event.end.call(n, l, t), s())
                : void 0;
            }
            function s() {
              return --i.count ? delete i[e] : delete n.__transition__, 1;
            }
            var l = n.__data__,
              f = o.ease,
              h = o.delay,
              g = o.duration,
              p = Ja,
              v = [];
            return (p.t = h + a), r >= h ? u(r - h) : ((p.c = u), void 0);
          },
          0,
          a
        );
    }
  }
  function Ho(n, t) {
    n.attr('transform', function (n) {
      return 'translate(' + t(n) + ',0)';
    });
  }
  function Fo(n, t) {
    n.attr('transform', function (n) {
      return 'translate(0,' + t(n) + ')';
    });
  }
  function Oo(n) {
    return n.toISOString();
  }
  function Yo(n, t, e) {
    function r(t) {
      return n(t);
    }
    function u(n, e) {
      var r = n[1] - n[0],
        u = r / e,
        i = Xo.bisect(js, u);
      return i == js.length
        ? [
            t.year,
            Yi(
              n.map(function (n) {
                return n / 31536e6;
              }),
              e
            )[2],
          ]
        : i
        ? t[u / js[i - 1] < js[i] / u ? i - 1 : i]
        : [Os, Yi(n, e)[2]];
    }
    return (
      (r.invert = function (t) {
        return Io(n.invert(t));
      }),
      (r.domain = function (t) {
        return arguments.length ? (n.domain(t), r) : n.domain().map(Io);
      }),
      (r.nice = function (n, t) {
        function e(e) {
          return !isNaN(e) && !n.range(e, Io(+e + 1), t).length;
        }
        var i = r.domain(),
          o = Ti(i),
          a = null == n ? u(o, 10) : 'number' == typeof n && u(o, n);
        return (
          a && ((n = a[0]), (t = a[1])),
          r.domain(
            Pi(
              i,
              t > 1
                ? {
                    floor: function (t) {
                      for (; e((t = n.floor(t))); ) t = Io(t - 1);
                      return t;
                    },
                    ceil: function (t) {
                      for (; e((t = n.ceil(t))); ) t = Io(+t + 1);
                      return t;
                    },
                  }
                : n
            )
          )
        );
      }),
      (r.ticks = function (n, t) {
        var e = Ti(r.domain()),
          i =
            null == n
              ? u(e, 10)
              : 'number' == typeof n
              ? u(e, n)
              : !n.range && [{ range: n }, t];
        return (
          i && ((n = i[0]), (t = i[1])),
          n.range(e[0], Io(+e[1] + 1), 1 > t ? 1 : t)
        );
      }),
      (r.tickFormat = function () {
        return e;
      }),
      (r.copy = function () {
        return Yo(n.copy(), t, e);
      }),
      Fi(r, n)
    );
  }
  function Io(n) {
    return new Date(n);
  }
  function Zo(n) {
    return JSON.parse(n.responseText);
  }
  function Vo(n) {
    var t = Wo.createRange();
    return t.selectNode(Wo.body), t.createContextualFragment(n.responseText);
  }
  var Xo = { version: '3.4.1' };
  Date.now ||
    (Date.now = function () {
      return +new Date();
    });
  var $o = [].slice,
    Bo = function (n) {
      return $o.call(n);
    },
    Wo = document,
    Jo = Wo.documentElement,
    Go = window;
  try {
    Bo(Jo.childNodes)[0].nodeType;
  } catch (Ko) {
    Bo = function (n) {
      for (var t = n.length, e = new Array(t); t--; ) e[t] = n[t];
      return e;
    };
  }
  try {
    Wo.createElement('div').style.setProperty('opacity', 0, '');
  } catch (Qo) {
    var na = Go.Element.prototype,
      ta = na.setAttribute,
      ea = na.setAttributeNS,
      ra = Go.CSSStyleDeclaration.prototype,
      ua = ra.setProperty;
    (na.setAttribute = function (n, t) {
      ta.call(this, n, t + '');
    }),
      (na.setAttributeNS = function (n, t, e) {
        ea.call(this, n, t, e + '');
      }),
      (ra.setProperty = function (n, t, e) {
        ua.call(this, n, t + '', e);
      });
  }
  (Xo.ascending = function (n, t) {
    return t > n ? -1 : n > t ? 1 : n >= t ? 0 : 0 / 0;
  }),
    (Xo.descending = function (n, t) {
      return n > t ? -1 : t > n ? 1 : t >= n ? 0 : 0 / 0;
    }),
    (Xo.min = function (n, t) {
      var e,
        r,
        u = -1,
        i = n.length;
      if (1 === arguments.length) {
        for (; ++u < i && !(null != (e = n[u]) && e >= e); ) e = void 0;
        for (; ++u < i; ) null != (r = n[u]) && e > r && (e = r);
      } else {
        for (; ++u < i && !(null != (e = t.call(n, n[u], u)) && e >= e); )
          e = void 0;
        for (; ++u < i; ) null != (r = t.call(n, n[u], u)) && e > r && (e = r);
      }
      return e;
    }),
    (Xo.max = function (n, t) {
      var e,
        r,
        u = -1,
        i = n.length;
      if (1 === arguments.length) {
        for (; ++u < i && !(null != (e = n[u]) && e >= e); ) e = void 0;
        for (; ++u < i; ) null != (r = n[u]) && r > e && (e = r);
      } else {
        for (; ++u < i && !(null != (e = t.call(n, n[u], u)) && e >= e); )
          e = void 0;
        for (; ++u < i; ) null != (r = t.call(n, n[u], u)) && r > e && (e = r);
      }
      return e;
    }),
    (Xo.extent = function (n, t) {
      var e,
        r,
        u,
        i = -1,
        o = n.length;
      if (1 === arguments.length) {
        for (; ++i < o && !(null != (e = u = n[i]) && e >= e); ) e = u = void 0;
        for (; ++i < o; )
          null != (r = n[i]) && (e > r && (e = r), r > u && (u = r));
      } else {
        for (; ++i < o && !(null != (e = u = t.call(n, n[i], i)) && e >= e); )
          e = void 0;
        for (; ++i < o; )
          null != (r = t.call(n, n[i], i)) &&
            (e > r && (e = r), r > u && (u = r));
      }
      return [e, u];
    }),
    (Xo.sum = function (n, t) {
      var e,
        r = 0,
        u = n.length,
        i = -1;
      if (1 === arguments.length)
        for (; ++i < u; ) isNaN((e = +n[i])) || (r += e);
      else for (; ++i < u; ) isNaN((e = +t.call(n, n[i], i))) || (r += e);
      return r;
    }),
    (Xo.mean = function (t, e) {
      var r,
        u = t.length,
        i = 0,
        o = -1,
        a = 0;
      if (1 === arguments.length)
        for (; ++o < u; ) n((r = t[o])) && (i += (r - i) / ++a);
      else
        for (; ++o < u; ) n((r = e.call(t, t[o], o))) && (i += (r - i) / ++a);
      return a ? i : void 0;
    }),
    (Xo.quantile = function (n, t) {
      var e = (n.length - 1) * t + 1,
        r = Math.floor(e),
        u = +n[r - 1],
        i = e - r;
      return i ? u + i * (n[r] - u) : u;
    }),
    (Xo.median = function (t, e) {
      return (
        arguments.length > 1 && (t = t.map(e)),
        (t = t.filter(n)),
        t.length ? Xo.quantile(t.sort(Xo.ascending), 0.5) : void 0
      );
    }),
    (Xo.bisector = function (n) {
      return {
        left: function (t, e, r, u) {
          for (
            arguments.length < 3 && (r = 0),
              arguments.length < 4 && (u = t.length);
            u > r;

          ) {
            var i = (r + u) >>> 1;
            n.call(t, t[i], i) < e ? (r = i + 1) : (u = i);
          }
          return r;
        },
        right: function (t, e, r, u) {
          for (
            arguments.length < 3 && (r = 0),
              arguments.length < 4 && (u = t.length);
            u > r;

          ) {
            var i = (r + u) >>> 1;
            e < n.call(t, t[i], i) ? (u = i) : (r = i + 1);
          }
          return r;
        },
      };
    });
  var ia = Xo.bisector(function (n) {
    return n;
  });
  (Xo.bisectLeft = ia.left),
    (Xo.bisect = Xo.bisectRight = ia.right),
    (Xo.shuffle = function (n) {
      for (var t, e, r = n.length; r; )
        (e = 0 | (Math.random() * r--)), (t = n[r]), (n[r] = n[e]), (n[e] = t);
      return n;
    }),
    (Xo.permute = function (n, t) {
      for (var e = t.length, r = new Array(e); e--; ) r[e] = n[t[e]];
      return r;
    }),
    (Xo.pairs = function (n) {
      for (
        var t, e = 0, r = n.length - 1, u = n[0], i = new Array(0 > r ? 0 : r);
        r > e;

      )
        i[e] = [(t = u), (u = n[++e])];
      return i;
    }),
    (Xo.zip = function () {
      if (!(u = arguments.length)) return [];
      for (var n = -1, e = Xo.min(arguments, t), r = new Array(e); ++n < e; )
        for (var u, i = -1, o = (r[n] = new Array(u)); ++i < u; )
          o[i] = arguments[i][n];
      return r;
    }),
    (Xo.transpose = function (n) {
      return Xo.zip.apply(Xo, n);
    }),
    (Xo.keys = function (n) {
      var t = [];
      for (var e in n) t.push(e);
      return t;
    }),
    (Xo.values = function (n) {
      var t = [];
      for (var e in n) t.push(n[e]);
      return t;
    }),
    (Xo.entries = function (n) {
      var t = [];
      for (var e in n) t.push({ key: e, value: n[e] });
      return t;
    }),
    (Xo.merge = function (n) {
      for (var t, e, r, u = n.length, i = -1, o = 0; ++i < u; )
        o += n[i].length;
      for (e = new Array(o); --u >= 0; )
        for (r = n[u], t = r.length; --t >= 0; ) e[--o] = r[t];
      return e;
    });
  var oa = Math.abs;
  (Xo.range = function (n, t, r) {
    if (
      (arguments.length < 3 &&
        ((r = 1), arguments.length < 2 && ((t = n), (n = 0))),
      1 / 0 === (t - n) / r)
    )
      throw new Error('infinite range');
    var u,
      i = [],
      o = e(oa(r)),
      a = -1;
    if (((n *= o), (t *= o), (r *= o), 0 > r))
      for (; (u = n + r * ++a) > t; ) i.push(u / o);
    else for (; (u = n + r * ++a) < t; ) i.push(u / o);
    return i;
  }),
    (Xo.map = function (n) {
      var t = new u();
      if (n instanceof u)
        n.forEach(function (n, e) {
          t.set(n, e);
        });
      else for (var e in n) t.set(e, n[e]);
      return t;
    }),
    r(u, {
      has: i,
      get: function (n) {
        return this[aa + n];
      },
      set: function (n, t) {
        return (this[aa + n] = t);
      },
      remove: o,
      keys: a,
      values: function () {
        var n = [];
        return (
          this.forEach(function (t, e) {
            n.push(e);
          }),
          n
        );
      },
      entries: function () {
        var n = [];
        return (
          this.forEach(function (t, e) {
            n.push({ key: t, value: e });
          }),
          n
        );
      },
      size: c,
      empty: s,
      forEach: function (n) {
        for (var t in this)
          t.charCodeAt(0) === ca && n.call(this, t.substring(1), this[t]);
      },
    });
  var aa = '\x00',
    ca = aa.charCodeAt(0);
  (Xo.nest = function () {
    function n(t, a, c) {
      if (c >= o.length) return r ? r.call(i, a) : e ? a.sort(e) : a;
      for (
        var s, l, f, h, g = -1, p = a.length, v = o[c++], d = new u();
        ++g < p;

      )
        (h = d.get((s = v((l = a[g]))))) ? h.push(l) : d.set(s, [l]);
      return (
        t
          ? ((l = t()),
            (f = function (e, r) {
              l.set(e, n(t, r, c));
            }))
          : ((l = {}),
            (f = function (e, r) {
              l[e] = n(t, r, c);
            })),
        d.forEach(f),
        l
      );
    }
    function t(n, e) {
      if (e >= o.length) return n;
      var r = [],
        u = a[e++];
      return (
        n.forEach(function (n, u) {
          r.push({ key: n, values: t(u, e) });
        }),
        u
          ? r.sort(function (n, t) {
              return u(n.key, t.key);
            })
          : r
      );
    }
    var e,
      r,
      i = {},
      o = [],
      a = [];
    return (
      (i.map = function (t, e) {
        return n(e, t, 0);
      }),
      (i.entries = function (e) {
        return t(n(Xo.map, e, 0), 0);
      }),
      (i.key = function (n) {
        return o.push(n), i;
      }),
      (i.sortKeys = function (n) {
        return (a[o.length - 1] = n), i;
      }),
      (i.sortValues = function (n) {
        return (e = n), i;
      }),
      (i.rollup = function (n) {
        return (r = n), i;
      }),
      i
    );
  }),
    (Xo.set = function (n) {
      var t = new l();
      if (n) for (var e = 0, r = n.length; r > e; ++e) t.add(n[e]);
      return t;
    }),
    r(l, {
      has: i,
      add: function (n) {
        return (this[aa + n] = !0), n;
      },
      remove: function (n) {
        return (n = aa + n), n in this && delete this[n];
      },
      values: a,
      size: c,
      empty: s,
      forEach: function (n) {
        for (var t in this)
          t.charCodeAt(0) === ca && n.call(this, t.substring(1));
      },
    }),
    (Xo.behavior = {}),
    (Xo.rebind = function (n, t) {
      for (var e, r = 1, u = arguments.length; ++r < u; )
        n[(e = arguments[r])] = f(n, t, t[e]);
      return n;
    });
  var sa = ['webkit', 'ms', 'moz', 'Moz', 'o', 'O'];
  (Xo.dispatch = function () {
    for (var n = new p(), t = -1, e = arguments.length; ++t < e; )
      n[arguments[t]] = v(n);
    return n;
  }),
    (p.prototype.on = function (n, t) {
      var e = n.indexOf('.'),
        r = '';
      if ((e >= 0 && ((r = n.substring(e + 1)), (n = n.substring(0, e))), n))
        return arguments.length < 2 ? this[n].on(r) : this[n].on(r, t);
      if (2 === arguments.length) {
        if (null == t)
          for (n in this) this.hasOwnProperty(n) && this[n].on(r, null);
        return this;
      }
    }),
    (Xo.event = null),
    (Xo.requote = function (n) {
      return n.replace(la, '\\$&');
    });
  var la = /[\\\^\$\*\+\?\|\[\]\(\)\.\{\}]/g,
    fa = {}.__proto__
      ? function (n, t) {
          n.__proto__ = t;
        }
      : function (n, t) {
          for (var e in t) n[e] = t[e];
        },
    ha = function (n, t) {
      return t.querySelector(n);
    },
    ga = function (n, t) {
      return t.querySelectorAll(n);
    },
    pa = Jo[h(Jo, 'matchesSelector')],
    va = function (n, t) {
      return pa.call(n, t);
    };
  'function' == typeof Sizzle &&
    ((ha = function (n, t) {
      return Sizzle(n, t)[0] || null;
    }),
    (ga = function (n, t) {
      return Sizzle.uniqueSort(Sizzle(n, t));
    }),
    (va = Sizzle.matchesSelector)),
    (Xo.selection = function () {
      return xa;
    });
  var da = (Xo.selection.prototype = []);
  (da.select = function (n) {
    var t,
      e,
      r,
      u,
      i = [];
    n = M(n);
    for (var o = -1, a = this.length; ++o < a; ) {
      i.push((t = [])), (t.parentNode = (r = this[o]).parentNode);
      for (var c = -1, s = r.length; ++c < s; )
        (u = r[c])
          ? (t.push((e = n.call(u, u.__data__, c, o))),
            e && '__data__' in u && (e.__data__ = u.__data__))
          : t.push(null);
    }
    return x(i);
  }),
    (da.selectAll = function (n) {
      var t,
        e,
        r = [];
      n = _(n);
      for (var u = -1, i = this.length; ++u < i; )
        for (var o = this[u], a = -1, c = o.length; ++a < c; )
          (e = o[a]) &&
            (r.push((t = Bo(n.call(e, e.__data__, a, u)))), (t.parentNode = e));
      return x(r);
    });
  var ma = {
    svg: 'http://www.w3.org/2000/svg',
    xhtml: 'http://www.w3.org/1999/xhtml',
    xlink: 'http://www.w3.org/1999/xlink',
    xml: 'http://www.w3.org/XML/1998/namespace',
    xmlns: 'http://www.w3.org/2000/xmlns/',
  };
  (Xo.ns = {
    prefix: ma,
    qualify: function (n) {
      var t = n.indexOf(':'),
        e = n;
      return (
        t >= 0 && ((e = n.substring(0, t)), (n = n.substring(t + 1))),
        ma.hasOwnProperty(e) ? { space: ma[e], local: n } : n
      );
    },
  }),
    (da.attr = function (n, t) {
      if (arguments.length < 2) {
        if ('string' == typeof n) {
          var e = this.node();
          return (
            (n = Xo.ns.qualify(n)),
            n.local ? e.getAttributeNS(n.space, n.local) : e.getAttribute(n)
          );
        }
        for (t in n) this.each(b(t, n[t]));
        return this;
      }
      return this.each(b(n, t));
    }),
    (da.classed = function (n, t) {
      if (arguments.length < 2) {
        if ('string' == typeof n) {
          var e = this.node(),
            r = (n = k(n)).length,
            u = -1;
          if ((t = e.classList)) {
            for (; ++u < r; ) if (!t.contains(n[u])) return !1;
          } else
            for (t = e.getAttribute('class'); ++u < r; )
              if (!S(n[u]).test(t)) return !1;
          return !0;
        }
        for (t in n) this.each(E(t, n[t]));
        return this;
      }
      return this.each(E(n, t));
    }),
    (da.style = function (n, t, e) {
      var r = arguments.length;
      if (3 > r) {
        if ('string' != typeof n) {
          2 > r && (t = '');
          for (e in n) this.each(C(e, n[e], t));
          return this;
        }
        if (2 > r)
          return Go.getComputedStyle(this.node(), null).getPropertyValue(n);
        e = '';
      }
      return this.each(C(n, t, e));
    }),
    (da.property = function (n, t) {
      if (arguments.length < 2) {
        if ('string' == typeof n) return this.node()[n];
        for (t in n) this.each(N(t, n[t]));
        return this;
      }
      return this.each(N(n, t));
    }),
    (da.text = function (n) {
      return arguments.length
        ? this.each(
            'function' == typeof n
              ? function () {
                  var t = n.apply(this, arguments);
                  this.textContent = null == t ? '' : t;
                }
              : null == n
              ? function () {
                  this.textContent = '';
                }
              : function () {
                  this.textContent = n;
                }
          )
        : this.node().textContent;
    }),
    (da.html = function (n) {
      return arguments.length
        ? this.each(
            'function' == typeof n
              ? function () {
                  var t = n.apply(this, arguments);
                  this.innerHTML = null == t ? '' : t;
                }
              : null == n
              ? function () {
                  this.innerHTML = '';
                }
              : function () {
                  this.innerHTML = n;
                }
          )
        : this.node().innerHTML;
    }),
    (da.append = function (n) {
      return (
        (n = L(n)),
        this.select(function () {
          return this.appendChild(n.apply(this, arguments));
        })
      );
    }),
    (da.insert = function (n, t) {
      return (
        (n = L(n)),
        (t = M(t)),
        this.select(function () {
          return this.insertBefore(
            n.apply(this, arguments),
            t.apply(this, arguments) || null
          );
        })
      );
    }),
    (da.remove = function () {
      return this.each(function () {
        var n = this.parentNode;
        n && n.removeChild(this);
      });
    }),
    (da.data = function (n, t) {
      function e(n, e) {
        var r,
          i,
          o,
          a = n.length,
          f = e.length,
          h = Math.min(a, f),
          g = new Array(f),
          p = new Array(f),
          v = new Array(a);
        if (t) {
          var d,
            m = new u(),
            y = new u(),
            x = [];
          for (r = -1; ++r < a; )
            (d = t.call((i = n[r]), i.__data__, r)),
              m.has(d) ? (v[r] = i) : m.set(d, i),
              x.push(d);
          for (r = -1; ++r < f; )
            (d = t.call(e, (o = e[r]), r)),
              (i = m.get(d))
                ? ((g[r] = i), (i.__data__ = o))
                : y.has(d) || (p[r] = z(o)),
              y.set(d, o),
              m.remove(d);
          for (r = -1; ++r < a; ) m.has(x[r]) && (v[r] = n[r]);
        } else {
          for (r = -1; ++r < h; )
            (i = n[r]),
              (o = e[r]),
              i ? ((i.__data__ = o), (g[r] = i)) : (p[r] = z(o));
          for (; f > r; ++r) p[r] = z(e[r]);
          for (; a > r; ++r) v[r] = n[r];
        }
        (p.update = g),
          (p.parentNode = g.parentNode = v.parentNode = n.parentNode),
          c.push(p),
          s.push(g),
          l.push(v);
      }
      var r,
        i,
        o = -1,
        a = this.length;
      if (!arguments.length) {
        for (n = new Array((a = (r = this[0]).length)); ++o < a; )
          (i = r[o]) && (n[o] = i.__data__);
        return n;
      }
      var c = D([]),
        s = x([]),
        l = x([]);
      if ('function' == typeof n)
        for (; ++o < a; ) e((r = this[o]), n.call(r, r.parentNode.__data__, o));
      else for (; ++o < a; ) e((r = this[o]), n);
      return (
        (s.enter = function () {
          return c;
        }),
        (s.exit = function () {
          return l;
        }),
        s
      );
    }),
    (da.datum = function (n) {
      return arguments.length
        ? this.property('__data__', n)
        : this.property('__data__');
    }),
    (da.filter = function (n) {
      var t,
        e,
        r,
        u = [];
      'function' != typeof n && (n = q(n));
      for (var i = 0, o = this.length; o > i; i++) {
        u.push((t = [])), (t.parentNode = (e = this[i]).parentNode);
        for (var a = 0, c = e.length; c > a; a++)
          (r = e[a]) && n.call(r, r.__data__, a, i) && t.push(r);
      }
      return x(u);
    }),
    (da.order = function () {
      for (var n = -1, t = this.length; ++n < t; )
        for (var e, r = this[n], u = r.length - 1, i = r[u]; --u >= 0; )
          (e = r[u]) &&
            (i && i !== e.nextSibling && i.parentNode.insertBefore(e, i),
            (i = e));
      return this;
    }),
    (da.sort = function (n) {
      n = T.apply(this, arguments);
      for (var t = -1, e = this.length; ++t < e; ) this[t].sort(n);
      return this.order();
    }),
    (da.each = function (n) {
      return R(this, function (t, e, r) {
        n.call(t, t.__data__, e, r);
      });
    }),
    (da.call = function (n) {
      var t = Bo(arguments);
      return n.apply((t[0] = this), t), this;
    }),
    (da.empty = function () {
      return !this.node();
    }),
    (da.node = function () {
      for (var n = 0, t = this.length; t > n; n++)
        for (var e = this[n], r = 0, u = e.length; u > r; r++) {
          var i = e[r];
          if (i) return i;
        }
      return null;
    }),
    (da.size = function () {
      var n = 0;
      return (
        this.each(function () {
          ++n;
        }),
        n
      );
    });
  var ya = [];
  (Xo.selection.enter = D),
    (Xo.selection.enter.prototype = ya),
    (ya.append = da.append),
    (ya.empty = da.empty),
    (ya.node = da.node),
    (ya.call = da.call),
    (ya.size = da.size),
    (ya.select = function (n) {
      for (var t, e, r, u, i, o = [], a = -1, c = this.length; ++a < c; ) {
        (r = (u = this[a]).update),
          o.push((t = [])),
          (t.parentNode = u.parentNode);
        for (var s = -1, l = u.length; ++s < l; )
          (i = u[s])
            ? (t.push((r[s] = e = n.call(u.parentNode, i.__data__, s, a))),
              (e.__data__ = i.__data__))
            : t.push(null);
      }
      return x(o);
    }),
    (ya.insert = function (n, t) {
      return arguments.length < 2 && (t = P(this)), da.insert.call(this, n, t);
    }),
    (da.transition = function () {
      for (
        var n,
          t,
          e = ks || ++Ls,
          r = [],
          u = Es || { time: Date.now(), ease: yu, delay: 0, duration: 250 },
          i = -1,
          o = this.length;
        ++i < o;

      ) {
        r.push((n = []));
        for (var a = this[i], c = -1, s = a.length; ++c < s; )
          (t = a[c]) && jo(t, c, e, u), n.push(t);
      }
      return Do(r, e);
    }),
    (da.interrupt = function () {
      return this.each(U);
    }),
    (Xo.select = function (n) {
      var t = ['string' == typeof n ? ha(n, Wo) : n];
      return (t.parentNode = Jo), x([t]);
    }),
    (Xo.selectAll = function (n) {
      var t = Bo('string' == typeof n ? ga(n, Wo) : n);
      return (t.parentNode = Jo), x([t]);
    });
  var xa = Xo.select(Jo);
  da.on = function (n, t, e) {
    var r = arguments.length;
    if (3 > r) {
      if ('string' != typeof n) {
        2 > r && (t = !1);
        for (e in n) this.each(j(e, n[e], t));
        return this;
      }
      if (2 > r) return (r = this.node()['__on' + n]) && r._;
      e = !1;
    }
    return this.each(j(n, t, e));
  };
  var Ma = Xo.map({ mouseenter: 'mouseover', mouseleave: 'mouseout' });
  Ma.forEach(function (n) {
    'on' + n in Wo && Ma.remove(n);
  });
  var _a = 'onselectstart' in Wo ? null : h(Jo.style, 'userSelect'),
    ba = 0;
  Xo.mouse = function (n) {
    return Y(n, m());
  };
  var wa = /WebKit/.test(Go.navigator.userAgent) ? -1 : 0;
  (Xo.touches = function (n, t) {
    return (
      arguments.length < 2 && (t = m().touches),
      t
        ? Bo(t).map(function (t) {
            var e = Y(n, t);
            return (e.identifier = t.identifier), e;
          })
        : []
    );
  }),
    (Xo.behavior.drag = function () {
      function n() {
        this.on('mousedown.drag', o).on('touchstart.drag', a);
      }
      function t() {
        return Xo.event.changedTouches[0].identifier;
      }
      function e(n, t) {
        return Xo.touches(n).filter(function (n) {
          return n.identifier === t;
        })[0];
      }
      function r(n, t, e, r) {
        return function () {
          function o() {
            var n = t(l, g),
              e = n[0] - v[0],
              r = n[1] - v[1];
            (d |= e | r),
              (v = n),
              f({ type: 'drag', x: n[0] + c[0], y: n[1] + c[1], dx: e, dy: r });
          }
          function a() {
            m.on(e + '.' + p, null).on(r + '.' + p, null),
              y(d && Xo.event.target === h),
              f({ type: 'dragend' });
          }
          var c,
            s = this,
            l = s.parentNode,
            f = u.of(s, arguments),
            h = Xo.event.target,
            g = n(),
            p = null == g ? 'drag' : 'drag-' + g,
            v = t(l, g),
            d = 0,
            m = Xo.select(Go)
              .on(e + '.' + p, o)
              .on(r + '.' + p, a),
            y = O();
          i
            ? ((c = i.apply(s, arguments)), (c = [c.x - v[0], c.y - v[1]]))
            : (c = [0, 0]),
            f({ type: 'dragstart' });
        };
      }
      var u = y(n, 'drag', 'dragstart', 'dragend'),
        i = null,
        o = r(g, Xo.mouse, 'mousemove', 'mouseup'),
        a = r(t, e, 'touchmove', 'touchend');
      return (
        (n.origin = function (t) {
          return arguments.length ? ((i = t), n) : i;
        }),
        Xo.rebind(n, u, 'on')
      );
    });
  var Sa = Math.PI,
    ka = 2 * Sa,
    Ea = Sa / 2,
    Aa = 1e-6,
    Ca = Aa * Aa,
    Na = Sa / 180,
    La = 180 / Sa,
    za = Math.SQRT2,
    qa = 2,
    Ta = 4;
  (Xo.interpolateZoom = function (n, t) {
    function e(n) {
      var t = n * y;
      if (m) {
        var e = B(v),
          o = (i / (qa * h)) * (e * W(za * t + v) - $(v));
        return [r + o * s, u + o * l, (i * e) / B(za * t + v)];
      }
      return [r + n * s, u + n * l, i * Math.exp(za * t)];
    }
    var r = n[0],
      u = n[1],
      i = n[2],
      o = t[0],
      a = t[1],
      c = t[2],
      s = o - r,
      l = a - u,
      f = s * s + l * l,
      h = Math.sqrt(f),
      g = (c * c - i * i + Ta * f) / (2 * i * qa * h),
      p = (c * c - i * i - Ta * f) / (2 * c * qa * h),
      v = Math.log(Math.sqrt(g * g + 1) - g),
      d = Math.log(Math.sqrt(p * p + 1) - p),
      m = d - v,
      y = (m || Math.log(c / i)) / za;
    return (e.duration = 1e3 * y), e;
  }),
    (Xo.behavior.zoom = function () {
      function n(n) {
        n.on(A, s)
          .on(Pa + '.zoom', f)
          .on(C, h)
          .on('dblclick.zoom', g)
          .on(L, l);
      }
      function t(n) {
        return [(n[0] - S.x) / S.k, (n[1] - S.y) / S.k];
      }
      function e(n) {
        return [n[0] * S.k + S.x, n[1] * S.k + S.y];
      }
      function r(n) {
        S.k = Math.max(E[0], Math.min(E[1], n));
      }
      function u(n, t) {
        (t = e(t)), (S.x += n[0] - t[0]), (S.y += n[1] - t[1]);
      }
      function i() {
        _ &&
          _.domain(
            M.range()
              .map(function (n) {
                return (n - S.x) / S.k;
              })
              .map(M.invert)
          ),
          w &&
            w.domain(
              b
                .range()
                .map(function (n) {
                  return (n - S.y) / S.k;
                })
                .map(b.invert)
            );
      }
      function o(n) {
        n({ type: 'zoomstart' });
      }
      function a(n) {
        i(), n({ type: 'zoom', scale: S.k, translate: [S.x, S.y] });
      }
      function c(n) {
        n({ type: 'zoomend' });
      }
      function s() {
        function n() {
          (l = 1), u(Xo.mouse(r), g), a(i);
        }
        function e() {
          f.on(C, Go === r ? h : null).on(N, null),
            p(l && Xo.event.target === s),
            c(i);
        }
        var r = this,
          i = z.of(r, arguments),
          s = Xo.event.target,
          l = 0,
          f = Xo.select(Go).on(C, n).on(N, e),
          g = t(Xo.mouse(r)),
          p = O();
        U.call(r), o(i);
      }
      function l() {
        function n() {
          var n = Xo.touches(g);
          return (
            (h = S.k),
            n.forEach(function (n) {
              n.identifier in v && (v[n.identifier] = t(n));
            }),
            n
          );
        }
        function e() {
          for (var t = Xo.event.changedTouches, e = 0, i = t.length; i > e; ++e)
            v[t[e].identifier] = null;
          var o = n(),
            c = Date.now();
          if (1 === o.length) {
            if (500 > c - x) {
              var s = o[0],
                l = v[s.identifier];
              r(2 * S.k), u(s, l), d(), a(p);
            }
            x = c;
          } else if (o.length > 1) {
            var s = o[0],
              f = o[1],
              h = s[0] - f[0],
              g = s[1] - f[1];
            m = h * h + g * g;
          }
        }
        function i() {
          for (
            var n, t, e, i, o = Xo.touches(g), c = 0, s = o.length;
            s > c;
            ++c, i = null
          )
            if (((e = o[c]), (i = v[e.identifier]))) {
              if (t) break;
              (n = e), (t = i);
            }
          if (i) {
            var l = (l = e[0] - n[0]) * l + (l = e[1] - n[1]) * l,
              f = m && Math.sqrt(l / m);
            (n = [(n[0] + e[0]) / 2, (n[1] + e[1]) / 2]),
              (t = [(t[0] + i[0]) / 2, (t[1] + i[1]) / 2]),
              r(f * h);
          }
          (x = null), u(n, t), a(p);
        }
        function f() {
          if (Xo.event.touches.length) {
            for (
              var t = Xo.event.changedTouches, e = 0, r = t.length;
              r > e;
              ++e
            )
              delete v[t[e].identifier];
            for (var u in v) return void n();
          }
          b.on(M, null).on(_, null), w.on(A, s).on(L, l), k(), c(p);
        }
        var h,
          g = this,
          p = z.of(g, arguments),
          v = {},
          m = 0,
          y = Xo.event.changedTouches[0].identifier,
          M = 'touchmove.zoom-' + y,
          _ = 'touchend.zoom-' + y,
          b = Xo.select(Go).on(M, i).on(_, f),
          w = Xo.select(g).on(A, null).on(L, e),
          k = O();
        U.call(g), e(), o(p);
      }
      function f() {
        var n = z.of(this, arguments);
        m ? clearTimeout(m) : (U.call(this), o(n)),
          (m = setTimeout(function () {
            (m = null), c(n);
          }, 50)),
          d();
        var e = v || Xo.mouse(this);
        p || (p = t(e)), r(Math.pow(2, 0.002 * Ra()) * S.k), u(e, p), a(n);
      }
      function h() {
        p = null;
      }
      function g() {
        var n = z.of(this, arguments),
          e = Xo.mouse(this),
          i = t(e),
          s = Math.log(S.k) / Math.LN2;
        o(n),
          r(
            Math.pow(
              2,
              Xo.event.shiftKey ? Math.ceil(s) - 1 : Math.floor(s) + 1
            )
          ),
          u(e, i),
          a(n),
          c(n);
      }
      var p,
        v,
        m,
        x,
        M,
        _,
        b,
        w,
        S = { x: 0, y: 0, k: 1 },
        k = [960, 500],
        E = Da,
        A = 'mousedown.zoom',
        C = 'mousemove.zoom',
        N = 'mouseup.zoom',
        L = 'touchstart.zoom',
        z = y(n, 'zoomstart', 'zoom', 'zoomend');
      return (
        (n.event = function (n) {
          n.each(function () {
            var n = z.of(this, arguments),
              t = S;
            ks
              ? Xo.select(this)
                  .transition()
                  .each('start.zoom', function () {
                    (S = this.__chart__ || { x: 0, y: 0, k: 1 }), o(n);
                  })
                  .tween('zoom:zoom', function () {
                    var e = k[0],
                      r = k[1],
                      u = e / 2,
                      i = r / 2,
                      o = Xo.interpolateZoom(
                        [(u - S.x) / S.k, (i - S.y) / S.k, e / S.k],
                        [(u - t.x) / t.k, (i - t.y) / t.k, e / t.k]
                      );
                    return function (t) {
                      var r = o(t),
                        c = e / r[2];
                      (this.__chart__ = S =
                        { x: u - r[0] * c, y: i - r[1] * c, k: c }),
                        a(n);
                    };
                  })
                  .each('end.zoom', function () {
                    c(n);
                  })
              : ((this.__chart__ = S), o(n), a(n), c(n));
          });
        }),
        (n.translate = function (t) {
          return arguments.length
            ? ((S = { x: +t[0], y: +t[1], k: S.k }), i(), n)
            : [S.x, S.y];
        }),
        (n.scale = function (t) {
          return arguments.length
            ? ((S = { x: S.x, y: S.y, k: +t }), i(), n)
            : S.k;
        }),
        (n.scaleExtent = function (t) {
          return arguments.length
            ? ((E = null == t ? Da : [+t[0], +t[1]]), n)
            : E;
        }),
        (n.center = function (t) {
          return arguments.length ? ((v = t && [+t[0], +t[1]]), n) : v;
        }),
        (n.size = function (t) {
          return arguments.length ? ((k = t && [+t[0], +t[1]]), n) : k;
        }),
        (n.x = function (t) {
          return arguments.length
            ? ((_ = t), (M = t.copy()), (S = { x: 0, y: 0, k: 1 }), n)
            : _;
        }),
        (n.y = function (t) {
          return arguments.length
            ? ((w = t), (b = t.copy()), (S = { x: 0, y: 0, k: 1 }), n)
            : w;
        }),
        Xo.rebind(n, z, 'on')
      );
    });
  var Ra,
    Da = [0, 1 / 0],
    Pa =
      'onwheel' in Wo
        ? ((Ra = function () {
            return -Xo.event.deltaY * (Xo.event.deltaMode ? 120 : 1);
          }),
          'wheel')
        : 'onmousewheel' in Wo
        ? ((Ra = function () {
            return Xo.event.wheelDelta;
          }),
          'mousewheel')
        : ((Ra = function () {
            return -Xo.event.detail;
          }),
          'MozMousePixelScroll');
  (G.prototype.toString = function () {
    return this.rgb() + '';
  }),
    (Xo.hsl = function (n, t, e) {
      return 1 === arguments.length
        ? n instanceof Q
          ? K(n.h, n.s, n.l)
          : dt('' + n, mt, K)
        : K(+n, +t, +e);
    });
  var Ua = (Q.prototype = new G());
  (Ua.brighter = function (n) {
    return (
      (n = Math.pow(0.7, arguments.length ? n : 1)),
      K(this.h, this.s, this.l / n)
    );
  }),
    (Ua.darker = function (n) {
      return (
        (n = Math.pow(0.7, arguments.length ? n : 1)),
        K(this.h, this.s, n * this.l)
      );
    }),
    (Ua.rgb = function () {
      return nt(this.h, this.s, this.l);
    }),
    (Xo.hcl = function (n, t, e) {
      return 1 === arguments.length
        ? n instanceof et
          ? tt(n.h, n.c, n.l)
          : n instanceof it
          ? at(n.l, n.a, n.b)
          : at((n = yt((n = Xo.rgb(n)).r, n.g, n.b)).l, n.a, n.b)
        : tt(+n, +t, +e);
    });
  var ja = (et.prototype = new G());
  (ja.brighter = function (n) {
    return tt(
      this.h,
      this.c,
      Math.min(100, this.l + Ha * (arguments.length ? n : 1))
    );
  }),
    (ja.darker = function (n) {
      return tt(
        this.h,
        this.c,
        Math.max(0, this.l - Ha * (arguments.length ? n : 1))
      );
    }),
    (ja.rgb = function () {
      return rt(this.h, this.c, this.l).rgb();
    }),
    (Xo.lab = function (n, t, e) {
      return 1 === arguments.length
        ? n instanceof it
          ? ut(n.l, n.a, n.b)
          : n instanceof et
          ? rt(n.l, n.c, n.h)
          : yt((n = Xo.rgb(n)).r, n.g, n.b)
        : ut(+n, +t, +e);
    });
  var Ha = 18,
    Fa = 0.95047,
    Oa = 1,
    Ya = 1.08883,
    Ia = (it.prototype = new G());
  (Ia.brighter = function (n) {
    return ut(
      Math.min(100, this.l + Ha * (arguments.length ? n : 1)),
      this.a,
      this.b
    );
  }),
    (Ia.darker = function (n) {
      return ut(
        Math.max(0, this.l - Ha * (arguments.length ? n : 1)),
        this.a,
        this.b
      );
    }),
    (Ia.rgb = function () {
      return ot(this.l, this.a, this.b);
    }),
    (Xo.rgb = function (n, t, e) {
      return 1 === arguments.length
        ? n instanceof pt
          ? gt(n.r, n.g, n.b)
          : dt('' + n, gt, nt)
        : gt(~~n, ~~t, ~~e);
    });
  var Za = (pt.prototype = new G());
  (Za.brighter = function (n) {
    n = Math.pow(0.7, arguments.length ? n : 1);
    var t = this.r,
      e = this.g,
      r = this.b,
      u = 30;
    return t || e || r
      ? (t && u > t && (t = u),
        e && u > e && (e = u),
        r && u > r && (r = u),
        gt(
          Math.min(255, ~~(t / n)),
          Math.min(255, ~~(e / n)),
          Math.min(255, ~~(r / n))
        ))
      : gt(u, u, u);
  }),
    (Za.darker = function (n) {
      return (
        (n = Math.pow(0.7, arguments.length ? n : 1)),
        gt(~~(n * this.r), ~~(n * this.g), ~~(n * this.b))
      );
    }),
    (Za.hsl = function () {
      return mt(this.r, this.g, this.b);
    }),
    (Za.toString = function () {
      return '#' + vt(this.r) + vt(this.g) + vt(this.b);
    });
  var Va = Xo.map({
    aliceblue: 15792383,
    antiquewhite: 16444375,
    aqua: 65535,
    aquamarine: 8388564,
    azure: 15794175,
    beige: 16119260,
    bisque: 16770244,
    black: 0,
    blanchedalmond: 16772045,
    blue: 255,
    blueviolet: 9055202,
    brown: 10824234,
    burlywood: 14596231,
    cadetblue: 6266528,
    chartreuse: 8388352,
    chocolate: 13789470,
    coral: 16744272,
    cornflowerblue: 6591981,
    cornsilk: 16775388,
    crimson: 14423100,
    cyan: 65535,
    darkblue: 139,
    darkcyan: 35723,
    darkgoldenrod: 12092939,
    darkgray: 11119017,
    darkgreen: 25600,
    darkgrey: 11119017,
    darkkhaki: 12433259,
    darkmagenta: 9109643,
    darkolivegreen: 5597999,
    darkorange: 16747520,
    darkorchid: 10040012,
    darkred: 9109504,
    darksalmon: 15308410,
    darkseagreen: 9419919,
    darkslateblue: 4734347,
    darkslategray: 3100495,
    darkslategrey: 3100495,
    darkturquoise: 52945,
    darkviolet: 9699539,
    deeppink: 16716947,
    deepskyblue: 49151,
    dimgray: 6908265,
    dimgrey: 6908265,
    dodgerblue: 2003199,
    firebrick: 11674146,
    floralwhite: 16775920,
    forestgreen: 2263842,
    fuchsia: 16711935,
    gainsboro: 14474460,
    ghostwhite: 16316671,
    gold: 16766720,
    goldenrod: 14329120,
    gray: 8421504,
    green: 32768,
    greenyellow: 11403055,
    grey: 8421504,
    honeydew: 15794160,
    hotpink: 16738740,
    indianred: 13458524,
    indigo: 4915330,
    ivory: 16777200,
    khaki: 15787660,
    lavender: 15132410,
    lavenderblush: 16773365,
    lawngreen: 8190976,
    lemonchiffon: 16775885,
    lightblue: 11393254,
    lightcoral: 15761536,
    lightcyan: 14745599,
    lightgoldenrodyellow: 16448210,
    lightgray: 13882323,
    lightgreen: 9498256,
    lightgrey: 13882323,
    lightpink: 16758465,
    lightsalmon: 16752762,
    lightseagreen: 2142890,
    lightskyblue: 8900346,
    lightslategray: 7833753,
    lightslategrey: 7833753,
    lightsteelblue: 11584734,
    lightyellow: 16777184,
    lime: 65280,
    limegreen: 3329330,
    linen: 16445670,
    magenta: 16711935,
    maroon: 8388608,
    mediumaquamarine: 6737322,
    mediumblue: 205,
    mediumorchid: 12211667,
    mediumpurple: 9662683,
    mediumseagreen: 3978097,
    mediumslateblue: 8087790,
    mediumspringgreen: 64154,
    mediumturquoise: 4772300,
    mediumvioletred: 13047173,
    midnightblue: 1644912,
    mintcream: 16121850,
    mistyrose: 16770273,
    moccasin: 16770229,
    navajowhite: 16768685,
    navy: 128,
    oldlace: 16643558,
    olive: 8421376,
    olivedrab: 7048739,
    orange: 16753920,
    orangered: 16729344,
    orchid: 14315734,
    palegoldenrod: 15657130,
    palegreen: 10025880,
    paleturquoise: 11529966,
    palevioletred: 14381203,
    papayawhip: 16773077,
    peachpuff: 16767673,
    peru: 13468991,
    pink: 16761035,
    plum: 14524637,
    powderblue: 11591910,
    purple: 8388736,
    red: 16711680,
    rosybrown: 12357519,
    royalblue: 4286945,
    saddlebrown: 9127187,
    salmon: 16416882,
    sandybrown: 16032864,
    seagreen: 3050327,
    seashell: 16774638,
    sienna: 10506797,
    silver: 12632256,
    skyblue: 8900331,
    slateblue: 6970061,
    slategray: 7372944,
    slategrey: 7372944,
    snow: 16775930,
    springgreen: 65407,
    steelblue: 4620980,
    tan: 13808780,
    teal: 32896,
    thistle: 14204888,
    tomato: 16737095,
    turquoise: 4251856,
    violet: 15631086,
    wheat: 16113331,
    white: 16777215,
    whitesmoke: 16119285,
    yellow: 16776960,
    yellowgreen: 10145074,
  });
  Va.forEach(function (n, t) {
    Va.set(n, ft(t));
  }),
    (Xo.functor = _t),
    (Xo.xhr = wt(bt)),
    (Xo.dsv = function (n, t) {
      function e(n, e, i) {
        arguments.length < 3 && ((i = e), (e = null));
        var o = St(n, t, null == e ? r : u(e), i);
        return (
          (o.row = function (n) {
            return arguments.length
              ? o.response(null == (e = n) ? r : u(n))
              : e;
          }),
          o
        );
      }
      function r(n) {
        return e.parse(n.responseText);
      }
      function u(n) {
        return function (t) {
          return e.parse(t.responseText, n);
        };
      }
      function i(t) {
        return t.map(o).join(n);
      }
      function o(n) {
        return a.test(n) ? '"' + n.replace(/\"/g, '""') + '"' : n;
      }
      var a = new RegExp('["' + n + '\n]'),
        c = n.charCodeAt(0);
      return (
        (e.parse = function (n, t) {
          var r;
          return e.parseRows(n, function (n, e) {
            if (r) return r(n, e - 1);
            var u = new Function(
              'd',
              'return {' +
                n
                  .map(function (n, t) {
                    return JSON.stringify(n) + ': d[' + t + ']';
                  })
                  .join(',') +
                '}'
            );
            r = t
              ? function (n, e) {
                  return t(u(n), e);
                }
              : u;
          });
        }),
        (e.parseRows = function (n, t) {
          function e() {
            if (l >= s) return o;
            if (u) return (u = !1), i;
            var t = l;
            if (34 === n.charCodeAt(t)) {
              for (var e = t; e++ < s; )
                if (34 === n.charCodeAt(e)) {
                  if (34 !== n.charCodeAt(e + 1)) break;
                  ++e;
                }
              l = e + 2;
              var r = n.charCodeAt(e + 1);
              return (
                13 === r
                  ? ((u = !0), 10 === n.charCodeAt(e + 2) && ++l)
                  : 10 === r && (u = !0),
                n.substring(t + 1, e).replace(/""/g, '"')
              );
            }
            for (; s > l; ) {
              var r = n.charCodeAt(l++),
                a = 1;
              if (10 === r) u = !0;
              else if (13 === r) (u = !0), 10 === n.charCodeAt(l) && (++l, ++a);
              else if (r !== c) continue;
              return n.substring(t, l - a);
            }
            return n.substring(t);
          }
          for (
            var r, u, i = {}, o = {}, a = [], s = n.length, l = 0, f = 0;
            (r = e()) !== o;

          ) {
            for (var h = []; r !== i && r !== o; ) h.push(r), (r = e());
            (!t || (h = t(h, f++))) && a.push(h);
          }
          return a;
        }),
        (e.format = function (t) {
          if (Array.isArray(t[0])) return e.formatRows(t);
          var r = new l(),
            u = [];
          return (
            t.forEach(function (n) {
              for (var t in n) r.has(t) || u.push(r.add(t));
            }),
            [u.map(o).join(n)]
              .concat(
                t.map(function (t) {
                  return u
                    .map(function (n) {
                      return o(t[n]);
                    })
                    .join(n);
                })
              )
              .join('\n')
          );
        }),
        (e.formatRows = function (n) {
          return n.map(i).join('\n');
        }),
        e
      );
    }),
    (Xo.csv = Xo.dsv(',', 'text/csv')),
    (Xo.tsv = Xo.dsv('	', 'text/tab-separated-values'));
  var Xa,
    $a,
    Ba,
    Wa,
    Ja,
    Ga =
      Go[h(Go, 'requestAnimationFrame')] ||
      function (n) {
        setTimeout(n, 17);
      };
  (Xo.timer = function (n, t, e) {
    var r = arguments.length;
    2 > r && (t = 0), 3 > r && (e = Date.now());
    var u = e + t,
      i = { c: n, t: u, f: !1, n: null };
    $a ? ($a.n = i) : (Xa = i),
      ($a = i),
      Ba || ((Wa = clearTimeout(Wa)), (Ba = 1), Ga(Et));
  }),
    (Xo.timer.flush = function () {
      At(), Ct();
    }),
    (Xo.round = function (n, t) {
      return t ? Math.round(n * (t = Math.pow(10, t))) / t : Math.round(n);
    });
  var Ka = [
    'y',
    'z',
    'a',
    'f',
    'p',
    'n',
    '\xb5',
    'm',
    '',
    'k',
    'M',
    'G',
    'T',
    'P',
    'E',
    'Z',
    'Y',
  ].map(Lt);
  Xo.formatPrefix = function (n, t) {
    var e = 0;
    return (
      n &&
        (0 > n && (n *= -1),
        t && (n = Xo.round(n, Nt(n, t))),
        (e = 1 + Math.floor(1e-12 + Math.log(n) / Math.LN10)),
        (e = Math.max(
          -24,
          Math.min(24, 3 * Math.floor((0 >= e ? e + 1 : e - 1) / 3))
        ))),
      Ka[8 + e / 3]
    );
  };
  var Qa =
      /(?:([^{])?([<>=^]))?([+\- ])?([$#])?(0)?(\d+)?(,)?(\.-?\d+)?([a-z%])?/i,
    nc = Xo.map({
      b: function (n) {
        return n.toString(2);
      },
      c: function (n) {
        return String.fromCharCode(n);
      },
      o: function (n) {
        return n.toString(8);
      },
      x: function (n) {
        return n.toString(16);
      },
      X: function (n) {
        return n.toString(16).toUpperCase();
      },
      g: function (n, t) {
        return n.toPrecision(t);
      },
      e: function (n, t) {
        return n.toExponential(t);
      },
      f: function (n, t) {
        return n.toFixed(t);
      },
      r: function (n, t) {
        return (n = Xo.round(n, Nt(n, t))).toFixed(
          Math.max(0, Math.min(20, Nt(n * (1 + 1e-15), t)))
        );
      },
    }),
    tc = (Xo.time = {}),
    ec = Date;
  Tt.prototype = {
    getDate: function () {
      return this._.getUTCDate();
    },
    getDay: function () {
      return this._.getUTCDay();
    },
    getFullYear: function () {
      return this._.getUTCFullYear();
    },
    getHours: function () {
      return this._.getUTCHours();
    },
    getMilliseconds: function () {
      return this._.getUTCMilliseconds();
    },
    getMinutes: function () {
      return this._.getUTCMinutes();
    },
    getMonth: function () {
      return this._.getUTCMonth();
    },
    getSeconds: function () {
      return this._.getUTCSeconds();
    },
    getTime: function () {
      return this._.getTime();
    },
    getTimezoneOffset: function () {
      return 0;
    },
    valueOf: function () {
      return this._.valueOf();
    },
    setDate: function () {
      rc.setUTCDate.apply(this._, arguments);
    },
    setDay: function () {
      rc.setUTCDay.apply(this._, arguments);
    },
    setFullYear: function () {
      rc.setUTCFullYear.apply(this._, arguments);
    },
    setHours: function () {
      rc.setUTCHours.apply(this._, arguments);
    },
    setMilliseconds: function () {
      rc.setUTCMilliseconds.apply(this._, arguments);
    },
    setMinutes: function () {
      rc.setUTCMinutes.apply(this._, arguments);
    },
    setMonth: function () {
      rc.setUTCMonth.apply(this._, arguments);
    },
    setSeconds: function () {
      rc.setUTCSeconds.apply(this._, arguments);
    },
    setTime: function () {
      rc.setTime.apply(this._, arguments);
    },
  };
  var rc = Date.prototype;
  (tc.year = Rt(
    function (n) {
      return (n = tc.day(n)), n.setMonth(0, 1), n;
    },
    function (n, t) {
      n.setFullYear(n.getFullYear() + t);
    },
    function (n) {
      return n.getFullYear();
    }
  )),
    (tc.years = tc.year.range),
    (tc.years.utc = tc.year.utc.range),
    (tc.day = Rt(
      function (n) {
        var t = new ec(2e3, 0);
        return t.setFullYear(n.getFullYear(), n.getMonth(), n.getDate()), t;
      },
      function (n, t) {
        n.setDate(n.getDate() + t);
      },
      function (n) {
        return n.getDate() - 1;
      }
    )),
    (tc.days = tc.day.range),
    (tc.days.utc = tc.day.utc.range),
    (tc.dayOfYear = function (n) {
      var t = tc.year(n);
      return Math.floor(
        (n - t - 6e4 * (n.getTimezoneOffset() - t.getTimezoneOffset())) / 864e5
      );
    }),
    [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ].forEach(function (n, t) {
      t = 7 - t;
      var e = (tc[n] = Rt(
        function (n) {
          return (
            (n = tc.day(n)).setDate(n.getDate() - ((n.getDay() + t) % 7)), n
          );
        },
        function (n, t) {
          n.setDate(n.getDate() + 7 * Math.floor(t));
        },
        function (n) {
          var e = tc.year(n).getDay();
          return Math.floor((tc.dayOfYear(n) + ((e + t) % 7)) / 7) - (e !== t);
        }
      ));
      (tc[n + 's'] = e.range),
        (tc[n + 's'].utc = e.utc.range),
        (tc[n + 'OfYear'] = function (n) {
          var e = tc.year(n).getDay();
          return Math.floor((tc.dayOfYear(n) + ((e + t) % 7)) / 7);
        });
    }),
    (tc.week = tc.sunday),
    (tc.weeks = tc.sunday.range),
    (tc.weeks.utc = tc.sunday.utc.range),
    (tc.weekOfYear = tc.sundayOfYear);
  var uc = { '-': '', _: ' ', 0: '0' },
    ic = /^\s*\d+/,
    oc = /^%/;
  Xo.locale = function (n) {
    return { numberFormat: zt(n), timeFormat: Pt(n) };
  };
  var ac = Xo.locale({
    decimal: '.',
    thousands: ',',
    grouping: [3],
    currency: ['$', ''],
    dateTime: '%a %b %e %X %Y',
    date: '%m/%d/%Y',
    time: '%H:%M:%S',
    periods: ['AM', 'PM'],
    days: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    shortMonths: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  });
  (Xo.format = ac.numberFormat),
    (Xo.geo = {}),
    (re.prototype = {
      s: 0,
      t: 0,
      add: function (n) {
        ue(n, this.t, cc),
          ue(cc.s, this.s, this),
          this.s ? (this.t += cc.t) : (this.s = cc.t);
      },
      reset: function () {
        this.s = this.t = 0;
      },
      valueOf: function () {
        return this.s;
      },
    });
  var cc = new re();
  Xo.geo.stream = function (n, t) {
    n && sc.hasOwnProperty(n.type) ? sc[n.type](n, t) : ie(n, t);
  };
  var sc = {
      Feature: function (n, t) {
        ie(n.geometry, t);
      },
      FeatureCollection: function (n, t) {
        for (var e = n.features, r = -1, u = e.length; ++r < u; )
          ie(e[r].geometry, t);
      },
    },
    lc = {
      Sphere: function (n, t) {
        t.sphere();
      },
      Point: function (n, t) {
        (n = n.coordinates), t.point(n[0], n[1], n[2]);
      },
      MultiPoint: function (n, t) {
        for (var e = n.coordinates, r = -1, u = e.length; ++r < u; )
          (n = e[r]), t.point(n[0], n[1], n[2]);
      },
      LineString: function (n, t) {
        oe(n.coordinates, t, 0);
      },
      MultiLineString: function (n, t) {
        for (var e = n.coordinates, r = -1, u = e.length; ++r < u; )
          oe(e[r], t, 0);
      },
      Polygon: function (n, t) {
        ae(n.coordinates, t);
      },
      MultiPolygon: function (n, t) {
        for (var e = n.coordinates, r = -1, u = e.length; ++r < u; )
          ae(e[r], t);
      },
      GeometryCollection: function (n, t) {
        for (var e = n.geometries, r = -1, u = e.length; ++r < u; ) ie(e[r], t);
      },
    };
  Xo.geo.area = function (n) {
    return (fc = 0), Xo.geo.stream(n, gc), fc;
  };
  var fc,
    hc = new re(),
    gc = {
      sphere: function () {
        fc += 4 * Sa;
      },
      point: g,
      lineStart: g,
      lineEnd: g,
      polygonStart: function () {
        hc.reset(), (gc.lineStart = ce);
      },
      polygonEnd: function () {
        var n = 2 * hc;
        (fc += 0 > n ? 4 * Sa + n : n),
          (gc.lineStart = gc.lineEnd = gc.point = g);
      },
    };
  (Xo.geo.bounds = (function () {
    function n(n, t) {
      x.push((M = [(l = n), (h = n)])), f > t && (f = t), t > g && (g = t);
    }
    function t(t, e) {
      var r = se([t * Na, e * Na]);
      if (m) {
        var u = fe(m, r),
          i = [u[1], -u[0], 0],
          o = fe(i, u);
        pe(o), (o = ve(o));
        var c = t - p,
          s = c > 0 ? 1 : -1,
          v = o[0] * La * s,
          d = oa(c) > 180;
        if (d ^ (v > s * p && s * t > v)) {
          var y = o[1] * La;
          y > g && (g = y);
        } else if (
          ((v = ((v + 360) % 360) - 180), d ^ (v > s * p && s * t > v))
        ) {
          var y = -o[1] * La;
          f > y && (f = y);
        } else f > e && (f = e), e > g && (g = e);
        d
          ? p > t
            ? a(l, t) > a(l, h) && (h = t)
            : a(t, h) > a(l, h) && (l = t)
          : h >= l
          ? (l > t && (l = t), t > h && (h = t))
          : t > p
          ? a(l, t) > a(l, h) && (h = t)
          : a(t, h) > a(l, h) && (l = t);
      } else n(t, e);
      (m = r), (p = t);
    }
    function e() {
      _.point = t;
    }
    function r() {
      (M[0] = l), (M[1] = h), (_.point = n), (m = null);
    }
    function u(n, e) {
      if (m) {
        var r = n - p;
        y += oa(r) > 180 ? r + (r > 0 ? 360 : -360) : r;
      } else (v = n), (d = e);
      gc.point(n, e), t(n, e);
    }
    function i() {
      gc.lineStart();
    }
    function o() {
      u(v, d),
        gc.lineEnd(),
        oa(y) > Aa && (l = -(h = 180)),
        (M[0] = l),
        (M[1] = h),
        (m = null);
    }
    function a(n, t) {
      return (t -= n) < 0 ? t + 360 : t;
    }
    function c(n, t) {
      return n[0] - t[0];
    }
    function s(n, t) {
      return t[0] <= t[1] ? t[0] <= n && n <= t[1] : n < t[0] || t[1] < n;
    }
    var l,
      f,
      h,
      g,
      p,
      v,
      d,
      m,
      y,
      x,
      M,
      _ = {
        point: n,
        lineStart: e,
        lineEnd: r,
        polygonStart: function () {
          (_.point = u),
            (_.lineStart = i),
            (_.lineEnd = o),
            (y = 0),
            gc.polygonStart();
        },
        polygonEnd: function () {
          gc.polygonEnd(),
            (_.point = n),
            (_.lineStart = e),
            (_.lineEnd = r),
            0 > hc
              ? ((l = -(h = 180)), (f = -(g = 90)))
              : y > Aa
              ? (g = 90)
              : -Aa > y && (f = -90),
            (M[0] = l),
            (M[1] = h);
        },
      };
    return function (n) {
      (g = h = -(l = f = 1 / 0)), (x = []), Xo.geo.stream(n, _);
      var t = x.length;
      if (t) {
        x.sort(c);
        for (var e, r = 1, u = x[0], i = [u]; t > r; ++r)
          (e = x[r]),
            s(e[0], u) || s(e[1], u)
              ? (a(u[0], e[1]) > a(u[0], u[1]) && (u[1] = e[1]),
                a(e[0], u[1]) > a(u[0], u[1]) && (u[0] = e[0]))
              : i.push((u = e));
        for (
          var o, e, p = -1 / 0, t = i.length - 1, r = 0, u = i[t];
          t >= r;
          u = e, ++r
        )
          (e = i[r]),
            (o = a(u[1], e[0])) > p && ((p = o), (l = e[0]), (h = u[1]));
      }
      return (
        (x = M = null),
        1 / 0 === l || 1 / 0 === f
          ? [
              [0 / 0, 0 / 0],
              [0 / 0, 0 / 0],
            ]
          : [
              [l, f],
              [h, g],
            ]
      );
    };
  })()),
    (Xo.geo.centroid = function (n) {
      (pc = vc = dc = mc = yc = xc = Mc = _c = bc = wc = Sc = 0),
        Xo.geo.stream(n, kc);
      var t = bc,
        e = wc,
        r = Sc,
        u = t * t + e * e + r * r;
      return Ca > u &&
        ((t = xc),
        (e = Mc),
        (r = _c),
        Aa > vc && ((t = dc), (e = mc), (r = yc)),
        (u = t * t + e * e + r * r),
        Ca > u)
        ? [0 / 0, 0 / 0]
        : [Math.atan2(e, t) * La, X(r / Math.sqrt(u)) * La];
    });
  var pc,
    vc,
    dc,
    mc,
    yc,
    xc,
    Mc,
    _c,
    bc,
    wc,
    Sc,
    kc = {
      sphere: g,
      point: me,
      lineStart: xe,
      lineEnd: Me,
      polygonStart: function () {
        kc.lineStart = _e;
      },
      polygonEnd: function () {
        kc.lineStart = xe;
      },
    },
    Ec = Ee(be, ze, Te, [-Sa, -Sa / 2]),
    Ac = 1e9;
  (Xo.geo.clipExtent = function () {
    var n,
      t,
      e,
      r,
      u,
      i,
      o = {
        stream: function (n) {
          return u && (u.valid = !1), (u = i(n)), (u.valid = !0), u;
        },
        extent: function (a) {
          return arguments.length
            ? ((i = Pe(
                (n = +a[0][0]),
                (t = +a[0][1]),
                (e = +a[1][0]),
                (r = +a[1][1])
              )),
              u && ((u.valid = !1), (u = null)),
              o)
            : [
                [n, t],
                [e, r],
              ];
        },
      };
    return o.extent([
      [0, 0],
      [960, 500],
    ]);
  }),
    ((Xo.geo.conicEqualArea = function () {
      return je(He);
    }).raw = He),
    (Xo.geo.albers = function () {
      return Xo.geo
        .conicEqualArea()
        .rotate([96, 0])
        .center([-0.6, 38.7])
        .parallels([29.5, 45.5])
        .scale(1070);
    }),
    (Xo.geo.albersUsa = function () {
      function n(n) {
        var i = n[0],
          o = n[1];
        return (t = null), e(i, o), t || (r(i, o), t) || u(i, o), t;
      }
      var t,
        e,
        r,
        u,
        i = Xo.geo.albers(),
        o = Xo.geo
          .conicEqualArea()
          .rotate([154, 0])
          .center([-2, 58.5])
          .parallels([55, 65]),
        a = Xo.geo
          .conicEqualArea()
          .rotate([157, 0])
          .center([-3, 19.9])
          .parallels([8, 18]),
        c = {
          point: function (n, e) {
            t = [n, e];
          },
        };
      return (
        (n.invert = function (n) {
          var t = i.scale(),
            e = i.translate(),
            r = (n[0] - e[0]) / t,
            u = (n[1] - e[1]) / t;
          return (
            u >= 0.12 && 0.234 > u && r >= -0.425 && -0.214 > r
              ? o
              : u >= 0.166 && 0.234 > u && r >= -0.214 && -0.115 > r
              ? a
              : i
          ).invert(n);
        }),
        (n.stream = function (n) {
          var t = i.stream(n),
            e = o.stream(n),
            r = a.stream(n);
          return {
            point: function (n, u) {
              t.point(n, u), e.point(n, u), r.point(n, u);
            },
            sphere: function () {
              t.sphere(), e.sphere(), r.sphere();
            },
            lineStart: function () {
              t.lineStart(), e.lineStart(), r.lineStart();
            },
            lineEnd: function () {
              t.lineEnd(), e.lineEnd(), r.lineEnd();
            },
            polygonStart: function () {
              t.polygonStart(), e.polygonStart(), r.polygonStart();
            },
            polygonEnd: function () {
              t.polygonEnd(), e.polygonEnd(), r.polygonEnd();
            },
          };
        }),
        (n.precision = function (t) {
          return arguments.length
            ? (i.precision(t), o.precision(t), a.precision(t), n)
            : i.precision();
        }),
        (n.scale = function (t) {
          return arguments.length
            ? (i.scale(t),
              o.scale(0.35 * t),
              a.scale(t),
              n.translate(i.translate()))
            : i.scale();
        }),
        (n.translate = function (t) {
          if (!arguments.length) return i.translate();
          var s = i.scale(),
            l = +t[0],
            f = +t[1];
          return (
            (e = i
              .translate(t)
              .clipExtent([
                [l - 0.455 * s, f - 0.238 * s],
                [l + 0.455 * s, f + 0.238 * s],
              ])
              .stream(c).point),
            (r = o
              .translate([l - 0.307 * s, f + 0.201 * s])
              .clipExtent([
                [l - 0.425 * s + Aa, f + 0.12 * s + Aa],
                [l - 0.214 * s - Aa, f + 0.234 * s - Aa],
              ])
              .stream(c).point),
            (u = a
              .translate([l - 0.205 * s, f + 0.212 * s])
              .clipExtent([
                [l - 0.214 * s + Aa, f + 0.166 * s + Aa],
                [l - 0.115 * s - Aa, f + 0.234 * s - Aa],
              ])
              .stream(c).point),
            n
          );
        }),
        n.scale(1070)
      );
    });
  var Cc,
    Nc,
    Lc,
    zc,
    qc,
    Tc,
    Rc = {
      point: g,
      lineStart: g,
      lineEnd: g,
      polygonStart: function () {
        (Nc = 0), (Rc.lineStart = Fe);
      },
      polygonEnd: function () {
        (Rc.lineStart = Rc.lineEnd = Rc.point = g), (Cc += oa(Nc / 2));
      },
    },
    Dc = {
      point: Oe,
      lineStart: g,
      lineEnd: g,
      polygonStart: g,
      polygonEnd: g,
    },
    Pc = {
      point: Ze,
      lineStart: Ve,
      lineEnd: Xe,
      polygonStart: function () {
        Pc.lineStart = $e;
      },
      polygonEnd: function () {
        (Pc.point = Ze), (Pc.lineStart = Ve), (Pc.lineEnd = Xe);
      },
    };
  (Xo.geo.path = function () {
    function n(n) {
      return (
        n &&
          ('function' == typeof a && i.pointRadius(+a.apply(this, arguments)),
          (o && o.valid) || (o = u(i)),
          Xo.geo.stream(n, o)),
        i.result()
      );
    }
    function t() {
      return (o = null), n;
    }
    var e,
      r,
      u,
      i,
      o,
      a = 4.5;
    return (
      (n.area = function (n) {
        return (Cc = 0), Xo.geo.stream(n, u(Rc)), Cc;
      }),
      (n.centroid = function (n) {
        return (
          (dc = mc = yc = xc = Mc = _c = bc = wc = Sc = 0),
          Xo.geo.stream(n, u(Pc)),
          Sc
            ? [bc / Sc, wc / Sc]
            : _c
            ? [xc / _c, Mc / _c]
            : yc
            ? [dc / yc, mc / yc]
            : [0 / 0, 0 / 0]
        );
      }),
      (n.bounds = function (n) {
        return (
          (qc = Tc = -(Lc = zc = 1 / 0)),
          Xo.geo.stream(n, u(Dc)),
          [
            [Lc, zc],
            [qc, Tc],
          ]
        );
      }),
      (n.projection = function (n) {
        return arguments.length
          ? ((u = (e = n) ? n.stream || Je(n) : bt), t())
          : e;
      }),
      (n.context = function (n) {
        return arguments.length
          ? ((i = null == (r = n) ? new Ye() : new Be(n)),
            'function' != typeof a && i.pointRadius(a),
            t())
          : r;
      }),
      (n.pointRadius = function (t) {
        return arguments.length
          ? ((a = 'function' == typeof t ? t : (i.pointRadius(+t), +t)), n)
          : a;
      }),
      n.projection(Xo.geo.albersUsa()).context(null)
    );
  }),
    (Xo.geo.transform = function (n) {
      return {
        stream: function (t) {
          var e = new Ge(t);
          for (var r in n) e[r] = n[r];
          return e;
        },
      };
    }),
    (Ge.prototype = {
      point: function (n, t) {
        this.stream.point(n, t);
      },
      sphere: function () {
        this.stream.sphere();
      },
      lineStart: function () {
        this.stream.lineStart();
      },
      lineEnd: function () {
        this.stream.lineEnd();
      },
      polygonStart: function () {
        this.stream.polygonStart();
      },
      polygonEnd: function () {
        this.stream.polygonEnd();
      },
    }),
    (Xo.geo.projection = Qe),
    (Xo.geo.projectionMutator = nr),
    ((Xo.geo.equirectangular = function () {
      return Qe(er);
    }).raw = er.invert =
      er),
    (Xo.geo.rotation = function (n) {
      function t(t) {
        return (t = n(t[0] * Na, t[1] * Na)), (t[0] *= La), (t[1] *= La), t;
      }
      return (
        (n = ur((n[0] % 360) * Na, n[1] * Na, n.length > 2 ? n[2] * Na : 0)),
        (t.invert = function (t) {
          return (
            (t = n.invert(t[0] * Na, t[1] * Na)), (t[0] *= La), (t[1] *= La), t
          );
        }),
        t
      );
    }),
    (rr.invert = er),
    (Xo.geo.circle = function () {
      function n() {
        var n = 'function' == typeof r ? r.apply(this, arguments) : r,
          t = ur(-n[0] * Na, -n[1] * Na, 0).invert,
          u = [];
        return (
          e(null, null, 1, {
            point: function (n, e) {
              u.push((n = t(n, e))), (n[0] *= La), (n[1] *= La);
            },
          }),
          { type: 'Polygon', coordinates: [u] }
        );
      }
      var t,
        e,
        r = [0, 0],
        u = 6;
      return (
        (n.origin = function (t) {
          return arguments.length ? ((r = t), n) : r;
        }),
        (n.angle = function (r) {
          return arguments.length ? ((e = cr((t = +r) * Na, u * Na)), n) : t;
        }),
        (n.precision = function (r) {
          return arguments.length ? ((e = cr(t * Na, (u = +r) * Na)), n) : u;
        }),
        n.angle(90)
      );
    }),
    (Xo.geo.distance = function (n, t) {
      var e,
        r = (t[0] - n[0]) * Na,
        u = n[1] * Na,
        i = t[1] * Na,
        o = Math.sin(r),
        a = Math.cos(r),
        c = Math.sin(u),
        s = Math.cos(u),
        l = Math.sin(i),
        f = Math.cos(i);
      return Math.atan2(
        Math.sqrt((e = f * o) * e + (e = s * l - c * f * a) * e),
        c * l + s * f * a
      );
    }),
    (Xo.geo.graticule = function () {
      function n() {
        return { type: 'MultiLineString', coordinates: t() };
      }
      function t() {
        return Xo.range(Math.ceil(i / d) * d, u, d)
          .map(h)
          .concat(Xo.range(Math.ceil(s / m) * m, c, m).map(g))
          .concat(
            Xo.range(Math.ceil(r / p) * p, e, p)
              .filter(function (n) {
                return oa(n % d) > Aa;
              })
              .map(l)
          )
          .concat(
            Xo.range(Math.ceil(a / v) * v, o, v)
              .filter(function (n) {
                return oa(n % m) > Aa;
              })
              .map(f)
          );
      }
      var e,
        r,
        u,
        i,
        o,
        a,
        c,
        s,
        l,
        f,
        h,
        g,
        p = 10,
        v = p,
        d = 90,
        m = 360,
        y = 2.5;
      return (
        (n.lines = function () {
          return t().map(function (n) {
            return { type: 'LineString', coordinates: n };
          });
        }),
        (n.outline = function () {
          return {
            type: 'Polygon',
            coordinates: [
              h(i).concat(
                g(c).slice(1),
                h(u).reverse().slice(1),
                g(s).reverse().slice(1)
              ),
            ],
          };
        }),
        (n.extent = function (t) {
          return arguments.length
            ? n.majorExtent(t).minorExtent(t)
            : n.minorExtent();
        }),
        (n.majorExtent = function (t) {
          return arguments.length
            ? ((i = +t[0][0]),
              (u = +t[1][0]),
              (s = +t[0][1]),
              (c = +t[1][1]),
              i > u && ((t = i), (i = u), (u = t)),
              s > c && ((t = s), (s = c), (c = t)),
              n.precision(y))
            : [
                [i, s],
                [u, c],
              ];
        }),
        (n.minorExtent = function (t) {
          return arguments.length
            ? ((r = +t[0][0]),
              (e = +t[1][0]),
              (a = +t[0][1]),
              (o = +t[1][1]),
              r > e && ((t = r), (r = e), (e = t)),
              a > o && ((t = a), (a = o), (o = t)),
              n.precision(y))
            : [
                [r, a],
                [e, o],
              ];
        }),
        (n.step = function (t) {
          return arguments.length ? n.majorStep(t).minorStep(t) : n.minorStep();
        }),
        (n.majorStep = function (t) {
          return arguments.length ? ((d = +t[0]), (m = +t[1]), n) : [d, m];
        }),
        (n.minorStep = function (t) {
          return arguments.length ? ((p = +t[0]), (v = +t[1]), n) : [p, v];
        }),
        (n.precision = function (t) {
          return arguments.length
            ? ((y = +t),
              (l = lr(a, o, 90)),
              (f = fr(r, e, y)),
              (h = lr(s, c, 90)),
              (g = fr(i, u, y)),
              n)
            : y;
        }),
        n
          .majorExtent([
            [-180, -90 + Aa],
            [180, 90 - Aa],
          ])
          .minorExtent([
            [-180, -80 - Aa],
            [180, 80 + Aa],
          ])
      );
    }),
    (Xo.geo.greatArc = function () {
      function n() {
        return {
          type: 'LineString',
          coordinates: [
            t || r.apply(this, arguments),
            e || u.apply(this, arguments),
          ],
        };
      }
      var t,
        e,
        r = hr,
        u = gr;
      return (
        (n.distance = function () {
          return Xo.geo.distance(
            t || r.apply(this, arguments),
            e || u.apply(this, arguments)
          );
        }),
        (n.source = function (e) {
          return arguments.length
            ? ((r = e), (t = 'function' == typeof e ? null : e), n)
            : r;
        }),
        (n.target = function (t) {
          return arguments.length
            ? ((u = t), (e = 'function' == typeof t ? null : t), n)
            : u;
        }),
        (n.precision = function () {
          return arguments.length ? n : 0;
        }),
        n
      );
    }),
    (Xo.geo.interpolate = function (n, t) {
      return pr(n[0] * Na, n[1] * Na, t[0] * Na, t[1] * Na);
    }),
    (Xo.geo.length = function (n) {
      return (Uc = 0), Xo.geo.stream(n, jc), Uc;
    });
  var Uc,
    jc = {
      sphere: g,
      point: g,
      lineStart: vr,
      lineEnd: g,
      polygonStart: g,
      polygonEnd: g,
    },
    Hc = dr(
      function (n) {
        return Math.sqrt(2 / (1 + n));
      },
      function (n) {
        return 2 * Math.asin(n / 2);
      }
    );
  (Xo.geo.azimuthalEqualArea = function () {
    return Qe(Hc);
  }).raw = Hc;
  var Fc = dr(function (n) {
    var t = Math.acos(n);
    return t && t / Math.sin(t);
  }, bt);
  ((Xo.geo.azimuthalEquidistant = function () {
    return Qe(Fc);
  }).raw = Fc),
    ((Xo.geo.conicConformal = function () {
      return je(mr);
    }).raw = mr),
    ((Xo.geo.conicEquidistant = function () {
      return je(yr);
    }).raw = yr);
  var Oc = dr(function (n) {
    return 1 / n;
  }, Math.atan);
  ((Xo.geo.gnomonic = function () {
    return Qe(Oc);
  }).raw = Oc),
    (xr.invert = function (n, t) {
      return [n, 2 * Math.atan(Math.exp(t)) - Ea];
    }),
    ((Xo.geo.mercator = function () {
      return Mr(xr);
    }).raw = xr);
  var Yc = dr(function () {
    return 1;
  }, Math.asin);
  (Xo.geo.orthographic = function () {
    return Qe(Yc);
  }).raw = Yc;
  var Ic = dr(
    function (n) {
      return 1 / (1 + n);
    },
    function (n) {
      return 2 * Math.atan(n);
    }
  );
  ((Xo.geo.stereographic = function () {
    return Qe(Ic);
  }).raw = Ic),
    (_r.invert = function (n, t) {
      return [-t, 2 * Math.atan(Math.exp(n)) - Ea];
    }),
    ((Xo.geo.transverseMercator = function () {
      var n = Mr(_r),
        t = n.center,
        e = n.rotate;
      return (
        (n.center = function (n) {
          return n ? t([-n[1], n[0]]) : ((n = t()), [-n[1], n[0]]);
        }),
        (n.rotate = function (n) {
          return n
            ? e([n[0], n[1], n.length > 2 ? n[2] + 90 : 90])
            : ((n = e()), [n[0], n[1], n[2] - 90]);
        }),
        n.rotate([0, 0])
      );
    }).raw = _r),
    (Xo.geom = {}),
    (Xo.geom.hull = function (n) {
      function t(n) {
        if (n.length < 3) return [];
        var t,
          u = _t(e),
          i = _t(r),
          o = n.length,
          a = [],
          c = [];
        for (t = 0; o > t; t++)
          a.push([+u.call(this, n[t], t), +i.call(this, n[t], t), t]);
        for (a.sort(kr), t = 0; o > t; t++) c.push([a[t][0], -a[t][1]]);
        var s = Sr(a),
          l = Sr(c),
          f = l[0] === s[0],
          h = l[l.length - 1] === s[s.length - 1],
          g = [];
        for (t = s.length - 1; t >= 0; --t) g.push(n[a[s[t]][2]]);
        for (t = +f; t < l.length - h; ++t) g.push(n[a[l[t]][2]]);
        return g;
      }
      var e = br,
        r = wr;
      return arguments.length
        ? t(n)
        : ((t.x = function (n) {
            return arguments.length ? ((e = n), t) : e;
          }),
          (t.y = function (n) {
            return arguments.length ? ((r = n), t) : r;
          }),
          t);
    }),
    (Xo.geom.polygon = function (n) {
      return fa(n, Zc), n;
    });
  var Zc = (Xo.geom.polygon.prototype = []);
  (Zc.area = function () {
    for (var n, t = -1, e = this.length, r = this[e - 1], u = 0; ++t < e; )
      (n = r), (r = this[t]), (u += n[1] * r[0] - n[0] * r[1]);
    return 0.5 * u;
  }),
    (Zc.centroid = function (n) {
      var t,
        e,
        r = -1,
        u = this.length,
        i = 0,
        o = 0,
        a = this[u - 1];
      for (arguments.length || (n = -1 / (6 * this.area())); ++r < u; )
        (t = a),
          (a = this[r]),
          (e = t[0] * a[1] - a[0] * t[1]),
          (i += (t[0] + a[0]) * e),
          (o += (t[1] + a[1]) * e);
      return [i * n, o * n];
    }),
    (Zc.clip = function (n) {
      for (
        var t,
          e,
          r,
          u,
          i,
          o,
          a = Cr(n),
          c = -1,
          s = this.length - Cr(this),
          l = this[s - 1];
        ++c < s;

      ) {
        for (
          t = n.slice(),
            n.length = 0,
            u = this[c],
            i = t[(r = t.length - a) - 1],
            e = -1;
          ++e < r;

        )
          (o = t[e]),
            Er(o, l, u)
              ? (Er(i, l, u) || n.push(Ar(i, o, l, u)), n.push(o))
              : Er(i, l, u) && n.push(Ar(i, o, l, u)),
            (i = o);
        a && n.push(n[0]), (l = u);
      }
      return n;
    });
  var Vc,
    Xc,
    $c,
    Bc,
    Wc,
    Jc = [],
    Gc = [];
  (Pr.prototype.prepare = function () {
    for (var n, t = this.edges, e = t.length; e--; )
      (n = t[e].edge), (n.b && n.a) || t.splice(e, 1);
    return t.sort(jr), t.length;
  }),
    (Br.prototype = {
      start: function () {
        return this.edge.l === this.site ? this.edge.a : this.edge.b;
      },
      end: function () {
        return this.edge.l === this.site ? this.edge.b : this.edge.a;
      },
    }),
    (Wr.prototype = {
      insert: function (n, t) {
        var e, r, u;
        if (n) {
          if (((t.P = n), (t.N = n.N), n.N && (n.N.P = t), (n.N = t), n.R)) {
            for (n = n.R; n.L; ) n = n.L;
            n.L = t;
          } else n.R = t;
          e = n;
        } else
          this._
            ? ((n = Qr(this._)),
              (t.P = null),
              (t.N = n),
              (n.P = n.L = t),
              (e = n))
            : ((t.P = t.N = null), (this._ = t), (e = null));
        for (t.L = t.R = null, t.U = e, t.C = !0, n = t; e && e.C; )
          (r = e.U),
            e === r.L
              ? ((u = r.R),
                u && u.C
                  ? ((e.C = u.C = !1), (r.C = !0), (n = r))
                  : (n === e.R && (Gr(this, e), (n = e), (e = n.U)),
                    (e.C = !1),
                    (r.C = !0),
                    Kr(this, r)))
              : ((u = r.L),
                u && u.C
                  ? ((e.C = u.C = !1), (r.C = !0), (n = r))
                  : (n === e.L && (Kr(this, e), (n = e), (e = n.U)),
                    (e.C = !1),
                    (r.C = !0),
                    Gr(this, r))),
            (e = n.U);
        this._.C = !1;
      },
      remove: function (n) {
        n.N && (n.N.P = n.P), n.P && (n.P.N = n.N), (n.N = n.P = null);
        var t,
          e,
          r,
          u = n.U,
          i = n.L,
          o = n.R;
        if (
          ((e = i ? (o ? Qr(o) : i) : o),
          u ? (u.L === n ? (u.L = e) : (u.R = e)) : (this._ = e),
          i && o
            ? ((r = e.C),
              (e.C = n.C),
              (e.L = i),
              (i.U = e),
              e !== o
                ? ((u = e.U),
                  (e.U = n.U),
                  (n = e.R),
                  (u.L = n),
                  (e.R = o),
                  (o.U = e))
                : ((e.U = u), (u = e), (n = e.R)))
            : ((r = n.C), (n = e)),
          n && (n.U = u),
          !r)
        ) {
          if (n && n.C) return (n.C = !1), void 0;
          do {
            if (n === this._) break;
            if (n === u.L) {
              if (
                ((t = u.R),
                t.C && ((t.C = !1), (u.C = !0), Gr(this, u), (t = u.R)),
                (t.L && t.L.C) || (t.R && t.R.C))
              ) {
                (t.R && t.R.C) ||
                  ((t.L.C = !1), (t.C = !0), Kr(this, t), (t = u.R)),
                  (t.C = u.C),
                  (u.C = t.R.C = !1),
                  Gr(this, u),
                  (n = this._);
                break;
              }
            } else if (
              ((t = u.L),
              t.C && ((t.C = !1), (u.C = !0), Kr(this, u), (t = u.L)),
              (t.L && t.L.C) || (t.R && t.R.C))
            ) {
              (t.L && t.L.C) ||
                ((t.R.C = !1), (t.C = !0), Gr(this, t), (t = u.L)),
                (t.C = u.C),
                (u.C = t.L.C = !1),
                Kr(this, u),
                (n = this._);
              break;
            }
            (t.C = !0), (n = u), (u = u.U);
          } while (!n.C);
          n && (n.C = !1);
        }
      },
    }),
    (Xo.geom.voronoi = function (n) {
      function t(n) {
        var t = new Array(n.length),
          r = a[0][0],
          u = a[0][1],
          i = a[1][0],
          o = a[1][1];
        return (
          nu(e(n), a).cells.forEach(function (e, a) {
            var c = e.edges,
              s = e.site,
              l = (t[a] = c.length
                ? c.map(function (n) {
                    var t = n.start();
                    return [t.x, t.y];
                  })
                : s.x >= r && s.x <= i && s.y >= u && s.y <= o
                ? [
                    [r, o],
                    [i, o],
                    [i, u],
                    [r, u],
                  ]
                : []);
            l.point = n[a];
          }),
          t
        );
      }
      function e(n) {
        return n.map(function (n, t) {
          return {
            x: Math.round(i(n, t) / Aa) * Aa,
            y: Math.round(o(n, t) / Aa) * Aa,
            i: t,
          };
        });
      }
      var r = br,
        u = wr,
        i = r,
        o = u,
        a = Kc;
      return n
        ? t(n)
        : ((t.links = function (n) {
            return nu(e(n))
              .edges.filter(function (n) {
                return n.l && n.r;
              })
              .map(function (t) {
                return { source: n[t.l.i], target: n[t.r.i] };
              });
          }),
          (t.triangles = function (n) {
            var t = [];
            return (
              nu(e(n)).cells.forEach(function (e, r) {
                for (
                  var u,
                    i,
                    o = e.site,
                    a = e.edges.sort(jr),
                    c = -1,
                    s = a.length,
                    l = a[s - 1].edge,
                    f = l.l === o ? l.r : l.l;
                  ++c < s;

                )
                  (u = l),
                    (i = f),
                    (l = a[c].edge),
                    (f = l.l === o ? l.r : l.l),
                    r < i.i &&
                      r < f.i &&
                      eu(o, i, f) < 0 &&
                      t.push([n[r], n[i.i], n[f.i]]);
              }),
              t
            );
          }),
          (t.x = function (n) {
            return arguments.length ? ((i = _t((r = n))), t) : r;
          }),
          (t.y = function (n) {
            return arguments.length ? ((o = _t((u = n))), t) : u;
          }),
          (t.clipExtent = function (n) {
            return arguments.length
              ? ((a = null == n ? Kc : n), t)
              : a === Kc
              ? null
              : a;
          }),
          (t.size = function (n) {
            return arguments.length
              ? t.clipExtent(n && [[0, 0], n])
              : a === Kc
              ? null
              : a && a[1];
          }),
          t);
    });
  var Kc = [
    [-1e6, -1e6],
    [1e6, 1e6],
  ];
  (Xo.geom.delaunay = function (n) {
    return Xo.geom.voronoi().triangles(n);
  }),
    (Xo.geom.quadtree = function (n, t, e, r, u) {
      function i(n) {
        function i(n, t, e, r, u, i, o, a) {
          if (!isNaN(e) && !isNaN(r))
            if (n.leaf) {
              var c = n.x,
                l = n.y;
              if (null != c)
                if (oa(c - e) + oa(l - r) < 0.01) s(n, t, e, r, u, i, o, a);
                else {
                  var f = n.point;
                  (n.x = n.y = n.point = null),
                    s(n, f, c, l, u, i, o, a),
                    s(n, t, e, r, u, i, o, a);
                }
              else (n.x = e), (n.y = r), (n.point = t);
            } else s(n, t, e, r, u, i, o, a);
        }
        function s(n, t, e, r, u, o, a, c) {
          var s = 0.5 * (u + a),
            l = 0.5 * (o + c),
            f = e >= s,
            h = r >= l,
            g = (h << 1) + f;
          (n.leaf = !1),
            (n = n.nodes[g] || (n.nodes[g] = iu())),
            f ? (u = s) : (a = s),
            h ? (o = l) : (c = l),
            i(n, t, e, r, u, o, a, c);
        }
        var l,
          f,
          h,
          g,
          p,
          v,
          d,
          m,
          y,
          x = _t(a),
          M = _t(c);
        if (null != t) (v = t), (d = e), (m = r), (y = u);
        else if (
          ((m = y = -(v = d = 1 / 0)), (f = []), (h = []), (p = n.length), o)
        )
          for (g = 0; p > g; ++g)
            (l = n[g]),
              l.x < v && (v = l.x),
              l.y < d && (d = l.y),
              l.x > m && (m = l.x),
              l.y > y && (y = l.y),
              f.push(l.x),
              h.push(l.y);
        else
          for (g = 0; p > g; ++g) {
            var _ = +x((l = n[g]), g),
              b = +M(l, g);
            v > _ && (v = _),
              d > b && (d = b),
              _ > m && (m = _),
              b > y && (y = b),
              f.push(_),
              h.push(b);
          }
        var w = m - v,
          S = y - d;
        w > S ? (y = d + w) : (m = v + S);
        var k = iu();
        if (
          ((k.add = function (n) {
            i(k, n, +x(n, ++g), +M(n, g), v, d, m, y);
          }),
          (k.visit = function (n) {
            ou(n, k, v, d, m, y);
          }),
          (g = -1),
          null == t)
        ) {
          for (; ++g < p; ) i(k, n[g], f[g], h[g], v, d, m, y);
          --g;
        } else n.forEach(k.add);
        return (f = h = n = l = null), k;
      }
      var o,
        a = br,
        c = wr;
      return (o = arguments.length)
        ? ((a = ru), (c = uu), 3 === o && ((u = e), (r = t), (e = t = 0)), i(n))
        : ((i.x = function (n) {
            return arguments.length ? ((a = n), i) : a;
          }),
          (i.y = function (n) {
            return arguments.length ? ((c = n), i) : c;
          }),
          (i.extent = function (n) {
            return arguments.length
              ? (null == n
                  ? (t = e = r = u = null)
                  : ((t = +n[0][0]),
                    (e = +n[0][1]),
                    (r = +n[1][0]),
                    (u = +n[1][1])),
                i)
              : null == t
              ? null
              : [
                  [t, e],
                  [r, u],
                ];
          }),
          (i.size = function (n) {
            return arguments.length
              ? (null == n
                  ? (t = e = r = u = null)
                  : ((t = e = 0), (r = +n[0]), (u = +n[1])),
                i)
              : null == t
              ? null
              : [r - t, u - e];
          }),
          i);
    }),
    (Xo.interpolateRgb = au),
    (Xo.interpolateObject = cu),
    (Xo.interpolateNumber = su),
    (Xo.interpolateString = lu);
  var Qc = /[-+]?(?:\d+\.?\d*|\.?\d+)(?:[eE][-+]?\d+)?/g;
  (Xo.interpolate = fu),
    (Xo.interpolators = [
      function (n, t) {
        var e = typeof t;
        return (
          'string' === e
            ? Va.has(t) || /^(#|rgb\(|hsl\()/.test(t)
              ? au
              : lu
            : t instanceof G
            ? au
            : 'object' === e
            ? Array.isArray(t)
              ? hu
              : cu
            : su
        )(n, t);
      },
    ]),
    (Xo.interpolateArray = hu);
  var ns = function () {
      return bt;
    },
    ts = Xo.map({
      linear: ns,
      poly: xu,
      quad: function () {
        return du;
      },
      cubic: function () {
        return mu;
      },
      sin: function () {
        return Mu;
      },
      exp: function () {
        return _u;
      },
      circle: function () {
        return bu;
      },
      elastic: wu,
      back: Su,
      bounce: function () {
        return ku;
      },
    }),
    es = Xo.map({
      in: bt,
      out: pu,
      'in-out': vu,
      'out-in': function (n) {
        return vu(pu(n));
      },
    });
  (Xo.ease = function (n) {
    var t = n.indexOf('-'),
      e = t >= 0 ? n.substring(0, t) : n,
      r = t >= 0 ? n.substring(t + 1) : 'in';
    return (
      (e = ts.get(e) || ns),
      (r = es.get(r) || bt),
      gu(r(e.apply(null, $o.call(arguments, 1))))
    );
  }),
    (Xo.interpolateHcl = Eu),
    (Xo.interpolateHsl = Au),
    (Xo.interpolateLab = Cu),
    (Xo.interpolateRound = Nu),
    (Xo.transform = function (n) {
      var t = Wo.createElementNS(Xo.ns.prefix.svg, 'g');
      return (Xo.transform = function (n) {
        if (null != n) {
          t.setAttribute('transform', n);
          var e = t.transform.baseVal.consolidate();
        }
        return new Lu(e ? e.matrix : rs);
      })(n);
    }),
    (Lu.prototype.toString = function () {
      return (
        'translate(' +
        this.translate +
        ')rotate(' +
        this.rotate +
        ')skewX(' +
        this.skew +
        ')scale(' +
        this.scale +
        ')'
      );
    });
  var rs = { a: 1, b: 0, c: 0, d: 1, e: 0, f: 0 };
  (Xo.interpolateTransform = Ru),
    (Xo.layout = {}),
    (Xo.layout.bundle = function () {
      return function (n) {
        for (var t = [], e = -1, r = n.length; ++e < r; ) t.push(Uu(n[e]));
        return t;
      };
    }),
    (Xo.layout.chord = function () {
      function n() {
        var n,
          s,
          f,
          h,
          g,
          p = {},
          v = [],
          d = Xo.range(i),
          m = [];
        for (e = [], r = [], n = 0, h = -1; ++h < i; ) {
          for (s = 0, g = -1; ++g < i; ) s += u[h][g];
          v.push(s), m.push(Xo.range(i)), (n += s);
        }
        for (
          o &&
            d.sort(function (n, t) {
              return o(v[n], v[t]);
            }),
            a &&
              m.forEach(function (n, t) {
                n.sort(function (n, e) {
                  return a(u[t][n], u[t][e]);
                });
              }),
            n = (ka - l * i) / n,
            s = 0,
            h = -1;
          ++h < i;

        ) {
          for (f = s, g = -1; ++g < i; ) {
            var y = d[h],
              x = m[y][g],
              M = u[y][x],
              _ = s,
              b = (s += M * n);
            p[y + '-' + x] = {
              index: y,
              subindex: x,
              startAngle: _,
              endAngle: b,
              value: M,
            };
          }
          (r[y] = { index: y, startAngle: f, endAngle: s, value: (s - f) / n }),
            (s += l);
        }
        for (h = -1; ++h < i; )
          for (g = h - 1; ++g < i; ) {
            var w = p[h + '-' + g],
              S = p[g + '-' + h];
            (w.value || S.value) &&
              e.push(
                w.value < S.value
                  ? { source: S, target: w }
                  : { source: w, target: S }
              );
          }
        c && t();
      }
      function t() {
        e.sort(function (n, t) {
          return c(
            (n.source.value + n.target.value) / 2,
            (t.source.value + t.target.value) / 2
          );
        });
      }
      var e,
        r,
        u,
        i,
        o,
        a,
        c,
        s = {},
        l = 0;
      return (
        (s.matrix = function (n) {
          return arguments.length
            ? ((i = (u = n) && u.length), (e = r = null), s)
            : u;
        }),
        (s.padding = function (n) {
          return arguments.length ? ((l = n), (e = r = null), s) : l;
        }),
        (s.sortGroups = function (n) {
          return arguments.length ? ((o = n), (e = r = null), s) : o;
        }),
        (s.sortSubgroups = function (n) {
          return arguments.length ? ((a = n), (e = null), s) : a;
        }),
        (s.sortChords = function (n) {
          return arguments.length ? ((c = n), e && t(), s) : c;
        }),
        (s.chords = function () {
          return e || n(), e;
        }),
        (s.groups = function () {
          return r || n(), r;
        }),
        s
      );
    }),
    (Xo.layout.force = function () {
      function n(n) {
        return function (t, e, r, u) {
          if (t.point !== n) {
            var i = t.cx - n.x,
              o = t.cy - n.y,
              a = u - e,
              c = i * i + o * o;
            if (c > (a * a) / d) {
              if (p > c) {
                var s = t.charge / c;
                (n.px -= i * s), (n.py -= o * s);
              }
              return !0;
            }
            if (t.point && c && p > c) {
              var s = t.pointCharge / c;
              (n.px -= i * s), (n.py -= o * s);
            }
          }
          return !t.charge;
        };
      }
      function t(n) {
        (n.px = Xo.event.x), (n.py = Xo.event.y), a.resume();
      }
      var e,
        r,
        u,
        i,
        o,
        a = {},
        c = Xo.dispatch('start', 'tick', 'end'),
        s = [1, 1],
        l = 0.9,
        f = us,
        h = is,
        g = -30,
        p = os,
        v = 0.1,
        d = 0.64,
        m = [],
        y = [];
      return (
        (a.tick = function () {
          if ((r *= 0.99) < 0.005)
            return c.end({ type: 'end', alpha: (r = 0) }), !0;
          var t,
            e,
            a,
            f,
            h,
            p,
            d,
            x,
            M,
            _ = m.length,
            b = y.length;
          for (e = 0; b > e; ++e)
            (a = y[e]),
              (f = a.source),
              (h = a.target),
              (x = h.x - f.x),
              (M = h.y - f.y),
              (p = x * x + M * M) &&
                ((p = (r * i[e] * ((p = Math.sqrt(p)) - u[e])) / p),
                (x *= p),
                (M *= p),
                (h.x -= x * (d = f.weight / (h.weight + f.weight))),
                (h.y -= M * d),
                (f.x += x * (d = 1 - d)),
                (f.y += M * d));
          if ((d = r * v) && ((x = s[0] / 2), (M = s[1] / 2), (e = -1), d))
            for (; ++e < _; )
              (a = m[e]), (a.x += (x - a.x) * d), (a.y += (M - a.y) * d);
          if (g)
            for (Zu((t = Xo.geom.quadtree(m)), r, o), e = -1; ++e < _; )
              (a = m[e]).fixed || t.visit(n(a));
          for (e = -1; ++e < _; )
            (a = m[e]),
              a.fixed
                ? ((a.x = a.px), (a.y = a.py))
                : ((a.x -= (a.px - (a.px = a.x)) * l),
                  (a.y -= (a.py - (a.py = a.y)) * l));
          c.tick({ type: 'tick', alpha: r });
        }),
        (a.nodes = function (n) {
          return arguments.length ? ((m = n), a) : m;
        }),
        (a.links = function (n) {
          return arguments.length ? ((y = n), a) : y;
        }),
        (a.size = function (n) {
          return arguments.length ? ((s = n), a) : s;
        }),
        (a.linkDistance = function (n) {
          return arguments.length
            ? ((f = 'function' == typeof n ? n : +n), a)
            : f;
        }),
        (a.distance = a.linkDistance),
        (a.linkStrength = function (n) {
          return arguments.length
            ? ((h = 'function' == typeof n ? n : +n), a)
            : h;
        }),
        (a.friction = function (n) {
          return arguments.length ? ((l = +n), a) : l;
        }),
        (a.charge = function (n) {
          return arguments.length
            ? ((g = 'function' == typeof n ? n : +n), a)
            : g;
        }),
        (a.chargeDistance = function (n) {
          return arguments.length ? ((p = n * n), a) : Math.sqrt(p);
        }),
        (a.gravity = function (n) {
          return arguments.length ? ((v = +n), a) : v;
        }),
        (a.theta = function (n) {
          return arguments.length ? ((d = n * n), a) : Math.sqrt(d);
        }),
        (a.alpha = function (n) {
          return arguments.length
            ? ((n = +n),
              r
                ? (r = n > 0 ? n : 0)
                : n > 0 &&
                  (c.start({ type: 'start', alpha: (r = n) }),
                  Xo.timer(a.tick)),
              a)
            : r;
        }),
        (a.start = function () {
          function n(n, r) {
            if (!e) {
              for (e = new Array(c), a = 0; c > a; ++a) e[a] = [];
              for (a = 0; s > a; ++a) {
                var u = y[a];
                e[u.source.index].push(u.target),
                  e[u.target.index].push(u.source);
              }
            }
            for (var i, o = e[t], a = -1, s = o.length; ++a < s; )
              if (!isNaN((i = o[a][n]))) return i;
            return Math.random() * r;
          }
          var t,
            e,
            r,
            c = m.length,
            l = y.length,
            p = s[0],
            v = s[1];
          for (t = 0; c > t; ++t) ((r = m[t]).index = t), (r.weight = 0);
          for (t = 0; l > t; ++t)
            (r = y[t]),
              'number' == typeof r.source && (r.source = m[r.source]),
              'number' == typeof r.target && (r.target = m[r.target]),
              ++r.source.weight,
              ++r.target.weight;
          for (t = 0; c > t; ++t)
            (r = m[t]),
              isNaN(r.x) && (r.x = n('x', p)),
              isNaN(r.y) && (r.y = n('y', v)),
              isNaN(r.px) && (r.px = r.x),
              isNaN(r.py) && (r.py = r.y);
          if (((u = []), 'function' == typeof f))
            for (t = 0; l > t; ++t) u[t] = +f.call(this, y[t], t);
          else for (t = 0; l > t; ++t) u[t] = f;
          if (((i = []), 'function' == typeof h))
            for (t = 0; l > t; ++t) i[t] = +h.call(this, y[t], t);
          else for (t = 0; l > t; ++t) i[t] = h;
          if (((o = []), 'function' == typeof g))
            for (t = 0; c > t; ++t) o[t] = +g.call(this, m[t], t);
          else for (t = 0; c > t; ++t) o[t] = g;
          return a.resume();
        }),
        (a.resume = function () {
          return a.alpha(0.1);
        }),
        (a.stop = function () {
          return a.alpha(0);
        }),
        (a.drag = function () {
          return (
            e ||
              (e = Xo.behavior
                .drag()
                .origin(bt)
                .on('dragstart.force', Fu)
                .on('drag.force', t)
                .on('dragend.force', Ou)),
            arguments.length
              ? (this.on('mouseover.force', Yu)
                  .on('mouseout.force', Iu)
                  .call(e),
                void 0)
              : e
          );
        }),
        Xo.rebind(a, c, 'on')
      );
    });
  var us = 20,
    is = 1,
    os = 1 / 0;
  (Xo.layout.hierarchy = function () {
    function n(t, o, a) {
      var c = u.call(e, t, o);
      if (((t.depth = o), a.push(t), c && (s = c.length))) {
        for (
          var s, l, f = -1, h = (t.children = new Array(s)), g = 0, p = o + 1;
          ++f < s;

        )
          (l = h[f] = n(c[f], p, a)), (l.parent = t), (g += l.value);
        r && h.sort(r), i && (t.value = g);
      } else delete t.children, i && (t.value = +i.call(e, t, o) || 0);
      return t;
    }
    function t(n, r) {
      var u = n.children,
        o = 0;
      if (u && (a = u.length))
        for (var a, c = -1, s = r + 1; ++c < a; ) o += t(u[c], s);
      else i && (o = +i.call(e, n, r) || 0);
      return i && (n.value = o), o;
    }
    function e(t) {
      var e = [];
      return n(t, 0, e), e;
    }
    var r = Bu,
      u = Xu,
      i = $u;
    return (
      (e.sort = function (n) {
        return arguments.length ? ((r = n), e) : r;
      }),
      (e.children = function (n) {
        return arguments.length ? ((u = n), e) : u;
      }),
      (e.value = function (n) {
        return arguments.length ? ((i = n), e) : i;
      }),
      (e.revalue = function (n) {
        return t(n, 0), n;
      }),
      e
    );
  }),
    (Xo.layout.partition = function () {
      function n(t, e, r, u) {
        var i = t.children;
        if (
          ((t.x = e),
          (t.y = t.depth * u),
          (t.dx = r),
          (t.dy = u),
          i && (o = i.length))
        ) {
          var o,
            a,
            c,
            s = -1;
          for (r = t.value ? r / t.value : 0; ++s < o; )
            n((a = i[s]), e, (c = a.value * r), u), (e += c);
        }
      }
      function t(n) {
        var e = n.children,
          r = 0;
        if (e && (u = e.length))
          for (var u, i = -1; ++i < u; ) r = Math.max(r, t(e[i]));
        return 1 + r;
      }
      function e(e, i) {
        var o = r.call(this, e, i);
        return n(o[0], 0, u[0], u[1] / t(o[0])), o;
      }
      var r = Xo.layout.hierarchy(),
        u = [1, 1];
      return (
        (e.size = function (n) {
          return arguments.length ? ((u = n), e) : u;
        }),
        Vu(e, r)
      );
    }),
    (Xo.layout.pie = function () {
      function n(i) {
        var o = i.map(function (e, r) {
            return +t.call(n, e, r);
          }),
          a = +('function' == typeof r ? r.apply(this, arguments) : r),
          c =
            (('function' == typeof u ? u.apply(this, arguments) : u) - a) /
            Xo.sum(o),
          s = Xo.range(i.length);
        null != e &&
          s.sort(
            e === as
              ? function (n, t) {
                  return o[t] - o[n];
                }
              : function (n, t) {
                  return e(i[n], i[t]);
                }
          );
        var l = [];
        return (
          s.forEach(function (n) {
            var t;
            l[n] = {
              data: i[n],
              value: (t = o[n]),
              startAngle: a,
              endAngle: (a += t * c),
            };
          }),
          l
        );
      }
      var t = Number,
        e = as,
        r = 0,
        u = ka;
      return (
        (n.value = function (e) {
          return arguments.length ? ((t = e), n) : t;
        }),
        (n.sort = function (t) {
          return arguments.length ? ((e = t), n) : e;
        }),
        (n.startAngle = function (t) {
          return arguments.length ? ((r = t), n) : r;
        }),
        (n.endAngle = function (t) {
          return arguments.length ? ((u = t), n) : u;
        }),
        n
      );
    });
  var as = {};
  Xo.layout.stack = function () {
    function n(a, c) {
      var s = a.map(function (e, r) {
          return t.call(n, e, r);
        }),
        l = s.map(function (t) {
          return t.map(function (t, e) {
            return [i.call(n, t, e), o.call(n, t, e)];
          });
        }),
        f = e.call(n, l, c);
      (s = Xo.permute(s, f)), (l = Xo.permute(l, f));
      var h,
        g,
        p,
        v = r.call(n, l, c),
        d = s.length,
        m = s[0].length;
      for (g = 0; m > g; ++g)
        for (u.call(n, s[0][g], (p = v[g]), l[0][g][1]), h = 1; d > h; ++h)
          u.call(n, s[h][g], (p += l[h - 1][g][1]), l[h][g][1]);
      return a;
    }
    var t = bt,
      e = Qu,
      r = ni,
      u = Ku,
      i = Ju,
      o = Gu;
    return (
      (n.values = function (e) {
        return arguments.length ? ((t = e), n) : t;
      }),
      (n.order = function (t) {
        return arguments.length
          ? ((e = 'function' == typeof t ? t : cs.get(t) || Qu), n)
          : e;
      }),
      (n.offset = function (t) {
        return arguments.length
          ? ((r = 'function' == typeof t ? t : ss.get(t) || ni), n)
          : r;
      }),
      (n.x = function (t) {
        return arguments.length ? ((i = t), n) : i;
      }),
      (n.y = function (t) {
        return arguments.length ? ((o = t), n) : o;
      }),
      (n.out = function (t) {
        return arguments.length ? ((u = t), n) : u;
      }),
      n
    );
  };
  var cs = Xo.map({
      'inside-out': function (n) {
        var t,
          e,
          r = n.length,
          u = n.map(ti),
          i = n.map(ei),
          o = Xo.range(r).sort(function (n, t) {
            return u[n] - u[t];
          }),
          a = 0,
          c = 0,
          s = [],
          l = [];
        for (t = 0; r > t; ++t)
          (e = o[t]),
            c > a ? ((a += i[e]), s.push(e)) : ((c += i[e]), l.push(e));
        return l.reverse().concat(s);
      },
      reverse: function (n) {
        return Xo.range(n.length).reverse();
      },
      default: Qu,
    }),
    ss = Xo.map({
      silhouette: function (n) {
        var t,
          e,
          r,
          u = n.length,
          i = n[0].length,
          o = [],
          a = 0,
          c = [];
        for (e = 0; i > e; ++e) {
          for (t = 0, r = 0; u > t; t++) r += n[t][e][1];
          r > a && (a = r), o.push(r);
        }
        for (e = 0; i > e; ++e) c[e] = (a - o[e]) / 2;
        return c;
      },
      wiggle: function (n) {
        var t,
          e,
          r,
          u,
          i,
          o,
          a,
          c,
          s,
          l = n.length,
          f = n[0],
          h = f.length,
          g = [];
        for (g[0] = c = s = 0, e = 1; h > e; ++e) {
          for (t = 0, u = 0; l > t; ++t) u += n[t][e][1];
          for (t = 0, i = 0, a = f[e][0] - f[e - 1][0]; l > t; ++t) {
            for (r = 0, o = (n[t][e][1] - n[t][e - 1][1]) / (2 * a); t > r; ++r)
              o += (n[r][e][1] - n[r][e - 1][1]) / a;
            i += o * n[t][e][1];
          }
          (g[e] = c -= u ? (i / u) * a : 0), s > c && (s = c);
        }
        for (e = 0; h > e; ++e) g[e] -= s;
        return g;
      },
      expand: function (n) {
        var t,
          e,
          r,
          u = n.length,
          i = n[0].length,
          o = 1 / u,
          a = [];
        for (e = 0; i > e; ++e) {
          for (t = 0, r = 0; u > t; t++) r += n[t][e][1];
          if (r) for (t = 0; u > t; t++) n[t][e][1] /= r;
          else for (t = 0; u > t; t++) n[t][e][1] = o;
        }
        for (e = 0; i > e; ++e) a[e] = 0;
        return a;
      },
      zero: ni,
    });
  (Xo.layout.histogram = function () {
    function n(n, i) {
      for (
        var o,
          a,
          c = [],
          s = n.map(e, this),
          l = r.call(this, s, i),
          f = u.call(this, l, s, i),
          i = -1,
          h = s.length,
          g = f.length - 1,
          p = t ? 1 : 1 / h;
        ++i < g;

      )
        (o = c[i] = []), (o.dx = f[i + 1] - (o.x = f[i])), (o.y = 0);
      if (g > 0)
        for (i = -1; ++i < h; )
          (a = s[i]),
            a >= l[0] &&
              a <= l[1] &&
              ((o = c[Xo.bisect(f, a, 1, g) - 1]), (o.y += p), o.push(n[i]));
      return c;
    }
    var t = !0,
      e = Number,
      r = oi,
      u = ui;
    return (
      (n.value = function (t) {
        return arguments.length ? ((e = t), n) : e;
      }),
      (n.range = function (t) {
        return arguments.length ? ((r = _t(t)), n) : r;
      }),
      (n.bins = function (t) {
        return arguments.length
          ? ((u =
              'number' == typeof t
                ? function (n) {
                    return ii(n, t);
                  }
                : _t(t)),
            n)
          : u;
      }),
      (n.frequency = function (e) {
        return arguments.length ? ((t = !!e), n) : t;
      }),
      n
    );
  }),
    (Xo.layout.tree = function () {
      function n(n, i) {
        function o(n, t) {
          var r = n.children,
            u = n._tree;
          if (r && (i = r.length)) {
            for (var i, a, s, l = r[0], f = l, h = -1; ++h < i; )
              (s = r[h]), o(s, a), (f = c(s, a, f)), (a = s);
            vi(n);
            var g = 0.5 * (l._tree.prelim + s._tree.prelim);
            t
              ? ((u.prelim = t._tree.prelim + e(n, t)), (u.mod = u.prelim - g))
              : (u.prelim = g);
          } else t && (u.prelim = t._tree.prelim + e(n, t));
        }
        function a(n, t) {
          n.x = n._tree.prelim + t;
          var e = n.children;
          if (e && (r = e.length)) {
            var r,
              u = -1;
            for (t += n._tree.mod; ++u < r; ) a(e[u], t);
          }
        }
        function c(n, t, r) {
          if (t) {
            for (
              var u,
                i = n,
                o = n,
                a = t,
                c = n.parent.children[0],
                s = i._tree.mod,
                l = o._tree.mod,
                f = a._tree.mod,
                h = c._tree.mod;
              (a = si(a)), (i = ci(i)), a && i;

            )
              (c = ci(c)),
                (o = si(o)),
                (o._tree.ancestor = n),
                (u = a._tree.prelim + f - i._tree.prelim - s + e(a, i)),
                u > 0 && (di(mi(a, n, r), n, u), (s += u), (l += u)),
                (f += a._tree.mod),
                (s += i._tree.mod),
                (h += c._tree.mod),
                (l += o._tree.mod);
            a && !si(o) && ((o._tree.thread = a), (o._tree.mod += f - l)),
              i &&
                !ci(c) &&
                ((c._tree.thread = i), (c._tree.mod += s - h), (r = n));
          }
          return r;
        }
        var s = t.call(this, n, i),
          l = s[0];
        pi(l, function (n, t) {
          n._tree = {
            ancestor: n,
            prelim: 0,
            mod: 0,
            change: 0,
            shift: 0,
            number: t ? t._tree.number + 1 : 0,
          };
        }),
          o(l),
          a(l, -l._tree.prelim);
        var f = li(l, hi),
          h = li(l, fi),
          g = li(l, gi),
          p = f.x - e(f, h) / 2,
          v = h.x + e(h, f) / 2,
          d = g.depth || 1;
        return (
          pi(
            l,
            u
              ? function (n) {
                  (n.x *= r[0]), (n.y = n.depth * r[1]), delete n._tree;
                }
              : function (n) {
                  (n.x = ((n.x - p) / (v - p)) * r[0]),
                    (n.y = (n.depth / d) * r[1]),
                    delete n._tree;
                }
          ),
          s
        );
      }
      var t = Xo.layout.hierarchy().sort(null).value(null),
        e = ai,
        r = [1, 1],
        u = !1;
      return (
        (n.separation = function (t) {
          return arguments.length ? ((e = t), n) : e;
        }),
        (n.size = function (t) {
          return arguments.length ? ((u = null == (r = t)), n) : u ? null : r;
        }),
        (n.nodeSize = function (t) {
          return arguments.length ? ((u = null != (r = t)), n) : u ? r : null;
        }),
        Vu(n, t)
      );
    }),
    (Xo.layout.pack = function () {
      function n(n, i) {
        var o = e.call(this, n, i),
          a = o[0],
          c = u[0],
          s = u[1],
          l =
            null == t
              ? Math.sqrt
              : 'function' == typeof t
              ? t
              : function () {
                  return t;
                };
        if (
          ((a.x = a.y = 0),
          pi(a, function (n) {
            n.r = +l(n.value);
          }),
          pi(a, bi),
          r)
        ) {
          var f = (r * (t ? 1 : Math.max((2 * a.r) / c, (2 * a.r) / s))) / 2;
          pi(a, function (n) {
            n.r += f;
          }),
            pi(a, bi),
            pi(a, function (n) {
              n.r -= f;
            });
        }
        return (
          ki(
            a,
            c / 2,
            s / 2,
            t ? 1 : 1 / Math.max((2 * a.r) / c, (2 * a.r) / s)
          ),
          o
        );
      }
      var t,
        e = Xo.layout.hierarchy().sort(yi),
        r = 0,
        u = [1, 1];
      return (
        (n.size = function (t) {
          return arguments.length ? ((u = t), n) : u;
        }),
        (n.radius = function (e) {
          return arguments.length
            ? ((t = null == e || 'function' == typeof e ? e : +e), n)
            : t;
        }),
        (n.padding = function (t) {
          return arguments.length ? ((r = +t), n) : r;
        }),
        Vu(n, e)
      );
    }),
    (Xo.layout.cluster = function () {
      function n(n, i) {
        var o,
          a = t.call(this, n, i),
          c = a[0],
          s = 0;
        pi(c, function (n) {
          var t = n.children;
          t && t.length
            ? ((n.x = Ci(t)), (n.y = Ai(t)))
            : ((n.x = o ? (s += e(n, o)) : 0), (n.y = 0), (o = n));
        });
        var l = Ni(c),
          f = Li(c),
          h = l.x - e(l, f) / 2,
          g = f.x + e(f, l) / 2;
        return (
          pi(
            c,
            u
              ? function (n) {
                  (n.x = (n.x - c.x) * r[0]), (n.y = (c.y - n.y) * r[1]);
                }
              : function (n) {
                  (n.x = ((n.x - h) / (g - h)) * r[0]),
                    (n.y = (1 - (c.y ? n.y / c.y : 1)) * r[1]);
                }
          ),
          a
        );
      }
      var t = Xo.layout.hierarchy().sort(null).value(null),
        e = ai,
        r = [1, 1],
        u = !1;
      return (
        (n.separation = function (t) {
          return arguments.length ? ((e = t), n) : e;
        }),
        (n.size = function (t) {
          return arguments.length ? ((u = null == (r = t)), n) : u ? null : r;
        }),
        (n.nodeSize = function (t) {
          return arguments.length ? ((u = null != (r = t)), n) : u ? r : null;
        }),
        Vu(n, t)
      );
    }),
    (Xo.layout.treemap = function () {
      function n(n, t) {
        for (var e, r, u = -1, i = n.length; ++u < i; )
          (r = (e = n[u]).value * (0 > t ? 0 : t)),
            (e.area = isNaN(r) || 0 >= r ? 0 : r);
      }
      function t(e) {
        var i = e.children;
        if (i && i.length) {
          var o,
            a,
            c,
            s = f(e),
            l = [],
            h = i.slice(),
            p = 1 / 0,
            v =
              'slice' === g
                ? s.dx
                : 'dice' === g
                ? s.dy
                : 'slice-dice' === g
                ? 1 & e.depth
                  ? s.dy
                  : s.dx
                : Math.min(s.dx, s.dy);
          for (n(h, (s.dx * s.dy) / e.value), l.area = 0; (c = h.length) > 0; )
            l.push((o = h[c - 1])),
              (l.area += o.area),
              'squarify' !== g || (a = r(l, v)) <= p
                ? (h.pop(), (p = a))
                : ((l.area -= l.pop().area),
                  u(l, v, s, !1),
                  (v = Math.min(s.dx, s.dy)),
                  (l.length = l.area = 0),
                  (p = 1 / 0));
          l.length && (u(l, v, s, !0), (l.length = l.area = 0)), i.forEach(t);
        }
      }
      function e(t) {
        var r = t.children;
        if (r && r.length) {
          var i,
            o = f(t),
            a = r.slice(),
            c = [];
          for (n(a, (o.dx * o.dy) / t.value), c.area = 0; (i = a.pop()); )
            c.push(i),
              (c.area += i.area),
              null != i.z &&
                (u(c, i.z ? o.dx : o.dy, o, !a.length),
                (c.length = c.area = 0));
          r.forEach(e);
        }
      }
      function r(n, t) {
        for (
          var e, r = n.area, u = 0, i = 1 / 0, o = -1, a = n.length;
          ++o < a;

        )
          (e = n[o].area) && (i > e && (i = e), e > u && (u = e));
        return (
          (r *= r),
          (t *= t),
          r ? Math.max((t * u * p) / r, r / (t * i * p)) : 1 / 0
        );
      }
      function u(n, t, e, r) {
        var u,
          i = -1,
          o = n.length,
          a = e.x,
          s = e.y,
          l = t ? c(n.area / t) : 0;
        if (t == e.dx) {
          for ((r || l > e.dy) && (l = e.dy); ++i < o; )
            (u = n[i]),
              (u.x = a),
              (u.y = s),
              (u.dy = l),
              (a += u.dx = Math.min(e.x + e.dx - a, l ? c(u.area / l) : 0));
          (u.z = !0), (u.dx += e.x + e.dx - a), (e.y += l), (e.dy -= l);
        } else {
          for ((r || l > e.dx) && (l = e.dx); ++i < o; )
            (u = n[i]),
              (u.x = a),
              (u.y = s),
              (u.dx = l),
              (s += u.dy = Math.min(e.y + e.dy - s, l ? c(u.area / l) : 0));
          (u.z = !1), (u.dy += e.y + e.dy - s), (e.x += l), (e.dx -= l);
        }
      }
      function i(r) {
        var u = o || a(r),
          i = u[0];
        return (
          (i.x = 0),
          (i.y = 0),
          (i.dx = s[0]),
          (i.dy = s[1]),
          o && a.revalue(i),
          n([i], (i.dx * i.dy) / i.value),
          (o ? e : t)(i),
          h && (o = u),
          u
        );
      }
      var o,
        a = Xo.layout.hierarchy(),
        c = Math.round,
        s = [1, 1],
        l = null,
        f = zi,
        h = !1,
        g = 'squarify',
        p = 0.5 * (1 + Math.sqrt(5));
      return (
        (i.size = function (n) {
          return arguments.length ? ((s = n), i) : s;
        }),
        (i.padding = function (n) {
          function t(t) {
            var e = n.call(i, t, t.depth);
            return null == e
              ? zi(t)
              : qi(t, 'number' == typeof e ? [e, e, e, e] : e);
          }
          function e(t) {
            return qi(t, n);
          }
          if (!arguments.length) return l;
          var r;
          return (
            (f =
              null == (l = n)
                ? zi
                : 'function' == (r = typeof n)
                ? t
                : 'number' === r
                ? ((n = [n, n, n, n]), e)
                : e),
            i
          );
        }),
        (i.round = function (n) {
          return arguments.length
            ? ((c = n ? Math.round : Number), i)
            : c != Number;
        }),
        (i.sticky = function (n) {
          return arguments.length ? ((h = n), (o = null), i) : h;
        }),
        (i.ratio = function (n) {
          return arguments.length ? ((p = n), i) : p;
        }),
        (i.mode = function (n) {
          return arguments.length ? ((g = n + ''), i) : g;
        }),
        Vu(i, a)
      );
    }),
    (Xo.random = {
      normal: function (n, t) {
        var e = arguments.length;
        return (
          2 > e && (t = 1),
          1 > e && (n = 0),
          function () {
            var e, r, u;
            do
              (e = 2 * Math.random() - 1),
                (r = 2 * Math.random() - 1),
                (u = e * e + r * r);
            while (!u || u > 1);
            return n + t * e * Math.sqrt((-2 * Math.log(u)) / u);
          }
        );
      },
      logNormal: function () {
        var n = Xo.random.normal.apply(Xo, arguments);
        return function () {
          return Math.exp(n());
        };
      },
      bates: function (n) {
        var t = Xo.random.irwinHall(n);
        return function () {
          return t() / n;
        };
      },
      irwinHall: function (n) {
        return function () {
          for (var t = 0, e = 0; n > e; e++) t += Math.random();
          return t;
        };
      },
    }),
    (Xo.scale = {});
  var ls = { floor: bt, ceil: bt };
  Xo.scale.linear = function () {
    return Hi([0, 1], [0, 1], fu, !1);
  };
  var fs = { s: 1, g: 1, p: 1, r: 1, e: 1 };
  Xo.scale.log = function () {
    return $i(Xo.scale.linear().domain([0, 1]), 10, !0, [1, 10]);
  };
  var hs = Xo.format('.0e'),
    gs = {
      floor: function (n) {
        return -Math.ceil(-n);
      },
      ceil: function (n) {
        return -Math.floor(-n);
      },
    };
  (Xo.scale.pow = function () {
    return Bi(Xo.scale.linear(), 1, [0, 1]);
  }),
    (Xo.scale.sqrt = function () {
      return Xo.scale.pow().exponent(0.5);
    }),
    (Xo.scale.ordinal = function () {
      return Ji([], { t: 'range', a: [[]] });
    }),
    (Xo.scale.category10 = function () {
      return Xo.scale.ordinal().range(ps);
    }),
    (Xo.scale.category20 = function () {
      return Xo.scale.ordinal().range(vs);
    }),
    (Xo.scale.category20b = function () {
      return Xo.scale.ordinal().range(ds);
    }),
    (Xo.scale.category20c = function () {
      return Xo.scale.ordinal().range(ms);
    });
  var ps = [
      2062260, 16744206, 2924588, 14034728, 9725885, 9197131, 14907330, 8355711,
      12369186, 1556175,
    ].map(ht),
    vs = [
      2062260, 11454440, 16744206, 16759672, 2924588, 10018698, 14034728,
      16750742, 9725885, 12955861, 9197131, 12885140, 14907330, 16234194,
      8355711, 13092807, 12369186, 14408589, 1556175, 10410725,
    ].map(ht),
    ds = [
      3750777, 5395619, 7040719, 10264286, 6519097, 9216594, 11915115, 13556636,
      9202993, 12426809, 15186514, 15190932, 8666169, 11356490, 14049643,
      15177372, 8077683, 10834324, 13528509, 14589654,
    ].map(ht),
    ms = [
      3244733, 7057110, 10406625, 13032431, 15095053, 16616764, 16625259,
      16634018, 3253076, 7652470, 10607003, 13101504, 7695281, 10394312,
      12369372, 14342891, 6513507, 9868950, 12434877, 14277081,
    ].map(ht);
  (Xo.scale.quantile = function () {
    return Gi([], []);
  }),
    (Xo.scale.quantize = function () {
      return Ki(0, 1, [0, 1]);
    }),
    (Xo.scale.threshold = function () {
      return Qi([0.5], [0, 1]);
    }),
    (Xo.scale.identity = function () {
      return no([0, 1]);
    }),
    (Xo.svg = {}),
    (Xo.svg.arc = function () {
      function n() {
        var n = t.apply(this, arguments),
          i = e.apply(this, arguments),
          o = r.apply(this, arguments) + ys,
          a = u.apply(this, arguments) + ys,
          c = (o > a && ((c = o), (o = a), (a = c)), a - o),
          s = Sa > c ? '0' : '1',
          l = Math.cos(o),
          f = Math.sin(o),
          h = Math.cos(a),
          g = Math.sin(a);
        return c >= xs
          ? n
            ? 'M0,' +
              i +
              'A' +
              i +
              ',' +
              i +
              ' 0 1,1 0,' +
              -i +
              'A' +
              i +
              ',' +
              i +
              ' 0 1,1 0,' +
              i +
              'M0,' +
              n +
              'A' +
              n +
              ',' +
              n +
              ' 0 1,0 0,' +
              -n +
              'A' +
              n +
              ',' +
              n +
              ' 0 1,0 0,' +
              n +
              'Z'
            : 'M0,' +
              i +
              'A' +
              i +
              ',' +
              i +
              ' 0 1,1 0,' +
              -i +
              'A' +
              i +
              ',' +
              i +
              ' 0 1,1 0,' +
              i +
              'Z'
          : n
          ? 'M' +
            i * l +
            ',' +
            i * f +
            'A' +
            i +
            ',' +
            i +
            ' 0 ' +
            s +
            ',1 ' +
            i * h +
            ',' +
            i * g +
            'L' +
            n * h +
            ',' +
            n * g +
            'A' +
            n +
            ',' +
            n +
            ' 0 ' +
            s +
            ',0 ' +
            n * l +
            ',' +
            n * f +
            'Z'
          : 'M' +
            i * l +
            ',' +
            i * f +
            'A' +
            i +
            ',' +
            i +
            ' 0 ' +
            s +
            ',1 ' +
            i * h +
            ',' +
            i * g +
            'L0,0' +
            'Z';
      }
      var t = to,
        e = eo,
        r = ro,
        u = uo;
      return (
        (n.innerRadius = function (e) {
          return arguments.length ? ((t = _t(e)), n) : t;
        }),
        (n.outerRadius = function (t) {
          return arguments.length ? ((e = _t(t)), n) : e;
        }),
        (n.startAngle = function (t) {
          return arguments.length ? ((r = _t(t)), n) : r;
        }),
        (n.endAngle = function (t) {
          return arguments.length ? ((u = _t(t)), n) : u;
        }),
        (n.centroid = function () {
          var n = (t.apply(this, arguments) + e.apply(this, arguments)) / 2,
            i = (r.apply(this, arguments) + u.apply(this, arguments)) / 2 + ys;
          return [Math.cos(i) * n, Math.sin(i) * n];
        }),
        n
      );
    });
  var ys = -Ea,
    xs = ka - Aa;
  Xo.svg.line = function () {
    return io(bt);
  };
  var Ms = Xo.map({
    linear: oo,
    'linear-closed': ao,
    step: co,
    'step-before': so,
    'step-after': lo,
    basis: mo,
    'basis-open': yo,
    'basis-closed': xo,
    bundle: Mo,
    cardinal: go,
    'cardinal-open': fo,
    'cardinal-closed': ho,
    monotone: Eo,
  });
  Ms.forEach(function (n, t) {
    (t.key = n), (t.closed = /-closed$/.test(n));
  });
  var _s = [0, 2 / 3, 1 / 3, 0],
    bs = [0, 1 / 3, 2 / 3, 0],
    ws = [0, 1 / 6, 2 / 3, 1 / 6];
  (Xo.svg.line.radial = function () {
    var n = io(Ao);
    return (n.radius = n.x), delete n.x, (n.angle = n.y), delete n.y, n;
  }),
    (so.reverse = lo),
    (lo.reverse = so),
    (Xo.svg.area = function () {
      return Co(bt);
    }),
    (Xo.svg.area.radial = function () {
      var n = Co(Ao);
      return (
        (n.radius = n.x),
        delete n.x,
        (n.innerRadius = n.x0),
        delete n.x0,
        (n.outerRadius = n.x1),
        delete n.x1,
        (n.angle = n.y),
        delete n.y,
        (n.startAngle = n.y0),
        delete n.y0,
        (n.endAngle = n.y1),
        delete n.y1,
        n
      );
    }),
    (Xo.svg.chord = function () {
      function n(n, a) {
        var c = t(this, i, n, a),
          s = t(this, o, n, a);
        return (
          'M' +
          c.p0 +
          r(c.r, c.p1, c.a1 - c.a0) +
          (e(c, s)
            ? u(c.r, c.p1, c.r, c.p0)
            : u(c.r, c.p1, s.r, s.p0) +
              r(s.r, s.p1, s.a1 - s.a0) +
              u(s.r, s.p1, c.r, c.p0)) +
          'Z'
        );
      }
      function t(n, t, e, r) {
        var u = t.call(n, e, r),
          i = a.call(n, u, r),
          o = c.call(n, u, r) + ys,
          l = s.call(n, u, r) + ys;
        return {
          r: i,
          a0: o,
          a1: l,
          p0: [i * Math.cos(o), i * Math.sin(o)],
          p1: [i * Math.cos(l), i * Math.sin(l)],
        };
      }
      function e(n, t) {
        return n.a0 == t.a0 && n.a1 == t.a1;
      }
      function r(n, t, e) {
        return 'A' + n + ',' + n + ' 0 ' + +(e > Sa) + ',1 ' + t;
      }
      function u(n, t, e, r) {
        return 'Q 0,0 ' + r;
      }
      var i = hr,
        o = gr,
        a = No,
        c = ro,
        s = uo;
      return (
        (n.radius = function (t) {
          return arguments.length ? ((a = _t(t)), n) : a;
        }),
        (n.source = function (t) {
          return arguments.length ? ((i = _t(t)), n) : i;
        }),
        (n.target = function (t) {
          return arguments.length ? ((o = _t(t)), n) : o;
        }),
        (n.startAngle = function (t) {
          return arguments.length ? ((c = _t(t)), n) : c;
        }),
        (n.endAngle = function (t) {
          return arguments.length ? ((s = _t(t)), n) : s;
        }),
        n
      );
    }),
    (Xo.svg.diagonal = function () {
      function n(n, u) {
        var i = t.call(this, n, u),
          o = e.call(this, n, u),
          a = (i.y + o.y) / 2,
          c = [i, { x: i.x, y: a }, { x: o.x, y: a }, o];
        return (
          (c = c.map(r)), 'M' + c[0] + 'C' + c[1] + ' ' + c[2] + ' ' + c[3]
        );
      }
      var t = hr,
        e = gr,
        r = Lo;
      return (
        (n.source = function (e) {
          return arguments.length ? ((t = _t(e)), n) : t;
        }),
        (n.target = function (t) {
          return arguments.length ? ((e = _t(t)), n) : e;
        }),
        (n.projection = function (t) {
          return arguments.length ? ((r = t), n) : r;
        }),
        n
      );
    }),
    (Xo.svg.diagonal.radial = function () {
      var n = Xo.svg.diagonal(),
        t = Lo,
        e = n.projection;
      return (
        (n.projection = function (n) {
          return arguments.length ? e(zo((t = n))) : t;
        }),
        n
      );
    }),
    (Xo.svg.symbol = function () {
      function n(n, r) {
        return (Ss.get(t.call(this, n, r)) || Ro)(e.call(this, n, r));
      }
      var t = To,
        e = qo;
      return (
        (n.type = function (e) {
          return arguments.length ? ((t = _t(e)), n) : t;
        }),
        (n.size = function (t) {
          return arguments.length ? ((e = _t(t)), n) : e;
        }),
        n
      );
    });
  var Ss = Xo.map({
    circle: Ro,
    cross: function (n) {
      var t = Math.sqrt(n / 5) / 2;
      return (
        'M' +
        -3 * t +
        ',' +
        -t +
        'H' +
        -t +
        'V' +
        -3 * t +
        'H' +
        t +
        'V' +
        -t +
        'H' +
        3 * t +
        'V' +
        t +
        'H' +
        t +
        'V' +
        3 * t +
        'H' +
        -t +
        'V' +
        t +
        'H' +
        -3 * t +
        'Z'
      );
    },
    diamond: function (n) {
      var t = Math.sqrt(n / (2 * Cs)),
        e = t * Cs;
      return 'M0,' + -t + 'L' + e + ',0' + ' 0,' + t + ' ' + -e + ',0' + 'Z';
    },
    square: function (n) {
      var t = Math.sqrt(n) / 2;
      return (
        'M' +
        -t +
        ',' +
        -t +
        'L' +
        t +
        ',' +
        -t +
        ' ' +
        t +
        ',' +
        t +
        ' ' +
        -t +
        ',' +
        t +
        'Z'
      );
    },
    'triangle-down': function (n) {
      var t = Math.sqrt(n / As),
        e = (t * As) / 2;
      return 'M0,' + e + 'L' + t + ',' + -e + ' ' + -t + ',' + -e + 'Z';
    },
    'triangle-up': function (n) {
      var t = Math.sqrt(n / As),
        e = (t * As) / 2;
      return 'M0,' + -e + 'L' + t + ',' + e + ' ' + -t + ',' + e + 'Z';
    },
  });
  Xo.svg.symbolTypes = Ss.keys();
  var ks,
    Es,
    As = Math.sqrt(3),
    Cs = Math.tan(30 * Na),
    Ns = [],
    Ls = 0;
  (Ns.call = da.call),
    (Ns.empty = da.empty),
    (Ns.node = da.node),
    (Ns.size = da.size),
    (Xo.transition = function (n) {
      return arguments.length ? (ks ? n.transition() : n) : xa.transition();
    }),
    (Xo.transition.prototype = Ns),
    (Ns.select = function (n) {
      var t,
        e,
        r,
        u = this.id,
        i = [];
      n = M(n);
      for (var o = -1, a = this.length; ++o < a; ) {
        i.push((t = []));
        for (var c = this[o], s = -1, l = c.length; ++s < l; )
          (r = c[s]) && (e = n.call(r, r.__data__, s, o))
            ? ('__data__' in r && (e.__data__ = r.__data__),
              jo(e, s, u, r.__transition__[u]),
              t.push(e))
            : t.push(null);
      }
      return Do(i, u);
    }),
    (Ns.selectAll = function (n) {
      var t,
        e,
        r,
        u,
        i,
        o = this.id,
        a = [];
      n = _(n);
      for (var c = -1, s = this.length; ++c < s; )
        for (var l = this[c], f = -1, h = l.length; ++f < h; )
          if ((r = l[f])) {
            (i = r.__transition__[o]),
              (e = n.call(r, r.__data__, f, c)),
              a.push((t = []));
            for (var g = -1, p = e.length; ++g < p; )
              (u = e[g]) && jo(u, g, o, i), t.push(u);
          }
      return Do(a, o);
    }),
    (Ns.filter = function (n) {
      var t,
        e,
        r,
        u = [];
      'function' != typeof n && (n = q(n));
      for (var i = 0, o = this.length; o > i; i++) {
        u.push((t = []));
        for (var e = this[i], a = 0, c = e.length; c > a; a++)
          (r = e[a]) && n.call(r, r.__data__, a, i) && t.push(r);
      }
      return Do(u, this.id);
    }),
    (Ns.tween = function (n, t) {
      var e = this.id;
      return arguments.length < 2
        ? this.node().__transition__[e].tween.get(n)
        : R(
            this,
            null == t
              ? function (t) {
                  t.__transition__[e].tween.remove(n);
                }
              : function (r) {
                  r.__transition__[e].tween.set(n, t);
                }
          );
    }),
    (Ns.attr = function (n, t) {
      function e() {
        this.removeAttribute(a);
      }
      function r() {
        this.removeAttributeNS(a.space, a.local);
      }
      function u(n) {
        return null == n
          ? e
          : ((n += ''),
            function () {
              var t,
                e = this.getAttribute(a);
              return (
                e !== n &&
                ((t = o(e, n)),
                function (n) {
                  this.setAttribute(a, t(n));
                })
              );
            });
      }
      function i(n) {
        return null == n
          ? r
          : ((n += ''),
            function () {
              var t,
                e = this.getAttributeNS(a.space, a.local);
              return (
                e !== n &&
                ((t = o(e, n)),
                function (n) {
                  this.setAttributeNS(a.space, a.local, t(n));
                })
              );
            });
      }
      if (arguments.length < 2) {
        for (t in n) this.attr(t, n[t]);
        return this;
      }
      var o = 'transform' == n ? Ru : fu,
        a = Xo.ns.qualify(n);
      return Po(this, 'attr.' + n, t, a.local ? i : u);
    }),
    (Ns.attrTween = function (n, t) {
      function e(n, e) {
        var r = t.call(this, n, e, this.getAttribute(u));
        return (
          r &&
          function (n) {
            this.setAttribute(u, r(n));
          }
        );
      }
      function r(n, e) {
        var r = t.call(this, n, e, this.getAttributeNS(u.space, u.local));
        return (
          r &&
          function (n) {
            this.setAttributeNS(u.space, u.local, r(n));
          }
        );
      }
      var u = Xo.ns.qualify(n);
      return this.tween('attr.' + n, u.local ? r : e);
    }),
    (Ns.style = function (n, t, e) {
      function r() {
        this.style.removeProperty(n);
      }
      function u(t) {
        return null == t
          ? r
          : ((t += ''),
            function () {
              var r,
                u = Go.getComputedStyle(this, null).getPropertyValue(n);
              return (
                u !== t &&
                ((r = fu(u, t)),
                function (t) {
                  this.style.setProperty(n, r(t), e);
                })
              );
            });
      }
      var i = arguments.length;
      if (3 > i) {
        if ('string' != typeof n) {
          2 > i && (t = '');
          for (e in n) this.style(e, n[e], t);
          return this;
        }
        e = '';
      }
      return Po(this, 'style.' + n, t, u);
    }),
    (Ns.styleTween = function (n, t, e) {
      function r(r, u) {
        var i = t.call(
          this,
          r,
          u,
          Go.getComputedStyle(this, null).getPropertyValue(n)
        );
        return (
          i &&
          function (t) {
            this.style.setProperty(n, i(t), e);
          }
        );
      }
      return arguments.length < 3 && (e = ''), this.tween('style.' + n, r);
    }),
    (Ns.text = function (n) {
      return Po(this, 'text', n, Uo);
    }),
    (Ns.remove = function () {
      return this.each('end.transition', function () {
        var n;
        this.__transition__.count < 2 &&
          (n = this.parentNode) &&
          n.removeChild(this);
      });
    }),
    (Ns.ease = function (n) {
      var t = this.id;
      return arguments.length < 1
        ? this.node().__transition__[t].ease
        : ('function' != typeof n && (n = Xo.ease.apply(Xo, arguments)),
          R(this, function (e) {
            e.__transition__[t].ease = n;
          }));
    }),
    (Ns.delay = function (n) {
      var t = this.id;
      return R(
        this,
        'function' == typeof n
          ? function (e, r, u) {
              e.__transition__[t].delay = +n.call(e, e.__data__, r, u);
            }
          : ((n = +n),
            function (e) {
              e.__transition__[t].delay = n;
            })
      );
    }),
    (Ns.duration = function (n) {
      var t = this.id;
      return R(
        this,
        'function' == typeof n
          ? function (e, r, u) {
              e.__transition__[t].duration = Math.max(
                1,
                n.call(e, e.__data__, r, u)
              );
            }
          : ((n = Math.max(1, n)),
            function (e) {
              e.__transition__[t].duration = n;
            })
      );
    }),
    (Ns.each = function (n, t) {
      var e = this.id;
      if (arguments.length < 2) {
        var r = Es,
          u = ks;
        (ks = e),
          R(this, function (t, r, u) {
            (Es = t.__transition__[e]), n.call(t, t.__data__, r, u);
          }),
          (Es = r),
          (ks = u);
      } else
        R(this, function (r) {
          var u = r.__transition__[e];
          (u.event || (u.event = Xo.dispatch('start', 'end'))).on(n, t);
        });
      return this;
    }),
    (Ns.transition = function () {
      for (
        var n, t, e, r, u = this.id, i = ++Ls, o = [], a = 0, c = this.length;
        c > a;
        a++
      ) {
        o.push((n = []));
        for (var t = this[a], s = 0, l = t.length; l > s; s++)
          (e = t[s]) &&
            ((r = Object.create(e.__transition__[u])),
            (r.delay += r.duration),
            jo(e, s, i, r)),
            n.push(e);
      }
      return Do(o, i);
    }),
    (Xo.svg.axis = function () {
      function n(n) {
        n.each(function () {
          var n,
            s = Xo.select(this),
            l = this.__chart__ || e,
            f = (this.__chart__ = e.copy()),
            h = null == c ? (f.ticks ? f.ticks.apply(f, a) : f.domain()) : c,
            g = null == t ? (f.tickFormat ? f.tickFormat.apply(f, a) : bt) : t,
            p = s.selectAll('.tick').data(h, f),
            v = p
              .enter()
              .insert('g', '.domain')
              .attr('class', 'tick')
              .style('opacity', Aa),
            d = Xo.transition(p.exit()).style('opacity', Aa).remove(),
            m = Xo.transition(p).style('opacity', 1),
            y = Ri(f),
            x = s.selectAll('.domain').data([0]),
            M =
              (x.enter().append('path').attr('class', 'domain'),
              Xo.transition(x));
          v.append('line'), v.append('text');
          var _ = v.select('line'),
            b = m.select('line'),
            w = p.select('text').text(g),
            S = v.select('text'),
            k = m.select('text');
          switch (r) {
            case 'bottom':
              (n = Ho),
                _.attr('y2', u),
                S.attr('y', Math.max(u, 0) + o),
                b.attr('x2', 0).attr('y2', u),
                k.attr('x', 0).attr('y', Math.max(u, 0) + o),
                w.attr('dy', '.71em').style('text-anchor', 'middle'),
                M.attr('d', 'M' + y[0] + ',' + i + 'V0H' + y[1] + 'V' + i);
              break;
            case 'top':
              (n = Ho),
                _.attr('y2', -u),
                S.attr('y', -(Math.max(u, 0) + o)),
                b.attr('x2', 0).attr('y2', -u),
                k.attr('x', 0).attr('y', -(Math.max(u, 0) + o)),
                w.attr('dy', '0em').style('text-anchor', 'middle'),
                M.attr('d', 'M' + y[0] + ',' + -i + 'V0H' + y[1] + 'V' + -i);
              break;
            case 'left':
              (n = Fo),
                _.attr('x2', -u),
                S.attr('x', -(Math.max(u, 0) + o)),
                b.attr('x2', -u).attr('y2', 0),
                k.attr('x', -(Math.max(u, 0) + o)).attr('y', 0),
                w.attr('dy', '.32em').style('text-anchor', 'end'),
                M.attr('d', 'M' + -i + ',' + y[0] + 'H0V' + y[1] + 'H' + -i);
              break;
            case 'right':
              (n = Fo),
                _.attr('x2', u),
                S.attr('x', Math.max(u, 0) + o),
                b.attr('x2', u).attr('y2', 0),
                k.attr('x', Math.max(u, 0) + o).attr('y', 0),
                w.attr('dy', '.32em').style('text-anchor', 'start'),
                M.attr('d', 'M' + i + ',' + y[0] + 'H0V' + y[1] + 'H' + i);
          }
          if (f.rangeBand) {
            var E = f,
              A = E.rangeBand() / 2;
            l = f = function (n) {
              return E(n) + A;
            };
          } else l.rangeBand ? (l = f) : d.call(n, f);
          v.call(n, l), m.call(n, f);
        });
      }
      var t,
        e = Xo.scale.linear(),
        r = zs,
        u = 6,
        i = 6,
        o = 3,
        a = [10],
        c = null;
      return (
        (n.scale = function (t) {
          return arguments.length ? ((e = t), n) : e;
        }),
        (n.orient = function (t) {
          return arguments.length ? ((r = t in qs ? t + '' : zs), n) : r;
        }),
        (n.ticks = function () {
          return arguments.length ? ((a = arguments), n) : a;
        }),
        (n.tickValues = function (t) {
          return arguments.length ? ((c = t), n) : c;
        }),
        (n.tickFormat = function (e) {
          return arguments.length ? ((t = e), n) : t;
        }),
        (n.tickSize = function (t) {
          var e = arguments.length;
          return e ? ((u = +t), (i = +arguments[e - 1]), n) : u;
        }),
        (n.innerTickSize = function (t) {
          return arguments.length ? ((u = +t), n) : u;
        }),
        (n.outerTickSize = function (t) {
          return arguments.length ? ((i = +t), n) : i;
        }),
        (n.tickPadding = function (t) {
          return arguments.length ? ((o = +t), n) : o;
        }),
        (n.tickSubdivide = function () {
          return arguments.length && n;
        }),
        n
      );
    });
  var zs = 'bottom',
    qs = { top: 1, right: 1, bottom: 1, left: 1 };
  Xo.svg.brush = function () {
    function n(i) {
      i.each(function () {
        var i = Xo.select(this)
            .style('pointer-events', 'all')
            .style('-webkit-tap-highlight-color', 'rgba(0,0,0,0)')
            .on('mousedown.brush', u)
            .on('touchstart.brush', u),
          o = i.selectAll('.background').data([0]);
        o
          .enter()
          .append('rect')
          .attr('class', 'background')
          .style('visibility', 'hidden')
          .style('cursor', 'crosshair'),
          i
            .selectAll('.extent')
            .data([0])
            .enter()
            .append('rect')
            .attr('class', 'extent')
            .style('cursor', 'move');
        var a = i.selectAll('.resize').data(p, bt);
        a.exit().remove(),
          a
            .enter()
            .append('g')
            .attr('class', function (n) {
              return 'resize ' + n;
            })
            .style('cursor', function (n) {
              return Ts[n];
            })
            .append('rect')
            .attr('x', function (n) {
              return /[ew]$/.test(n) ? -3 : null;
            })
            .attr('y', function (n) {
              return /^[ns]/.test(n) ? -3 : null;
            })
            .attr('width', 6)
            .attr('height', 6)
            .style('visibility', 'hidden'),
          a.style('display', n.empty() ? 'none' : null);
        var l,
          f = Xo.transition(i),
          h = Xo.transition(o);
        c && ((l = Ri(c)), h.attr('x', l[0]).attr('width', l[1] - l[0]), e(f)),
          s &&
            ((l = Ri(s)), h.attr('y', l[0]).attr('height', l[1] - l[0]), r(f)),
          t(f);
      });
    }
    function t(n) {
      n.selectAll('.resize').attr('transform', function (n) {
        return 'translate(' + l[+/e$/.test(n)] + ',' + f[+/^s/.test(n)] + ')';
      });
    }
    function e(n) {
      n.select('.extent').attr('x', l[0]),
        n.selectAll('.extent,.n>rect,.s>rect').attr('width', l[1] - l[0]);
    }
    function r(n) {
      n.select('.extent').attr('y', f[0]),
        n.selectAll('.extent,.e>rect,.w>rect').attr('height', f[1] - f[0]);
    }
    function u() {
      function u() {
        32 == Xo.event.keyCode &&
          (C || ((x = null), (L[0] -= l[1]), (L[1] -= f[1]), (C = 2)), d());
      }
      function p() {
        32 == Xo.event.keyCode &&
          2 == C &&
          ((L[0] += l[1]), (L[1] += f[1]), (C = 0), d());
      }
      function v() {
        var n = Xo.mouse(_),
          u = !1;
        M && ((n[0] += M[0]), (n[1] += M[1])),
          C ||
            (Xo.event.altKey
              ? (x || (x = [(l[0] + l[1]) / 2, (f[0] + f[1]) / 2]),
                (L[0] = l[+(n[0] < x[0])]),
                (L[1] = f[+(n[1] < x[1])]))
              : (x = null)),
          E && m(n, c, 0) && (e(S), (u = !0)),
          A && m(n, s, 1) && (r(S), (u = !0)),
          u && (t(S), w({ type: 'brush', mode: C ? 'move' : 'resize' }));
      }
      function m(n, t, e) {
        var r,
          u,
          a = Ri(t),
          c = a[0],
          s = a[1],
          p = L[e],
          v = e ? f : l,
          d = v[1] - v[0];
        return (
          C && ((c -= p), (s -= d + p)),
          (r = (e ? g : h) ? Math.max(c, Math.min(s, n[e])) : n[e]),
          C
            ? (u = (r += p) + d)
            : (x && (p = Math.max(c, Math.min(s, 2 * x[e] - r))),
              r > p ? ((u = r), (r = p)) : (u = p)),
          v[0] != r || v[1] != u
            ? (e ? (o = null) : (i = null), (v[0] = r), (v[1] = u), !0)
            : void 0
        );
      }
      function y() {
        v(),
          S.style('pointer-events', 'all')
            .selectAll('.resize')
            .style('display', n.empty() ? 'none' : null),
          Xo.select('body').style('cursor', null),
          z
            .on('mousemove.brush', null)
            .on('mouseup.brush', null)
            .on('touchmove.brush', null)
            .on('touchend.brush', null)
            .on('keydown.brush', null)
            .on('keyup.brush', null),
          N(),
          w({ type: 'brushend' });
      }
      var x,
        M,
        _ = this,
        b = Xo.select(Xo.event.target),
        w = a.of(_, arguments),
        S = Xo.select(_),
        k = b.datum(),
        E = !/^(n|s)$/.test(k) && c,
        A = !/^(e|w)$/.test(k) && s,
        C = b.classed('extent'),
        N = O(),
        L = Xo.mouse(_),
        z = Xo.select(Go).on('keydown.brush', u).on('keyup.brush', p);
      if (
        (Xo.event.changedTouches
          ? z.on('touchmove.brush', v).on('touchend.brush', y)
          : z.on('mousemove.brush', v).on('mouseup.brush', y),
        S.interrupt().selectAll('*').interrupt(),
        C)
      )
        (L[0] = l[0] - L[0]), (L[1] = f[0] - L[1]);
      else if (k) {
        var q = +/w$/.test(k),
          T = +/^n/.test(k);
        (M = [l[1 - q] - L[0], f[1 - T] - L[1]]), (L[0] = l[q]), (L[1] = f[T]);
      } else Xo.event.altKey && (x = L.slice());
      S.style('pointer-events', 'none')
        .selectAll('.resize')
        .style('display', null),
        Xo.select('body').style('cursor', b.style('cursor')),
        w({ type: 'brushstart' }),
        v();
    }
    var i,
      o,
      a = y(n, 'brushstart', 'brush', 'brushend'),
      c = null,
      s = null,
      l = [0, 0],
      f = [0, 0],
      h = !0,
      g = !0,
      p = Rs[0];
    return (
      (n.event = function (n) {
        n.each(function () {
          var n = a.of(this, arguments),
            t = { x: l, y: f, i: i, j: o },
            e = this.__chart__ || t;
          (this.__chart__ = t),
            ks
              ? Xo.select(this)
                  .transition()
                  .each('start.brush', function () {
                    (i = e.i),
                      (o = e.j),
                      (l = e.x),
                      (f = e.y),
                      n({ type: 'brushstart' });
                  })
                  .tween('brush:brush', function () {
                    var e = hu(l, t.x),
                      r = hu(f, t.y);
                    return (
                      (i = o = null),
                      function (u) {
                        (l = t.x = e(u)),
                          (f = t.y = r(u)),
                          n({ type: 'brush', mode: 'resize' });
                      }
                    );
                  })
                  .each('end.brush', function () {
                    (i = t.i),
                      (o = t.j),
                      n({ type: 'brush', mode: 'resize' }),
                      n({ type: 'brushend' });
                  })
              : (n({ type: 'brushstart' }),
                n({ type: 'brush', mode: 'resize' }),
                n({ type: 'brushend' }));
        });
      }),
      (n.x = function (t) {
        return arguments.length ? ((c = t), (p = Rs[(!c << 1) | !s]), n) : c;
      }),
      (n.y = function (t) {
        return arguments.length ? ((s = t), (p = Rs[(!c << 1) | !s]), n) : s;
      }),
      (n.clamp = function (t) {
        return arguments.length
          ? (c && s
              ? ((h = !!t[0]), (g = !!t[1]))
              : c
              ? (h = !!t)
              : s && (g = !!t),
            n)
          : c && s
          ? [h, g]
          : c
          ? h
          : s
          ? g
          : null;
      }),
      (n.extent = function (t) {
        var e, r, u, a, h;
        return arguments.length
          ? (c &&
              ((e = t[0]),
              (r = t[1]),
              s && ((e = e[0]), (r = r[0])),
              (i = [e, r]),
              c.invert && ((e = c(e)), (r = c(r))),
              e > r && ((h = e), (e = r), (r = h)),
              (e != l[0] || r != l[1]) && (l = [e, r])),
            s &&
              ((u = t[0]),
              (a = t[1]),
              c && ((u = u[1]), (a = a[1])),
              (o = [u, a]),
              s.invert && ((u = s(u)), (a = s(a))),
              u > a && ((h = u), (u = a), (a = h)),
              (u != f[0] || a != f[1]) && (f = [u, a])),
            n)
          : (c &&
              (i
                ? ((e = i[0]), (r = i[1]))
                : ((e = l[0]),
                  (r = l[1]),
                  c.invert && ((e = c.invert(e)), (r = c.invert(r))),
                  e > r && ((h = e), (e = r), (r = h)))),
            s &&
              (o
                ? ((u = o[0]), (a = o[1]))
                : ((u = f[0]),
                  (a = f[1]),
                  s.invert && ((u = s.invert(u)), (a = s.invert(a))),
                  u > a && ((h = u), (u = a), (a = h)))),
            c && s
              ? [
                  [e, u],
                  [r, a],
                ]
              : c
              ? [e, r]
              : s && [u, a]);
      }),
      (n.clear = function () {
        return n.empty() || ((l = [0, 0]), (f = [0, 0]), (i = o = null)), n;
      }),
      (n.empty = function () {
        return (!!c && l[0] == l[1]) || (!!s && f[0] == f[1]);
      }),
      Xo.rebind(n, a, 'on')
    );
  };
  var Ts = {
      n: 'ns-resize',
      e: 'ew-resize',
      s: 'ns-resize',
      w: 'ew-resize',
      nw: 'nwse-resize',
      ne: 'nesw-resize',
      se: 'nwse-resize',
      sw: 'nesw-resize',
    },
    Rs = [
      ['n', 'e', 's', 'w', 'nw', 'ne', 'se', 'sw'],
      ['e', 'w'],
      ['n', 's'],
      [],
    ],
    Ds = (tc.format = ac.timeFormat),
    Ps = Ds.utc,
    Us = Ps('%Y-%m-%dT%H:%M:%S.%LZ');
  (Ds.iso =
    Date.prototype.toISOString && +new Date('2000-01-01T00:00:00.000Z')
      ? Oo
      : Us),
    (Oo.parse = function (n) {
      var t = new Date(n);
      return isNaN(t) ? null : t;
    }),
    (Oo.toString = Us.toString),
    (tc.second = Rt(
      function (n) {
        return new ec(1e3 * Math.floor(n / 1e3));
      },
      function (n, t) {
        n.setTime(n.getTime() + 1e3 * Math.floor(t));
      },
      function (n) {
        return n.getSeconds();
      }
    )),
    (tc.seconds = tc.second.range),
    (tc.seconds.utc = tc.second.utc.range),
    (tc.minute = Rt(
      function (n) {
        return new ec(6e4 * Math.floor(n / 6e4));
      },
      function (n, t) {
        n.setTime(n.getTime() + 6e4 * Math.floor(t));
      },
      function (n) {
        return n.getMinutes();
      }
    )),
    (tc.minutes = tc.minute.range),
    (tc.minutes.utc = tc.minute.utc.range),
    (tc.hour = Rt(
      function (n) {
        var t = n.getTimezoneOffset() / 60;
        return new ec(36e5 * (Math.floor(n / 36e5 - t) + t));
      },
      function (n, t) {
        n.setTime(n.getTime() + 36e5 * Math.floor(t));
      },
      function (n) {
        return n.getHours();
      }
    )),
    (tc.hours = tc.hour.range),
    (tc.hours.utc = tc.hour.utc.range),
    (tc.month = Rt(
      function (n) {
        return (n = tc.day(n)), n.setDate(1), n;
      },
      function (n, t) {
        n.setMonth(n.getMonth() + t);
      },
      function (n) {
        return n.getMonth();
      }
    )),
    (tc.months = tc.month.range),
    (tc.months.utc = tc.month.utc.range);
  var js = [
      1e3, 5e3, 15e3, 3e4, 6e4, 3e5, 9e5, 18e5, 36e5, 108e5, 216e5, 432e5,
      864e5, 1728e5, 6048e5, 2592e6, 7776e6, 31536e6,
    ],
    Hs = [
      [tc.second, 1],
      [tc.second, 5],
      [tc.second, 15],
      [tc.second, 30],
      [tc.minute, 1],
      [tc.minute, 5],
      [tc.minute, 15],
      [tc.minute, 30],
      [tc.hour, 1],
      [tc.hour, 3],
      [tc.hour, 6],
      [tc.hour, 12],
      [tc.day, 1],
      [tc.day, 2],
      [tc.week, 1],
      [tc.month, 1],
      [tc.month, 3],
      [tc.year, 1],
    ],
    Fs = Ds.multi([
      [
        '.%L',
        function (n) {
          return n.getMilliseconds();
        },
      ],
      [
        ':%S',
        function (n) {
          return n.getSeconds();
        },
      ],
      [
        '%I:%M',
        function (n) {
          return n.getMinutes();
        },
      ],
      [
        '%I %p',
        function (n) {
          return n.getHours();
        },
      ],
      [
        '%a %d',
        function (n) {
          return n.getDay() && 1 != n.getDate();
        },
      ],
      [
        '%b %d',
        function (n) {
          return 1 != n.getDate();
        },
      ],
      [
        '%B',
        function (n) {
          return n.getMonth();
        },
      ],
      ['%Y', be],
    ]),
    Os = {
      range: function (n, t, e) {
        return Xo.range(+n, +t, e).map(Io);
      },
      floor: bt,
      ceil: bt,
    };
  (Hs.year = tc.year),
    (tc.scale = function () {
      return Yo(Xo.scale.linear(), Hs, Fs);
    });
  var Ys = Hs.map(function (n) {
      return [n[0].utc, n[1]];
    }),
    Is = Ps.multi([
      [
        '.%L',
        function (n) {
          return n.getUTCMilliseconds();
        },
      ],
      [
        ':%S',
        function (n) {
          return n.getUTCSeconds();
        },
      ],
      [
        '%I:%M',
        function (n) {
          return n.getUTCMinutes();
        },
      ],
      [
        '%I %p',
        function (n) {
          return n.getUTCHours();
        },
      ],
      [
        '%a %d',
        function (n) {
          return n.getUTCDay() && 1 != n.getUTCDate();
        },
      ],
      [
        '%b %d',
        function (n) {
          return 1 != n.getUTCDate();
        },
      ],
      [
        '%B',
        function (n) {
          return n.getUTCMonth();
        },
      ],
      ['%Y', be],
    ]);
  (Ys.year = tc.year.utc),
    (tc.scale.utc = function () {
      return Yo(Xo.scale.linear(), Ys, Is);
    }),
    (Xo.text = wt(function (n) {
      return n.responseText;
    })),
    (Xo.json = function (n, t) {
      return St(n, 'application/json', Zo, t);
    }),
    (Xo.html = function (n, t) {
      return St(n, 'text/html', Vo, t);
    }),
    (Xo.xml = wt(function (n) {
      return n.responseXML;
    })),
    'function' == typeof define && define.amd
      ? define(Xo)
      : 'object' == typeof module && module.exports
      ? (module.exports = Xo)
      : (this.d3 = Xo);
})();
