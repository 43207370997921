<template>
  <div>
    <v-form ref="form">
      <div class="row mt-2">
        <input-text
          v-model="form.dados.nome"
          class="col-12 col-md-6"
          :label="$t('modulos.incertezas.formulario.dados_incerteza.nome')"
          :placeholder="
            $t('modulos.incertezas.formulario.dados_incerteza.nome')
          "
          :disabled="revisao"
          obrigatorio
        />
        <input-formula
          v-model="form.dados.formulaId"
          class="col-12 col-md-6"
          :label="
            $t(
              'modulos.incertezas.formulario.dados_incerteza.formula_mensurando'
            )
          "
          :multiplos="false"
          :disabled="revisao"
          somente-mensurando
          obrigatorio
        />
      </div>
      <div class="row">
        <input-unidade-medida
          v-model="form.dados.unidadeMedidaId"
          class="col-12 col-md-3"
          :label="
            $t('modulos.incertezas.formulario.dados_incerteza.unidade_medida')
          "
          :multiplos="false"
          :disabled="revisao"
          obrigatorio
        />
        <v-form
          ref="amplitudeForm"
          class="col-12 col-md-3 d-flex align-top"
        >
          <input-text
            v-model="form.dados.amplitudeMinima"
            class="flex-fill"
            :label="
              $t('modulos.incertezas.formulario.dados_incerteza.amplitude')
            "
            :placeholder="
              $t(
                'modulos.procedimentos_eletronicos.tabela_registro.formulario.amplitude_min'
              )
            "
            obrigatorio
            :regras-personalizadas="regraAmplitude()"
            type="number"
            :disabled="revisao"
            @input="validarAmplitude"
          />
          <span class="mx-2 mt-11">{{ $t('geral.titulos.a') }}</span>
          <input-text
            v-model="form.dados.amplitudeMaxima"
            class="flex-fill mt-5"
            :placeholder="
              $t(
                'modulos.procedimentos_eletronicos.tabela_registro.formulario.amplitude_max'
              )
            "
            obrigatorio
            :disabled="revisao"
            :regras-personalizadas="regraAmplitude()"
            type="number"
            @input="validarAmplitude"
          />
        </v-form>
        <input-select
          v-model="form.dados.estruturaUtilizada"
          class="col-12 col-md-3"
          :label="
            $t(
              'modulos.incertezas.formulario.dados_incerteza.estrutura_utilizada'
            )
          "
          :options="opcoes.estruturaUtilizada"
          :disabled="revisao || desabilitarEstruturaUtilizada"
          obrigatorio
        />
        <input-file
          :value="form.dados.fileImagem"
          returns="base64"
          class="col-12 col-md-3"
          :placeholder="
            $t('modulos.incertezas.formulario.dados_incerteza.adicionar_imagem')
          "
          visualizador
          :regras-personalizadas="[regraArquivo]"
          :image="form.dados.imagem"
          :disabled="revisao"
          @handle-upload="handleUpload"
          @clear="desvincularImagem"
          @nome-imagem="nomeImagem"
        />
      </div>
      <div class="row">
        <input-textarea
          v-model="form.dados.descricaoPadrao"
          class="col-12 col-md-6"
          :label="
            $t('modulos.incertezas.formulario.dados_incerteza.descricao_padrao')
          "
          :disabled="revisao"
          :placeholder="
            $t('modulos.incertezas.formulario.dados_incerteza.descricao_padrao')
          "
          :min="0"
          :max="1000"
          :rows="4"
        />
        <input-textarea
          v-model="form.dados.descricaoMelhorArtefato"
          class="col-12 col-md-6"
          :label="
            $t(
              'modulos.incertezas.formulario.dados_incerteza.descricao_melhor_artefato'
            )
          "
          :disabled="revisao"
          :placeholder="
            $t(
              'modulos.incertezas.formulario.dados_incerteza.descricao_melhor_artefato'
            )
          "
          :min="0"
          :max="1000"
          :rows="4"
        />
      </div>
      <div class="row">
        <input-textarea
          v-if="form.dados.revisao > 0"
          :key="justificativaKey"
          v-model="form.dados.justificativa"
          class="col-12"
          :label="
            $t('modulos.incertezas.formulario.dados_incerteza.justificativa')
          "
          :min="0"
          :max="1000"
          :rows="6"
          :disabled="revisao"
          :obrigatorio="justificativaObrigatoria"
        />
      </div>
    </v-form>

    <v-divider class="my-5" />

    <div class="d-flex align-center justify-end mt-4">
      <botao-padrao
        v-if="revisao"
        outlined
        color="secondary"
        class="mr-2"
        @click="voltar"
      >
        <v-icon>$mdiArrowLeft</v-icon>
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>

      <template v-else>
        <botao-padrao
          outlined
          color="secondary"
          @click="cancelar"
        >
          <v-icon>$mdiCancel</v-icon>
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>

        <botao-padrao
          sem-capitalize
          class="mx-2"
          @click="confirmacaoSalvarContinuar"
        >
          <v-icon>$mdiContentSaveMoveOutline</v-icon>
          {{ $t('geral.botoes.salvar_continuar') }}
        </botao-padrao>

        <botao-padrao
          sem-capitalize
          class="mr-2"
          @click="confirmacaoSalvarSair"
        >
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.salvar_sair') }}
        </botao-padrao>

        <botao-padrao
          v-if="form.dados.id"
          sem-capitalize
          @click="confirmacaoPublicar"
        >
          <v-icon>$mdiPublish</v-icon>
          {{ $t('geral.botoes.publicar') }}
        </botao-padrao>
      </template>
    </div>
  </div>
</template>

<script>
import IncertezasService from '@/common/services/cadastros/IncertezasService';

import {
  InputUnidadeMedida,
  InputFile,
  InputFormula,
} from '@/components/inputs';

export default {
  components: {
    InputUnidadeMedida,
    InputFile,
    InputFormula,
  },
  props: {
    form: { type: Object, default: () => {} },
    revisao: { type: Boolean, default: false },
    desabilitarEstruturaUtilizada: { type: Boolean, default: false },
  },
  data() {
    return {
      opcoes: {
        estruturaUtilizada: [],
        unidadesDeMedida: [],
      },
      error: {
        amplitude_maxima: {
          status: false,
          message: '',
        },
      },
      justificativaObrigatoria: false,
      justificativaKey: 0,
    };
  },
  mounted() {
    this.listarEstruturasUtilizadas();
  },
  methods: {
    cancelar() {
      this.$emit('cancelar');
    },
    confirmacaoPublicar() {
      if(this.form.dados.revisao > 0 && !this.form.dados.justificativa)
        return this.obrigarPreencherJustificativa();
      this.$emit('publicar');
    },
    confirmacaoSalvarContinuar() {
      this.salvarContinuar();
    },
    confirmacaoSalvarSair() {
      this.confirmarSalvar().then(this.salvarSair);
    },
    salvarContinuar() {
      this.$emit('salvar-continuar');
    },
    salvarSair() {
      this.$emit('salvar-sair');
    },
    resetErrors() {
      this.error.amplitude_maxima.status = false;
      this.error.amplitude_maxima.message = '';
    },
    regraAmplitude() {
      if (!this.form.dados.amplitudeMaxima || !this.form.dados.amplitudeMinima)
        return [];

      let regraValor = (v) => {
        let valor = `${v}`;
        let intValue = valor.split('.')[0];
        let decimalValue = valor.split('.')[1];
        if (intValue.length > 10) {
          return this.$t('inputs.rules.maxBetweenDecimalValue', {
            char: 10,
            decimal: 9,
          });
        }

        if (decimalValue && decimalValue.length > 9) {
          return this.$t('inputs.rules.maxBetweenDecimalValue', {
            decimal: 9,
            char: 10,
          });
        }

        return true;
      };

      let regraValorMaior = () =>
        parseFloat(this.form.dados.amplitudeMaxima) >=
        parseFloat(this.form.dados.amplitudeMinima)
          ? true
          : this.$t(
              'inputs.rules.amplitudeMaximaNaoPodeSerMenorQueAmplitudeMinima'
            );
      return [regraValor, regraValorMaior];
    },
    podeJustificativaNula() {
      this.justificativaObrigatoria = false;
      ++this.justificativaKey;
    },
    obrigarPreencherJustificativa: async function() {
      this.toastAlerta(this.$t(`modulos.incertezas.alertas.justificativa`));
      this.justificativaObrigatoria = true;
      await this.$nextTick();
      this.$refs.form.validate();
    },
    listarEstruturasUtilizadas() {
      this.$store.dispatch('Layout/iniciarCarregamento');

      IncertezasService.listarEnumEstruturaUtilizada()
        .then((res) => {
          this.opcoes.estruturaUtilizada = res.data;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    handleUpload(file) {
      this.form.dados.imagem = file;
    },
    validarAmplitude() {
      if (!this.form.dados.amplitudeMinima && !this.form.dados.amplitudeMaxima)
        return;
      this.$refs.amplitudeForm.validate();
    },
    desvincularImagem() {
      this.form.dados.imagem = null;
      this.form.dados.nomeImagem = null;
    },
    nomeImagem(nomeImagem) {
      this.form.dados.nomeImagem = nomeImagem ? nomeImagem : null;
    },
    voltar: function () {
      this.$router.back();
    },
    regraArquivo(v) {
      if (!v) return true;
      if (v?.type == 'image/png') return true;
      return this.$t(`modulos.colaborador.validacoes.permitido_png`);
    },
  },
};
</script>
