import _ from 'lodash';
import { DropdownModel } from '../geral/DropdownModel';

export class ComponentesIncertezaModel {
  constructor({
    id,
    xi,
    descricao,
    tipoA = true,
    origemDado,
    unidadeMedidaContribuicao,
    distribuicaoEstatistica,
    unidadeMedidaCi,
    unidadeMedidaIncertezaPadrao,
    formulaContribuicao,
    incertezaDado,
    formulaDe,
    formulaCi,
    metodoNumericoCi,
    origemContribuicao,
    incertezaAuxiliar,
    valorTesteCi,
    valorTesteDe,
    valorTesteContribuicao,
    fonteIncerteza,
    variavel,
    grausLiberdadeFormulaTexto = '1000',
    grausLiberdade = 1000,
    arredondamentoSmc
  } = {}) {
    this.id = id;
    this.xi = xi;

    this.teste = {
      valorTesteContribuicao: valorTesteContribuicao,
      valorTesteCi: valorTesteCi,
      valorTesteDe: valorTesteDe,
      grausLiberdade: grausLiberdade,
    };

    this.descricao = descricao;
    this.tipoA = tipoA;
    this.origemDado = origemDado;

    if (unidadeMedidaContribuicao)
      this.unidadeMedidaContribuicaoId = new DropdownModel(
        unidadeMedidaContribuicao
      );

    if (incertezaAuxiliar)
      this.incertezaAuxiliarId = new DropdownModel(incertezaAuxiliar);

    if (unidadeMedidaCi)
      this.unidadeMedidaCiId = new DropdownModel(unidadeMedidaCi);

    if (unidadeMedidaIncertezaPadrao)
      this.unidadeMedidaIncertezaPadraoId = new DropdownModel(
        unidadeMedidaIncertezaPadrao
      );

    if (incertezaDado) this.incertezaDadoId = new DropdownModel(incertezaDado);

    this.distribuicaoEstatistica = distribuicaoEstatistica;
    this.formulaContribuicao = formulaContribuicao;
    this.formulaDE = formulaDe;
    this.formulaCi = formulaCi;
    this.metodoNumericoCi = metodoNumericoCi;
    this.origemContribuicao = origemContribuicao;
    this.fonteIncerteza = fonteIncerteza;
    this.variavel = variavel;
    this.arredondamentoSmc = arredondamentoSmc;
    if (variavel && arredondamentoSmc)
      this.variavel = new DropdownModel(variavel);
    if (arredondamentoSmc == 'ResolucaoSMC')
      this.variavel = new DropdownModel({ name: 'SMC', id: null });
    this.grausLiberdadeFormulaTexto = grausLiberdadeFormulaTexto;
  }

  get request() {
    let retorno = _.cloneDeep(this);

    if (this.unidadeMedidaCiId) {
      retorno.unidadeMedidaCiId = this.unidadeMedidaCiId.value;
    }

    if (this.unidadeMedidaContribuicaoId) {
      retorno.unidadeMedidaContribuicaoId =
        this.unidadeMedidaContribuicaoId.value;
    }

    if (this.unidadeMedidaIncertezaPadraoId) {
      retorno.unidadeMedidaIncertezaPadraoId =
        this.unidadeMedidaIncertezaPadraoId.value;
    }

    if (this.incertezaAuxiliarId) {
      retorno.incertezaAuxiliarId = this.incertezaAuxiliarId.value;
    }

    if (this.variavel) {
      if (this.variavel.text == "SMC")
        retorno.variavelId = null
      else
        retorno.variavelId = this.variavel.value;
    } 

    retorno = { ...retorno, ...retorno.teste };

    delete retorno.teste;

    return retorno;
  }
}
